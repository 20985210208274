// Customizable Area Start
import React from "react";
import CreatePasswordController, {
    Props
} from "../src/CreatePasswordController.web";
import {
    Grid,
    InputAdornment,
    IconButton,
    Typography,
    TextField,
    Button
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
    ValidationRightGreen,
    ValidationWrongRed
} from "../../../components/src/IconAssets";
import { AppConstant } from "../../../components/src/Constant";

const images = require("./assets")
const configJSON = require("./config")

const webStyle: any = {
    mainCreatePassword: {
        borderRadius: "16px",
        width: "25%",
        margin: "auto",
        padding: "50px 50px",
        height: "auto",
        // overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 8px 4px rgba(225, 216, 229, 0.30)",
        fontFamily: "Fira Sans, sans-serif",
    },
    imageAndText: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    lockImage: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "42px"
    },
    createPasswordText: 
    {
        marginBottom: "22px",
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 500,
        color: "black",
        fontSize: "28px",
    },
    mainPasswordFields: 
    {
        width: "100%",
    },
    label: 
    {
        marginBottom: "10px",
        fontWeight: 400,
        fontFamily: "Fira Sans, sans-serif",
        fontSize: "16px",
    },
    textFieldNewPassword: 
    {
        width: "100%",
        border: "1px solid #E1D8E5",
        borderRadius: "0.5rem",
        padding: "10px 16px",
        marginBottom: "22px",
        boxSizing: "border-box",
    },
    textFieldConfirmPassword: 
    {
        padding: "10px 16px",
        width: "100%",
        border: "1px solid #E1D8E5",
        boxSizing: "border-box",
        borderRadius: "0.5rem",
        marginBottom: "16px"
    },
    validationMessages: 
    {
        marginBottom: "8px",
        fontSize: "16px",
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 400,
        gap: "10px",
        display: "flex",
    },
    saveNewPasswordButton: 
    {
        alignItems: "center",
        display: "block",
        color: "white",
        borderRadius: "8px",
        justifyContent: "center",
        flexDirection: "column",
        textTransform: "capitalize",
        width: "100%",
        padding: "16px",
        fontSize: "18px",
        fontWeight: 700,
        marginBottom: "20px",
        fontFamily: "Fira Sans, sans-serif",
        cursor: "pointer"
    },
    backToLogin: 
    {
        alignItems: "center",
        fontSize: "18px",
        justifyContent: "center",
        fontWeight: 700,
        color: "#503A65",
        fontFamily: "Fira Sans, sans-serif",
        textDecorationLine: "underline",
        cursor: "pointer",
        textAlign: "center"
    },
    mainButtonsContainer: 
    {
        flexDirection: "column",
        display: "flex",
    },
    errorMessage: 
    {
        fontSize: '16px',
        color: "red",
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 400,
    },
    textFieldConfirmPasswordError: 
    {
        borderRadius: "0.5rem",
        marginBottom: "16px",
        width: "100%",
        padding: "10px 16px",
        boxSizing: "border-box",
        border: "1px solid red",
    },
    textFieldNewPasswordError: 
    {
        width: "100%",
        marginBottom: "22px",
        borderRadius: "0.5rem",
        padding: "10px 16px",
        boxSizing: "border-box",
        border: "1px solid red",
    }
}
// Customizable Area End

export default class CreatePassword extends CreatePasswordController {
    constructor(props: Props) {
        super(props)
    }
    // Customizable Area Start
    validationsCreate = () => {
        return (
            <ul style={{ listStyleType: 'none', padding: 0, marginBottom: "60px" }}>
                <li style={{ color: this.state.validationsCreate[0] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validationsCreate[0] ? <ValidationRightGreen /> : <ValidationWrongRed />} {AppConstant.passwordLengthValidation}
                </li>
                <li style={{ color: this.state.validationsCreate[1] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validationsCreate[1] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.uppercaseLetter}
                </li>
                <li style={{ color: this.state.validationsCreate[2] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validationsCreate[2] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.lowercaseletter}
                </li>
                <li style={{ color: this.state.validationsCreate[3] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validationsCreate[3] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.number}
                </li>
                <li style={{ color: this.state.validationsCreate[4] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validationsCreate[4] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.specialCharacter}
                </li>
            </ul>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div style={webStyle.mainCreatePassword}>

                {/* Image and Text container */}
                <Grid container style={webStyle.imageAndText}>
                    <Grid item>
                        <img src={images.lockIcon} style={webStyle.lockImage} />
                    </Grid>
                </Grid>
                <Grid item style={webStyle.createPasswordText}>
                    {configJSON.createPasswordText}
                </Grid>

                {/* Fields */}
                <Grid item style={webStyle.mainPasswordFields}>
                    <Typography className="label" style={webStyle.label}>{configJSON.newPassword}</Typography>
                    <TextField
                        style={
                            this.state.passwordNotMatchError
                                ? webStyle.textFieldNewPasswordError
                                : webStyle.textFieldNewPassword
                        }
                        type={this.state.showNewPassword ? "text" : "password"}
                        data-test-id="newPassword"
                        name="newPassword"
                        value={this.state.passwordCreate}
                        onChange={this.handlePasswordChangeCreate}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <img src={images.passwordFieldIcon} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle passwordCreate visibility"
                                        onClick={this.handlePasswordVisdibilityNewPassword}
                                        data-test-id="passwordCreate-visibility"
                                        edge=
                                        "end"
                                    >
                                        {
                                        this.state.showNewPassword ? 
                                        (
                                            <Visibility 
                                            style={{ color: "#b493b7" }} 
                                            />
                                        ) : (
                                            <VisibilityOff 
                                            style={{ color: "#b493b7" }} 
                                            />
                                        )
                                        }
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                    }
                    />

                    <Typography className="label" style={webStyle.label}>{configJSON.confirmNewPassword}</Typography>
                    <TextField
                        style=
                        {
                            this.state.passwordNotMatchError
                                ? webStyle.textFieldConfirmPasswordError
                                : webStyle.textFieldConfirmPassword
                        }
                        type={
                            this.state.showConfirmPassword ? "text" : "password"
                        }
                        data-test-id="confirmNewpassword"
                        name="confirmNewpassword"
                        value={this.state.reEnterPasswordCreate}
                        onChange={this.handleConfirmPasswordChange}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <img src={images.passwordFieldIcon} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle passwordCreate visibility"
                                        onClick={this.handlePasswordVisdibilityConfirmNewPassword}
                                        data-test-id="passwordCreate-visibility"
                                        edge="end"
                                    >
                                        {this.state.showConfirmPassword ? (
                                            <Visibility style={{ color: "#b493b7" }} />
                                        ) : (
                                            <VisibilityOff style={{ color: "#b493b7" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Grid item style={webStyle.errorMessage}>
                        {this.state.passwordNotMatchError && this.state.passwordNotMatchErrorMsg}
                    </Grid>
                </Grid>

                {/* Validations */}
                <Grid>
                    {this.validationsCreate()}
                </Grid>

                {/* Buttons */}
                <Grid container style={webStyle.mainButtonsContainer}>
                    <Grid item style={webStyle.mainButton}>
                        <Button
                            style={{ backgroundColor: this.state.buttonDisabled ? '#A79CB2' : '#503A65', ...webStyle.saveNewPasswordButton }}
                            data-test-id="saveNewPassword"
                            disabled={this.state.buttonDisabled}
                            onClick={this.handleSaveNewPasswordCreate}
                        >
                            {configJSON.saveNewPasswordButton}
                        </Button>
                    </Grid>
                </Grid>
            </div>
            // Customizable Area End
        )
    }
}
