// Customizable Area Start
import React from "react";
import {
    Select,
    InputAdornment,
    OutlinedInput,
    Button,
    MenuItem
} from "@material-ui/core";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { withRouter } from 'react-router-dom';
import PersonalInfoController, { Props } from "./PersonalInfoController.web";
import '../../assets/css/DataEntryScreens.web.css';
import { iconArrowDown } from "../assets";
import {
    CalendarIcon,
    IconInfo,
    IconStepper,
    IconSubmitStepper
} from "../../../../components/src/IconAssets";
import Sidebar from "../../../../components/src/Sidebar.web";
import Header from "../../../../components/src/Header.web";
// Customizable Area End


export class PersonalInfo extends PersonalInfoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleError = (error: any) => {
        if (error) {
            return (
                <div className="validation-error">{error}</div>
            );
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { personalInfo, personalInfoErrors } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header/>
                <Sidebar/>
                <div className="dataEntry-page">
                    <div className="form-stepper">
                        <div className="step">
                            <span className="step-icon"><IconStepper /></span>
                            <span className="step-name">Business Info</span>
                        </div>
                        <div className="step active">
                            <span className="step-icon"><IconStepper /></span>
                            <span className="step-name">Personal Info</span>
                        </div>
                        <div className="step">
                            <span className="step-icon"><IconSubmitStepper /></span>
                            <span className="step-name">Submit</span>
                        </div>
                    </div>
                    <div className="PersoanlInfo-container" data-test-id="Main">
                        <form
                            className="personalInfo-form"
                            onSubmit={(e) => this.handleSubmitPersonalInfo(e)}
                            data-test-id="personalInfoForm"
                        >
                            {personalInfo?.map((item, index) =>
                                <div className="form-division" key={JSON.stringify(index)}>
                                    <div className="division-header">
                                        <h2>Personal Info {index + 1}</h2>
                                        {(index === (personalInfo.length - 1)) &&
                                            <Button
                                                onClick={() => {
                                                    this.handleAddMorePersonalInfo();
                                                    this.handlePushValidationForm();
                                                }}
                                                data-test-id="addMoreBtn"
                                            >
                                                Add More
                                            </Button>
                                        }
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="first_name">First Name</label>
                                        <OutlinedInput
                                            type="text"
                                            id="first_name"
                                            name="first_name"
                                            data-test-id="firstNameInput"
                                            value={item.first_name}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                        {this.handleError(personalInfoErrors[index]?.first_name)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="Middle">Middle Initial</label>
                                        <Select
                                            id="Middle"
                                            name="middle_initial"
                                            className="select-box"
                                            variant="outlined"
                                            data-test-id="middleInitialInput"
                                            value={item.middle_initial}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                                </InputAdornment>
                                            }
                                        >
                                            <MenuItem className="select-option" value="iso1">opt 1</MenuItem>
                                            <MenuItem className="select-option" value="iso2">opt 2</MenuItem>
                                            <MenuItem className="select-option" value="iso3">opt 3</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="last_name">Last Name</label>
                                        <OutlinedInput
                                            type="text"
                                            id="last_name"
                                            name="last_name"
                                            data-test-id="lastNameInput"
                                            value={item.last_name}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                        {this.handleError(personalInfoErrors[index]?.last_name)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="sufix">Sufix</label>
                                        <OutlinedInput
                                            type="text"
                                            id="sufix"
                                            name="sufix"
                                            data-test-id="suffixInput"
                                            value={item.sufix}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="DOB">DOB</label>
                                        <DatePicker
                                            id="DOB"
                                            name="dob"
                                            className="date_picker"
                                            data-test-id="dobInput"
                                            value={item.dob}
                                            onChange={(newDate) => this.handlePersonalInfoDobChange(newDate, index)}
                                            format="MM/dd/yyyy"
                                            maxDate={new Date()}
                                            calendarIcon={<CalendarIcon />}
                                            clearIcon={null}
                                        />
                                        {this.handleError(personalInfoErrors[index]?.dob)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="gender">Principal Gender</label>
                                        <Select
                                            id="gender"
                                            name="principal_gender"
                                            className="select-box"
                                            variant="outlined"
                                            data-test-id="genderName"
                                            value={item.principal_gender}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                                </InputAdornment>
                                            }
                                        >
                                            <MenuItem className="select-option" value="male">Male</MenuItem>
                                            <MenuItem className="select-option" value="female">Female</MenuItem>
                                            <MenuItem className="select-option" value="other">Other</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="SSN">SSN</label>
                                        <OutlinedInput
                                            type="text"
                                            id="SSN"
                                            name="ssn"
                                            data-test-id="ssnInput"
                                            value={item.ssn}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                            endAdornment={
                                                <InputAdornment position="end"><IconInfo /></InputAdornment>
                                            }
                                        />
                                        {this.handleError(personalInfoErrors[index]?.ssn)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="ownership">% Ownership</label>
                                        <OutlinedInput
                                            type="text"
                                            id="ownership"
                                            name="ownership"
                                            data-test-id="ownershipInput"
                                            value={item.ownership}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="address">Address</label>
                                        <OutlinedInput
                                            type="text"
                                            id="address"
                                            name="address"
                                            data-test-id="addressInput"
                                            value={item.address}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                        {this.handleError(personalInfoErrors[index]?.address)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="city">City</label>
                                        <OutlinedInput
                                            type="text"
                                            id="city"
                                            name="city"
                                            data-test-id="cityInput"
                                            value={item.city}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                        {this.handleError(personalInfoErrors[index]?.city)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="state">State</label>
                                        <Select
                                            id="state"
                                            name="state"
                                            className="select-box"
                                            variant="outlined"
                                            data-test-id="stateInput"
                                            value={item.state}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                                </InputAdornment>
                                            }
                                        >
                                            <MenuItem className="select-option" value="st1">st1</MenuItem>
                                            <MenuItem className="select-option" value="st2">st2</MenuItem>
                                            <MenuItem className="select-option" value="st3">st3</MenuItem>
                                        </Select>
                                        {this.handleError(personalInfoErrors[index]?.state)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="zip">Principal Zip</label>
                                        <OutlinedInput
                                            type="text"
                                            id="zip"
                                            name="principal_zip"
                                            data-test-id="zipInput"
                                            value={item.principal_zip}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                        {this.handleError(personalInfoErrors[index]?.principal_zip)}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="email">Email</label>
                                        <OutlinedInput
                                            type="email"
                                            id="email"
                                            name="email"
                                            data-test-id="emailInput"
                                            value={item.email}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="homephone">Home phone</label>
                                        <OutlinedInput
                                            type="number"
                                            id="homephone"
                                            name="home_phone"
                                            data-test-id="homePhoneInput"
                                            value={item.home_phone}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="cellphone">Cell-Phone</label>
                                        <OutlinedInput
                                            type="number"
                                            id="cellphone"
                                            name="cell_phone"
                                            data-test-id="cellPhoneInput"
                                            value={item.cell_phone}
                                            onChange={(e) => this.handlePersonalInfoChange(e, index)}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="form-actions">
                                <Button
                                    className="back-btn"
                                    data-test-id="backBtn"
                                    onClick={() => this.props.history.push('/dataentry/businessinfo')}
                                >
                                    Back
                                </Button>
                                <Button type="submit">Save and  Next</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withRouter(PersonalInfo as any);
// Customizable Area End
