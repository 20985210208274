import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { webStyles } from "./MerchantFinancials.web";
import { WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// Customizable Area Start
const moment = require('moment');
// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps, WithStyles<typeof webStyles> {
  navigation?: any;
  history: any;
  location: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedDate: any;
  bankInfo: any[];
  mcaData: any[];
  isDeleteDialogOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MerchantFinancialsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      selectedDate: "",
      bankInfo: [],
      mcaData: [],
      isDeleteDialogOpen: false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      bankInfo: [
        { code: "code1", month: "Sep-2023", bank: "bank1", acct: "1234", adjusted_td: "$123459.78", td: "$123", a_b: "$123", e_b: "$1234", nsf: "0", od: "0", d_n: "0", vol: "12", mca: "$123", misc: "$123" },
        { code: "code2", month: "Oct-2023", bank: "bank2", acct: "5678", adjusted_td: "$567890.12", td: "$456", a_b: "$456", e_b: "$5678", nsf: "1", od: "1", d_n: "1", vol: "20", mca: "$456", misc: "$456" },
        { code: "code3", month: "Nov-2023", bank: "bank3", acct: "9012", adjusted_td: "$901234.56", td: "$789", a_b: "$789", e_b: "$9012", nsf: "2", od: "2", d_n: "2", vol: "30", mca: "$789", misc: "$789" },
        { code: "code4", month: "Dec-2023", bank: "bank4", acct: "3456", adjusted_td: "$345678.90", td: "$101", a_b: "$101", e_b: "$3456", nsf: "3", od: "3", d_n: "3", vol: "40", mca: "$101", misc: "$101" },
        { code: "code5", month: "Jan-2024", bank: "bank5", acct: "7890", adjusted_td: "$789012.34", td: "$112", a_b: "$112", e_b: "$7890", nsf: "4", od: "4", d_n: "4", vol: "50", mca: "$112", misc: "$112" }
      ],
      mcaData: [
        { funder: "2211 BYZ ", funded: "$24567.7656", date: "Sep-2023", pmt: "$4599.0", fqcy: 4, active: "Yes", payoff: "No", balance: "$24567.7656", monthly_obligation: "$3459.78", holdback: "12.87%", }
      ]
    });
  }
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
