import React, { Component } from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';
import {
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Menu
} from '@material-ui/core';
import './Header.web.css';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { EmainIcon, NotificationIcon, PhoenixLogoName } from './IconAssets';
import { getStorageData } from '../../framework/src/Utilities';
const images = require("./assets");

// Customizable Area Start
const styles = (_theme: Theme) => createStyles({
  headerWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  headerFixedWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  appHeaderMain: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: "15px 30px",
    paddingLeft: "20px",
    minHeight: 75,
    background: "#FFFFFF",
    boxSizing: 'border-box',
    gap: "10px",
    borderBottom: "1px solid #E1D8E5",
    "@media (max-width: 639px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "& *": {
      fontFamily: 'Fira Sans, sans-serif !important',
      boxSizing: 'border-box',
    }
  },
  appLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    "& img": {
      marginRight: 50,
    },
    "@media (max-width: 639px)": {
      "& svg": {
        width: 120,
      },
      "& img": {
        marginRight: 10,
      }
    }
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 560,
    width: "100%",
    "& .search-input": {
      width: "100%",
    },
    "& .MuiInput-root": {
      border: "1px solid #E1D8E5",
      height: 44,
      padding: "10px 16px",
      borderRadius: 44,
      "& input": {
        fontSize: 15,
        lineHeight: 'normal',
        padding: "0 !important",
        height: "100%",
        "&:placeholder": {
          color: "#94A3B8",
        }
      }
    },
    "& fieldset": {
      display: 'none',
    }
  },
  headerEndActions: {
    display: 'flex',
    alignItems: 'center',
    "& .notification-btn": {
      minWidth: "auto",
      marginRight: 30,
      borderRadius: "50%",
      "@media (max-width: 767px)": {
        marginRight: 0,
      }
    },
    "& .communication-btn": {
      minWidth: "auto",
      marginRight: 10,
      "@media (max-width: 767px)": {
        marginRight: 0,
      }
    },
    "& .user-profile": {
      display: 'flex',
      alignItems: 'center',
      fontSize: 15,
      lineHeight: "normal",
      "& img": {
        borderRadius: "50%",
        objectFit: "cover",
        marginLeft: 8,
      },
      "@media (max-width: 767px)": {
        "& span": {
          display: "none",
        }
      }
    }
  }
});
// Customizable Area End

interface Props extends RouteComponentProps, WithStyles<typeof styles> { }
interface S {
  anchorEl: HTMLElement | null;
  userInfo: any;
  isScrolled: boolean;
}

class Header extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      anchorEl: null,
      userInfo: {},
      isScrolled: false,
    };
  }

  async componentDidMount() {
    const data = JSON.parse(await getStorageData('userInfo'));
    data && this.setState({ userInfo: data });

    window.addEventListener('scroll', () => {
      if (window.scrollY > 80) {
        this.setState({ isScrolled: true });
      } else {
        this.setState({ isScrolled: false });
      }
    });
  }

  handleMenuOpen = (e: any) => {
    this.setState({ anchorEl: e.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.state.isScrolled ? classes.headerFixedWrapper : classes.headerWrapper}>
        <header className={classes.appHeaderMain}>
          <div className={classes.appLogoWrapper}>
            <img src={images.logoP} alt="P" width={40} />
            <PhoenixLogoName />
          </div>
          <div className={classes.searchBar}>
            <TextField
              className='search-input'
              type="text"
              placeholder='Search'
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={images.searchIcon} height={22} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.headerEndActions}>
            {this.props.location.pathname.includes('underWriter') &&
              <Button className='communication-btn'><EmainIcon/></Button>
            }
            <Button className='notification-btn'>
              <NotificationIcon />
            </Button>
            <div className="user-profile" onClick={(e) => this.handleMenuOpen(e)}>
              <span>{this.state.userInfo?.first_name}</span>
              <img src={images.accountPic} alt="" height={40} width={40} />
            </div>
          </div>
          <Menu
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
          </Menu>
        </header>
      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(styles)(withRouter(Header)) as any;
