// Customizable Area Start
import React from "react";
import {
    Select,
    InputAdornment,
    OutlinedInput,
    Button,
    MenuItem
} from "@material-ui/core";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { withRouter } from 'react-router-dom';
import BusinessInfoController, { Props } from "./BusinessInfoController.web";
import '../../assets/css/DataEntryScreens.web.css';
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import { iconArrowDown } from "../assets";
import {
    CalendarIcon,
    IconInfo,
    IconStepper,
    IconSubmitStepper
} from "../../../../components/src/IconAssets";
// Customizable Area End


export class BusinessInfo extends BusinessInfoController {
    getStorageData: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderError = (error: any) => {
        if (error) {
            return (
                <div className="validation-error">{error}</div>
            );
        }
    }
    // Customizable Area End

    render() {

        const { businessInfo, typeOfEntityOptions } = this.state;

        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <div className="dataEntry-page">
                    <div className="form-stepper">
                        <div className="step active">
                            <span className="step-icon"><IconStepper /></span>
                            <span className="step-name">Business Info</span>
                        </div>
                        <div className="step">
                            <span className="step-icon"><IconStepper /></span>
                            <span className="step-name">Personal Info</span>
                        </div>
                        <div className="step">
                            <span className="step-icon"><IconSubmitStepper /></span>
                            <span className="step-name">Submit</span>
                        </div>
                    </div>

                    <div className="BusinessInfo-container">
                        <form
                            className="businessInfo-form"
                            onSubmit={(e) => this.validateFields(e)}
                            data-test-id="businessInfoForm"
                        >
                            <div className="form-part1">
                                <h2>Business Info</h2>
                                <div className="form-field">
                                    <label htmlFor="folder">Folder</label>
                                    <OutlinedInput
                                        type="text"
                                        id="folder"
                                        name="folder"
                                        data-test-id="folderInput"
                                        value={businessInfo.folder}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="iso">ISO</label>
                                    <Select
                                        id="iso"
                                        name="iso"
                                        className="select-box"
                                        variant="outlined"
                                        data-test-id="isoInput"
                                        value={businessInfo.iso}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                            </InputAdornment>
                                        }
                                    >
                                        <MenuItem className="select-option" value="iso1">ISO 1</MenuItem>
                                        <MenuItem className="select-option" value="iso2">ISO 2</MenuItem>
                                        <MenuItem className="select-option" value="iso3">ISO 3</MenuItem>
                                    </Select>
                                    {this.renderError(this.state.businessInfoErrors.iso)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="notes">Notes</label>
                                    <OutlinedInput
                                        type="text"
                                        id="notes"
                                        name="notes"
                                        data-test-id="notesInput"
                                        value={businessInfo.notes}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="form-part2">
                                <h2>Business Info</h2>
                                <div className="form-field">
                                    <label htmlFor="legal_name">Legal Name</label>
                                    <OutlinedInput
                                        type="text"
                                        id="legal_name"
                                        name="legal_name"
                                        data-test-id="legalNameInput"
                                        value={businessInfo.legal_name}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                    {this.renderError(this.state.businessInfoErrors.legal_name)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="type_on_entity">Type On Entity</label>
                                    <Select
                                        id="type_on_entity"
                                        name="type_on_entity"
                                        className="select-box"
                                        variant="outlined"
                                        data-test-id="entityTypeInput"
                                        value={businessInfo.type_on_entity}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                            </InputAdornment>
                                        }
                                    >
                                        {typeOfEntityOptions.length > 0 && typeOfEntityOptions.map((item:string, index:number) =>
                                            <MenuItem className="select-option" value={item} key={JSON.stringify(index)}>
                                                {item}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    {this.renderError(this.state.businessInfoErrors.type_on_entity)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="merchant">Merchant (DBA)</label>
                                    <OutlinedInput
                                        type="text"
                                        id="merchant"
                                        name="merchant"
                                        data-test-id="merchantInput"
                                        value={businessInfo.merchant}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        onInput={(e) => this.handleMerchantInput(e)}
                                    />
                                    {this.renderError(this.state.businessInfoErrors.merchant)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="address">Address</label>
                                    <OutlinedInput
                                        type="text"
                                        id="address"
                                        name="address"
                                        data-test-id="addressInput"
                                        value={businessInfo.address}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                    {this.renderError(this.state.businessInfoErrors.address)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="fein">FEIN</label>
                                    <OutlinedInput
                                        type="text"
                                        id="fein"
                                        name="fein"
                                        data-test-id="feinInput"
                                        value={businessInfo.fein}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end"><IconInfo /></InputAdornment>
                                        }
                                    />
                                    {this.renderError(this.state.businessInfoErrors.fein)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="city">City</label>
                                    <OutlinedInput
                                        type="text"
                                        id="city"
                                        name="city"
                                        data-test-id="cityInput"
                                        value={businessInfo.city}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                    {this.renderError(this.state.businessInfoErrors.city)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="st">ST</label>
                                    <Select
                                        type="text"
                                        id="st"
                                        name="st"
                                        className="select-box"
                                        variant="outlined"
                                        data-test-id="stInput"
                                        value={businessInfo.st}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                            </InputAdornment>
                                        }
                                    >
                                        <MenuItem className="select-option" value="st1">st1</MenuItem>
                                        <MenuItem className="select-option" value="st2">st2</MenuItem>
                                        <MenuItem className="select-option" value="st3">st3</MenuItem>
                                    </Select>
                                    {this.renderError(this.state.businessInfoErrors.st)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="zip">Zip</label>
                                    <OutlinedInput
                                        type="number"
                                        id="zip"
                                        name="zip"
                                        data-test-id="zipInput"
                                        value={businessInfo.zip}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                    {this.renderError(this.state.businessInfoErrors.zip)}
                                </div>
                                {/* <div className="form-field">
                                    <label htmlFor="country">Country</label>
                                    <Select
                                        type="text"
                                        id="country"
                                        name="country"
                                        className="select-box"
                                        variant="outlined"
                                        data-test-id="countryInput"
                                        value={businessInfo.country}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                            </InputAdornment>
                                        }
                                    >
                                        <MenuItem className="select-option" value="India">India</MenuItem>
                                        <MenuItem className="select-option" value="America">America</MenuItem>
                                        <MenuItem className="select-option" value="Canada">Canada</MenuItem>
                                    </Select>
                                    {this.renderError(this.state.businessInfoErrors.country)}
                                </div> */}
                                <div className="form-field">
                                    <label htmlFor="phone">Phone</label>
                                    <OutlinedInput
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        data-test-id="phoneInput"
                                        value={businessInfo.phone}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                    {this.renderError(this.state.businessInfoErrors.phone)}
                                </div>
                                <div className="form-field">
                                    <label htmlFor="email">Email</label>
                                    <OutlinedInput
                                        type="email"
                                        id="email"
                                        name="email"
                                        data-test-id="emailInput"
                                        value={businessInfo.email}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end"><IconInfo /></InputAdornment>
                                        }
                                    />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="start_date">Start Date</label>
                                    <DatePicker
                                        id="start_date"
                                        name="start_date"
                                        className="date_picker"
                                        data-test-id="dateInput"
                                        onChange={(newDate) => this.handleStartDateChange(newDate)}
                                        value={businessInfo.start_date}
                                        format="MM/dd/yyyy"
                                        calendarIcon={<CalendarIcon />}
                                        clearIcon={null}
                                    />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="type">Type</label>
                                    <Select
                                        type="text"
                                        id="type"
                                        name="type"
                                        className="select-box"
                                        variant="outlined"
                                        data-test-id="businessTypeInput"
                                        value={businessInfo.type}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                            </InputAdornment>
                                        }
                                    >
                                        <MenuItem className="select-option" value="business1">business1</MenuItem>
                                        <MenuItem className="select-option" value="business2">business3</MenuItem>
                                        <MenuItem className="select-option" value="business3">business2</MenuItem>
                                    </Select>
                                </div>
                                <div className="form-field">
                                    <label htmlFor="website">Website</label>
                                    <OutlinedInput
                                        type="text"
                                        id="website"
                                        name="website"
                                        data-test-id="websiteInput"
                                        value={businessInfo.website}
                                        onChange={(e) => this.handleBusinessInfoChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="form-actions">
                                <Button type="submit">Save and Next</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withRouter(BusinessInfo as any);
// Customizable Area End
