// Customizable Area Start
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
    OverviewIcon,
    FinancialsIcon,
    DataIcon,
    NotesIcon,
    DocumentsIcon,
    PerformanceIcon,
    SyndicationIcon
} from "../../../../components/src/IconAssets";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

interface Props extends RouteComponentProps, WithStyles<typeof styles> { }

interface States {
    steps: any[];
}

interface SS { }

const stepsArr = [
    { path: '/underWriter/merchantOverview', icon: <OverviewIcon />, label: 'Overview' },
    { path: '/underWriter/merchantFinancials', icon: <FinancialsIcon />, label: 'Financials' },
    { path: '', icon: <DataIcon />, label: 'Data' },
    { path: '/underWriter/notes', icon: <NotesIcon />, label: 'Notes' },
    { path: '/underWriter/documents', icon: <DocumentsIcon />, label: 'Documents' },
    { path: '/underWriter/payments', icon: <PerformanceIcon />, label: 'Performance (payments)' },
    { path: '', icon: <SyndicationIcon />, label: 'Syndication' },
];
// Customizable Area End

/* istanbul ignore next */
class UnderwriterScreenStepper extends React.Component<Props, States, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            steps: stepsArr,
        };
        // Customizable Area End
    }

    // Customizable Area Start
    handleStepperNavigation = (path: string) => {
        const { location, history } = this.props;
        if (path && location.pathname !== path) {
            history.push(path);
        }
    }

    isActiveTab = (path: string): boolean => this.props.location.pathname === path;
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { steps } = this.state;
        return (
            // Customizable Area Start
            <ul className={classes.stepperMain}>
                {steps.map(step => (
                    <li
                        key={step.path}
                        data-test-id="step"
                        className={`${this.isActiveTab(step.path) ? 'active' : ''} step`}
                        onClick={() => this.handleStepperNavigation(step.path)}
                    >
                        {React.cloneElement(step.icon, { color: this.isActiveTab(step.path) ? "#513A66" : "#818181" })}
                        <span>{step.label}</span>
                    </li>
                ))}
            </ul>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const styles = (_theme: Theme) => createStyles({
    stepperMain: {
        listStyleType: "none",
        margin: 0,
        overflowX: "auto",
        display: "flex",
        justifyContent: "space-between",
        gap: 20,
        border: "1px solid #E1D8E5",
        borderRadius: 8,
        padding: "13px 21px",
        boxSizing: "border-box",
        "& .step": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            color: "#818181",
            fontSize: 16,
            lineHeight: "normal",
            gap: 4,
            cursor: "pointer",
            "&.active": {
                color: "#513A66",
                "& span": {
                    textDecoration: "underline",
                }
            },
            "@media (max-width: 767px)": {
                fontSize: 14,
            }
        }
    },
});

export default withStyles(styles)(withRouter(UnderwriterScreenStepper)) as any;
// Customizable Area End
