import React from "react";
import ResetPasswordController, {
    Props
} from "../../../blocks/forgot-password/src/ResetPasswordController.web";

const images = require("./assets");
const configJSON = require("./config");

const webStyle: any = {
    mainPage: {
        borderRadius: "16px",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        padding: "64px 100px",
        height: "auto",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 8px 4px rgba(225, 216, 229, 0.30)",
        fontFamily: "Fira Sans, sans-serif",
    },
    backToLogin: {
        alignItems: "center",
        justifyContent: "center",
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Fira Sans, sans-serif",
        color: "#503A65",
        textDecorationLine: "underline",
        cursor: "pointer",
        textAlign: "center"
    },
    successMessage: {
        fontSize: "28px",
        fontWeight: 500,
        fontFamily: "Fira Sans, sans-serif",
        color: "#059669",
        marginBottom: "61px"
    },
    successImage: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "61px"
    }
}

export default class ResetPasswordSuccessfull extends ResetPasswordController{
    constructor(props: Props){
        super(props)
    }

    render() {
        return(
            <div style={webStyle.mainPage}>
                    <div style={webStyle.successImage}>
                        <img src={images.successGreen} />
                    </div>
                    <div style={webStyle.successMessage}>
                        {configJSON.successMessage}
                    </div>
                    <div style={webStyle.backToLogin} onClick={this.handleBackToLogin}>
                        {configJSON.backToLoginButtonText}
                    </div>
            </div>
        )
    }
}