import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import {
  getStorageData,
  setStorageData
} from "../../../../framework/src/Utilities";
import apiCall from "../../../../components/src/apiCall.web";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  businessInfo: any;
  is_edit: boolean;
  businessInfoErrors: any;
  typeOfEntityOptions: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BusinessInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTypeOfEntityOptionsCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      businessInfo: {
        folder: "",
        iso: "",
        notes: "",
        legal_name: "",
        type_on_entity: "",
        merchant: "",
        address: "",
        fein: "",
        city: "",
        st: "",
        zip: "",
        phone: "",
        email: "",
        start_date: "",
        type: "",
        website: "",
      },
      is_edit: false,
      businessInfoErrors: {},
      typeOfEntityOptions: [],
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      businessInfoErrors: {
        iso: "",
        legal_name: "",
        type_on_entity: "",
        merchant: "",
        address: "",
        fein: "",
        city: "",
        st: "",
        zip: "",
        phone: ""
      }
    });
    this.handleHistoryData();
    this.getTypeOfEntityOptions();
  }
  // Customizable Area End

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getTypeOfEntityOptionsCallId) {
        if (responseJson.type_on_entity) {
          this.setState({ typeOfEntityOptions: responseJson.type_on_entity });
        }
      }
    }
    // Customizable Area End
    runEngine.debugLog("Message Recived", message);
  }

  // Customizable Area Start
  handleDateFormat = (date: any) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  getTypeOfEntityOptions = async () => {
    const token = await getStorageData('userToken');

    const requestHeaders = {
      "Content-Type": "application/json",
      "token": token
    }
    this.getTypeOfEntityOptionsCallId = await apiCall({
      header: requestHeaders,
      method: "GET",
      endpoint: configJSON.getTypeOfEntityApiEndPoint,
    });
  }

  handleHistoryData = async () => {
    const storedData: any = JSON.parse(await getStorageData("businessInfo"));
    const isEdit: any = JSON.parse(await getStorageData("is_edit"));

    if (storedData && Object.keys(storedData).length > 0) {
      this.setState({
        businessInfo: storedData,
        is_edit: isEdit || this.state.is_edit,
      });
    }
  }

  handleBusinessInfoChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({
      businessInfo: { ...this.state.businessInfo, [name]: value },
      businessInfoErrors: {
        ...this.state.businessInfoErrors,
        [name]: ""
      }
    });
  }

  handleStartDateChange = (selectedDate: any) => {
    if (selectedDate) {
      this.setState({
        businessInfo: {
          ...this.state.businessInfo,
          start_date: this.handleDateFormat(selectedDate)
        }
      });
    }
  }

  handleMerchantInput = (e: any) => {
    const alphanumericPattern: RegExp = /^[A-Za-z0-9]*$/g;
    if (!alphanumericPattern.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '');
    }
  }

  validateFields = (e: any) => {
    e.preventDefault();

    const updatedErrors = Object.keys(this.state.businessInfo).reduce((errors, key) => {
      if (this.state.businessInfoErrors.hasOwnProperty(key)) {
        const selectFields = ['type_on_entity', 'iso', 'st'];
        if (!this.state.businessInfo[key]) {
          errors[key] = `Please ${selectFields.includes(key) ? "select" : "enter"} ${key.replace(/[_/\\]/g, ' ')}`;
        } else {
          errors[key] = "";
        }
      }
      return errors;
    }, { ...this.state.businessInfoErrors });
    this.setState({ businessInfoErrors: updatedErrors });

    if (this.state.businessInfo.fein) {
      const feinRegex = /^\d{2}-\d{7}$/g;
      if (!feinRegex.test(this.state.businessInfo.fein)) {
        this.setState({
          businessInfoErrors: {
            ...this.state.businessInfoErrors,
            fein: 'Please enter valid fein'
          }
        });
      }
    }
    setTimeout(() => {
      const AllEmpty = Object.values(this.state.businessInfoErrors).every(value => value === "");
      AllEmpty && this.handleSubmitBusinessInfo();
    }, 500);
  }

  handleSubmitBusinessInfo = async () => {
    setStorageData('businessInfo', JSON.stringify(this.state.businessInfo));

    if (this.state.is_edit) {
      this.setState({ is_edit: false }, () => {
        setStorageData('is_edit', false);
        this.props.history.push("/dataentry/reviewinfo");
      });
    } else {
      this.props.history.push("/dataentry/personalinfo");
    }
  }
  // Customizable Area End
}
