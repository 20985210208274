// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfyelpintegration from "../../blocks/cfyelpintegration/src/Cfyelpintegration";
import Cfplaid from "../../blocks/cfplaid/src/Cfplaid";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Cfexperianapi from "../../blocks/cfexperianapi/src/Cfexperianapi";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Automatedemailsending from "../../blocks/automatedemailsending/src/Automatedemailsending";
import Cfconverttopdf from "../../blocks/cfconverttopdf/src/Cfconverttopdf";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Cfgoogleplacesapi2 from "../../blocks/cfgoogleplacesapi2/src/Cfgoogleplacesapi2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Cfachapi from "../../blocks/cfachapi/src/Cfachapi";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Scoring from "../../blocks/scoring/src/Scoring";
import Dataencryption2 from "../../blocks/dataencryption2/src/Dataencryption2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Recurringappointments from "../../blocks/recurringappointments/src/Recurringappointments";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Cfplaid4 from "../../blocks/cfplaid4/src/Cfplaid4";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Slackintegration2 from "../../blocks/slackintegration2/src/Slackintegration2";
import Analytics from "../../blocks/analytics/src/Analytics";
import Onlinebackup from "../../blocks/onlinebackup/src/Onlinebackup";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Datamigration2 from "../../blocks/datamigration2/src/Datamigration2";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
// Customizable Area Start
import DataEntryUserScreen from "../../blocks/customform/src/DataEntryUser/DataEntryUserScreen.web";
import BusinessInfo from "../../blocks/customform/src/DataEntryUser/BusinessInfo.web";
import PersonalInfo from "../../blocks/customform/src/DataEntryUser/PersonalInfo.web";
import ReviewInfo from "../../blocks/customform/src/DataEntryUser/ReviewInfo.web";
import AccountCreation from "../../blocks/email-account-registration/src/AccountCreation.web";
import EmailLoginWeb from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import UnderWriter from "../../blocks/customform/src/UnderwriterUser/Underwriter.web";
import MerchantOverview from "../../blocks/customform/src/UnderwriterUser/MerchantOverview.web";
import MerchantFinancials from "../../blocks/customform/src/UnderwriterUser/MerchantFinancials.web";
import MerchantNotes from "../../blocks/customform/src/UnderwriterUser/MerchantNotes.web";
import MerchantDocuments from "../../blocks/customform/src/UnderwriterUser/MerchantDocuments.web";
import MerchantPayments from "../../blocks/customform/src/UnderwriterUser/MerchantPayments.web";
import ForgotPasswordWebBlock from "../../blocks/forgot-password/src/ForgotPasswordBlock.web";
import PasswordResetConfirmation from "../../blocks/forgot-password/src/PasswordResetConfirmation.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ResetPasswordSuccessfull from "../../blocks/forgot-password/src/ResetPasswordSuccessfull.web";
import CreatePassword from "../../blocks/forgot-password/src/CreatePassword.web";
import CreatePasswordSuccessfull from "../../blocks/forgot-password/src/CreatePasswordSuccessfull.web";
// Customizable Area End



const routeMap = {
  // Customizable Area Start
  DataEntryUserScreen: {
    component: DataEntryUserScreen,
    path: "/dataentry",
    exact: true
  },
  BusinessInfo: {
    component: BusinessInfo,
    path: "/dataentry/businessinfo",
    exact: true
  },
  PersonalInfo: {
    component: PersonalInfo,
    path: "/dataentry/personalinfo",
    exact: true
  },
  ReviewInfo: {
    component: ReviewInfo,
    path: "/dataentry/reviewinfo",
    exact: true
  },
  AccountCreation:{
    component: AccountCreation,
    path: "/accountcreation",
    exact: true
  },
  UnderWriter: {
    component: UnderWriter,
    path: "/underwriter",
    exact: true
  },
  MerchantOverview: {
    component: MerchantOverview,
    path: "/underWriter/merchantOverview",
    exact: true
  },
  MerchantFinancials: {
    component: MerchantFinancials,
    path: "/underWriter/merchantFinancials",
    exact: true
  },
  MerchantNotes: {
    component: MerchantNotes,
    path: "/underWriter/notes",
    exact: true
  },
  MerchantDocuments: {
    component: MerchantDocuments,
    path: "/underWriter/documents",
    exact: true
  },
  MerchantPayments: {
    component: MerchantPayments,
    path: "/underWriter/payments",
    exact: true
  },
  EmailLoginWeb: {
    component: EmailLoginWeb,
    path: "/emailLogin"
  },
  ForgotPasswordWebBlock: {
    component: ForgotPasswordWebBlock,
    path: "/forgotpassword",
    exact: true,
  },
  PasswordResetConfirmation: {
    component: PasswordResetConfirmation,
    path: "/resetconfirmation",
    exact: true
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/resetpassword",
    exact: true
  },
  ResetPasswordSuccessfull: {
    component: ResetPasswordSuccessfull,
    path: "/passwordresetsuccessfully",
    exact: true
  },
  CreatePassword: {
    component: CreatePassword,
    path: "/createpassword",
    exact: true
  },
  CreatePasswordSuccessfull: {
    component: CreatePasswordSuccessfull,
    path: "/createpasswordsuccessfully",
    exact: true
  },
  // Customizable Area End
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: "/ActivityFeed"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Cfyelpintegration: {
    component: Cfyelpintegration,
    path: "/Cfyelpintegration"
  },
  Cfplaid: {
    component: Cfplaid,
    path: "/Cfplaid"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPasswordW"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Cfexperianapi: {
    component: Cfexperianapi,
    path: "/Cfexperianapi"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Automatedemailsending: {
    component: Automatedemailsending,
    path: "/Automatedemailsending"
  },
  // ./src/ProjectReporting.web.tsx:{
  //  component:./src/ProjectReporting.web.tsx,
  // path:"/./src/ProjectReporting.web.tsx"},
  Cfconverttopdf: {
    component: Cfconverttopdf,
    path: "/Cfconverttopdf"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: "/SaveAsPdf"
  },
  Cfgoogleplacesapi2: {
    component: Cfgoogleplacesapi2,
    path: "/Cfgoogleplacesapi2"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Cfachapi: {
    component: Cfachapi,
    path: "/Cfachapi"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Scoring: {
    component: Scoring,
    path: "/Scoring"
  },
  Dataencryption2: {
    component: Dataencryption2,
    path: "/Dataencryption2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Recurringappointments: {
    component: Recurringappointments,
    path: "/Recurringappointments"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Cfplaid4: {
    component: Cfplaid4,
    path: "/Cfplaid4"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Slackintegration2: {
    component: Slackintegration2,
    path: "/Slackintegration2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Onlinebackup: {
    component: Onlinebackup,
    path: "/Onlinebackup"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  Datamigration2: {
    component: Datamigration2,
    path: "/Datamigration2"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  GoogleCalendarSync: {
    component: GoogleCalendarSync,
    path: "/GoogleCalendarSync"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;