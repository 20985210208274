// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import '../../assets/css/DataEntryScreens.web.css';
import MerchantFinancialsController, { Props } from "./MerchantFinancialsController.web";
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import {
    Button,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    Dialog
} from "@material-ui/core";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import UnderwriterScreenStepperWeb from "./UnderwriterScreenStepper.web";
import { CalendarIcon, DeleteIcon, EditIcon, OptionsIcon, WarningIcon } from "../../../../components/src/IconAssets";
import CustomDataGrid, { ColumnsProps } from "../../../../components/src/CustomDataGrid.web";
import { iconArrowDown } from "../assets";
import { Chart } from 'react-google-charts';

const baseBankInfoColumns: ColumnsProps[] = [
    { key: 'code', label: 'Code' },
    { key: 'month', label: 'Month' },
    { key: 'bank', label: 'Bank' },
    { key: 'acct', label: 'Account' },
    { key: 'adjusted_td', label: 'Adjusted TD' },
    { key: 'td', label: 'TD' },
    { key: 'a_b', label: 'A/B' },
    { key: 'e_b', label: 'E/B' },
    { key: 'nsf', label: 'NSF' },
    { key: 'od', label: 'OD' },
    { key: 'd_n', label: 'D/N' },
    { key: 'vol', label: 'Volume' },
    { key: 'mca', label: 'MCA' },
    { key: 'misc', label: 'Misc' },
];

const baseMcaDataColumns: ColumnsProps[] = [
    { key: 'funder', label: 'Funder' },
    { key: 'funded', label: 'Funded' },
    { key: 'date', label: 'Date' },
    { key: 'pmt', label: 'Pmt' },
    { key: 'fqcy', label: 'Fqcy' },
    { key: 'active', label: 'Active' },
    { key: 'payoff', label: 'Payoff' },
    { key: 'balance', label: 'Balance' },
    { key: 'monthly_obligation', label: 'Monthly Obligation' },
    { key: 'holdback', label: 'Holdback' },
];

const chartData = [
    ['Category', 'Adjusted TD', 'Avg. Balance', 'Ending Balance', 'MCA'],
    ['Code 2209', 50000, 10000, 20000, 0],
    ['Code 2200', 75000, 25000, 30000, 0],
    ['Code 2208', 100000, 50000, 60000, 80000],
];
// Customizable Area End

export class MerchantFinancials extends MerchantFinancialsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { bankInfo, mcaData } = this.state;
        /* istanbul ignore next */
        const mcaDataColumns: ColumnsProps[] = [
            ...baseMcaDataColumns,
            {
                key: 'action', label: '', cellRender: (_row: any) => (
                    <div className="rowActions">
                        <Button><EditIcon /></Button>
                        <Button data-test-id="rowDeleteBtn" onClick={() => this.setState({ isDeleteDialogOpen: true })}>
                            <DeleteIcon />
                        </Button>
                    </div>
                )
            }
        ];
        
        /* istanbul ignore next */
        const bankInfoColumns: ColumnsProps[] = [
            ...baseBankInfoColumns,
            {
                key: 'action', label: '', cellRender: (row) => <Button className={row.code}><OptionsIcon /></Button>
            }
        ]

        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <main className={`page-main ${classes.mainWrapper}`} data-test-id="Main">
                    <Typography variant="h3" className={classes.pageTitle}>Merchant Overview</Typography>
                    <UnderwriterScreenStepperWeb />
                    <div className={classes.dataTopic}>
                        <div className="topicHeader">
                            <Typography variant="h4">Banking Totals</Typography>
                        </div>
                        <div className={classes.bankingTotals}>
                            <ul className="topicList">
                                <li><span>Total Deposits</span> $763498.78</li>
                                <li><span>Average Balance</span> $763498.78</li>
                                <li><span>NSF</span> 0.00</li>
                                <li><span>Volume</span> 0.00</li>
                                <li><span>Open Advances</span> 1</li>
                                <li><span>Monthly HB %</span> 1</li>
                                <li><span>Net sales</span> $536788.12</li>
                                <li><span>Ending Balance</span> $763498.78</li>
                                <li><span>Over Drafts</span> 0.00</li>
                                <li><span>Over Drafts</span> 0.00</li>
                                <li><span>Monthly HB $ </span> $1234.56</li>
                                <li><span>Monthly Recent MCA</span> $1234.56</li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.dataTopic}>
                        <div className="topicHeader">
                            <Typography variant="h4">Overview</Typography>
                            <ul className="chartLegends">
                                <li><em style={{ background: "#2E6216" }}></em>Adjusted TD</li>
                                <li><em style={{ background: "#3F8DFD" }}></em>Avg. Balance</li>
                                <li><em style={{ background: "#EEAE63" }}></em>Ending Balance</li>
                                <li><em style={{ background: "#FF6581" }}></em>MCA</li>
                            </ul>
                        </div>
                        <div>
                            {/* @ts-ignore */}
                            <Chart
                                width={'100%'}
                                height={'210px'}
                                // @ts-ignore
                                chartType={'Bar'}
                                loader={<div>Loading Chart</div>}
                                data={chartData}
                                options={{
                                    hAxis: {
                                        useHTML: true,
                                        format: '$#,###.00',
                                    },
                                    vAxis: {
                                        title: '',
                                    },
                                    isStacked: true,
                                    legend: { position: 'none' },
                                    series: {
                                        0: { color: '#2E6216' },
                                        1: { color: '#3F8DFD' },
                                        2: { color: '#EEAE63' },
                                        3: { color: '#FF6581' },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.dataTopic}>
                        <div className="topicHeader">
                            <Typography variant="h4">Banking</Typography>
                            <Button>Load Bank</Button>
                        </div>
                        <div className={classes.bankingInfo}>
                            <div className="bankForm">
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="bank">Bank*</label>
                                    <OutlinedInput
                                        id="bank"
                                        name="bank"
                                        placeholder="bank"
                                        data-test-id="bankInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="accNo">AccountNumber*</label>
                                    <OutlinedInput
                                        id="accNo"
                                        name="accNo"
                                        placeholder="accNo"
                                        data-test-id="accNoInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="month">Month*</label>
                                    <DatePicker
                                        id="month"
                                        name="month"
                                        className="date_picker"
                                        data-test-id="monthInput"
                                        format="MM/yyyy"
                                        maxDetail="year"
                                        monthPlaceholder="MM"
                                        yearPlaceholder="YYYY"
                                        value={this.state.selectedDate}
                                        onChange={(newDate: any) => this.setState({ selectedDate: newDate })}
                                        calendarIcon={<CalendarIcon color={'#000000'} />}
                                        clearIcon={null}
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="td">TD</label>
                                    <OutlinedInput
                                        id="td"
                                        name="td"
                                        placeholder="td"
                                        data-test-id="tdInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="avgBalance">Avg Balance</label>
                                    <OutlinedInput
                                        id="avgBalance"
                                        name="avgBalance"
                                        placeholder="avgBalance"
                                        data-test-id="avgBalanceInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="endbalance">End Balance</label>
                                    <OutlinedInput
                                        id="endbalance"
                                        name="endbalance"
                                        placeholder="endbalance"
                                        data-test-id="endbalanceInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="nsf">NSF</label>
                                    <OutlinedInput
                                        id="nsf"
                                        name="nsf"
                                        placeholder="nsf"
                                        data-test-id="nsfInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="od">OD</label>
                                    <OutlinedInput
                                        id="od"
                                        name="od"
                                        placeholder="od"
                                        data-test-id="odInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="nagativeDays">Days Nagative</label>
                                    <OutlinedInput
                                        id="nagativeDays"
                                        name="nagativeDays"
                                        placeholder="nagativeDays"
                                        data-test-id="nagativeDaysInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="volume">Volume</label>
                                    <OutlinedInput
                                        id="volume"
                                        name="volume"
                                        placeholder="volume"
                                        data-test-id="volumeInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="mca">MCA</label>
                                    <OutlinedInput
                                        id="mca"
                                        name="mca"
                                        placeholder="mca"
                                        data-test-id="mcaInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="misc">Misc</label>
                                    <OutlinedInput
                                        id="misc"
                                        name="misc"
                                        placeholder="misc"
                                        data-test-id="miscInput"
                                    />
                                </div>

                            </div>
                            <div className="searchResultsTbl">
                                <CustomDataGrid
                                    date-test-id="bankingDataGrid"
                                    columns={bankInfoColumns}
                                    rows={bankInfo}
                                    rowsPerPage={bankInfo.length}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.dataTopic}>
                        <div className="topicHeader">
                            <Typography variant="h4">MCA</Typography>
                            <Button>Load MCA</Button>
                        </div>
                        <div className={classes.mcaData}>
                            <div className="mcaForm">
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="funder">Funder</label>
                                    <OutlinedInput
                                        id="funder"
                                        name="funder"
                                        placeholder="funder"
                                        data-test-id="funderInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="funded">Funded*</label>
                                    <OutlinedInput
                                        id="funded"
                                        name="funded"
                                        placeholder="funded"
                                        data-test-id="fundedInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="dateFunded">Date Funded*</label>
                                    <DatePicker
                                        id="dateFunded"
                                        name="dateFunded"
                                        className="date_picker"
                                        data-test-id="dateFundedInput"
                                        format="MM/dd/yyyy"
                                        monthPlaceholder="MM"
                                        yearPlaceholder="YYYY"
                                        dayPlaceholder="DD"
                                        value={this.state.selectedDate}
                                        onChange={(newDate: any) => this.setState({ selectedDate: newDate })}
                                        calendarIcon={<CalendarIcon color={'#000000'} />}
                                        clearIcon={null}
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="payment">Payment</label>
                                    <OutlinedInput
                                        id="payment"
                                        name="payment"
                                        placeholder="payment"
                                        data-test-id="paymentInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="frequency">Frequency</label>
                                    <Select
                                        id="frequency"
                                        name="frequency"
                                        className="select-box"
                                        variant="outlined"
                                        data-test-id="deal_type_input"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                            </InputAdornment>
                                        }
                                    >
                                        <MenuItem className="select-option" value="frequency1">Frequency 1</MenuItem>
                                        <MenuItem className="select-option" value="frequency2">Frequency 2</MenuItem>
                                        <MenuItem className="select-option" value="frequency3">Frequency 3</MenuItem>
                                    </Select>
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="action">Action</label>
                                    <OutlinedInput
                                        id="action"
                                        name="action"
                                        placeholder="action"
                                        data-test-id="actionInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="payoff">Payoff</label>
                                    <OutlinedInput
                                        id="payoff"
                                        name="payoff"
                                        placeholder="payoff"
                                        data-test-id="payoffInput"
                                    />
                                </div>
                                <div className={classes.formField} id="formField">
                                    <label htmlFor="balance">Balance</label>
                                    <OutlinedInput
                                        id="balance"
                                        name="balance"
                                        placeholder="balance"
                                        data-test-id="balanceInput"
                                    />
                                </div>
                            </div>
                            <div className="searchResultsTbl">
                                <CustomDataGrid
                                    data-test-id="mcaDataGrid"
                                    columns={mcaDataColumns}
                                    rows={mcaData}
                                    rowsPerPage={mcaData.length}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </div>
                    <Dialog
                        className={classes.deleteRecordDialog}
                        open={this.state.isDeleteDialogOpen}
                        data-test-id="deleteDialog"
                        onClose={() => this.setState({ isDeleteDialogOpen: false })}
                    >
                        <div className="dialog-title">Delete?</div>
                        <div className="delete-que">Are you sure you want to delete <em>this entry?</em></div>
                        <div className="delete-warning">
                            <div className="title"><WarningIcon /> Warning</div>
                            <span>By Deleteing this , you won't be able to undo this action.</span>
                        </div>
                        <div className="dialog-actions">
                            <Button
                                onClick={() => this.setState({ isDeleteDialogOpen: false })}
                                data-test-id="dialog_cancle"
                            >
                                Cancle
                            </Button>
                            <Button
                                onClick={() => this.setState({ isDeleteDialogOpen: false })}
                                className="outlined"
                                data-test-id="dialog_deleteBtn"
                            >
                                Delete
                            </Button>
                        </div>
                    </Dialog>
                </main>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const webStyles = (_theme: Theme) => createStyles({
    pageTitle: {
        width: "100%",
        display: "inline-block",
        marginBottom: 30,
        fontSize: 24,
        lineHeight: "normal",
        fontWeight: 500,
        "@media (max-width: 767px)": {
            fontSize: 22,
        },
    },
    formField: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        '& fieldset': {
            display: 'none',
        },
        '& label': {
            fontSize: 16,
            lineHeight: 'normal',
            color: '#334155',
            "@media (max-width: 767px)": {
                fontSize: 14,
            }
        },
        '& .select-box, .MuiOutlinedInput-root': {
            height: 44,
            borderRadius: 8,
            color: '#000',
            border: '1px solid #E1D8E5',
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            boxSizing: "border-box",
            "& .MuiSelect-root": {
                height: '100%',
                boxSizing: "border-box",
            },
            "& .MuiSelect-icon": {
                display: 'none',
            },
            "& input": {
                height: "100%",
                boxSizing: "border-box",
            },
            "@media (max-width: 767px)": {
                height: 38
            }
        }
    },
    mainWrapper: {
        "& .custom-datagrid": {
            borderColor: "transparent",
            minHeight: "auto",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
            "& table .empty-msg": {
                position: "relative !important",
                display: "table-cell !important",
                textAlign: "center",
            },
            "& .MuiTableRow-root td": {
                borderColor: "transparent",
            },
            "& .MuiTableRow-root td, .MuiTableRow-root th": {
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        "& .pagination-wrapper": {
            display: "none",
        }
    },
    dataTopic: {
        marginTop: 40,
        "@media (max-width: 767px)": {
            marginTop: 30,
        },
        "& .topicHeader": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 15,
            rowGap: 10,
            "& h4": {
                fontSize: 24,
                lineHeight: "normal",
                fontWeight: 500,
                "@media (max-width: 767px)": {
                    fontSize: 22,
                },
            },
            "& button, button:hover": {
                padding: "10px 14px",
                fontWeight: 600,
                fontSize: 18,
                lineHeight: "normal",
                color: "#FFFFFF",
                background: "#503A65",
                textTransform: "none",
                borderRadius: 8,
                "@media (max-width: 767px)": {
                    fontSize: 16,
                    padding: "8px 12px",
                },
            },
            "& .chartLegends": {
                listStyle: "none",
                margin: 0,
                padding: 0,
                display: "flex",
                flexWrap: "wrap",
                columnGap: 15,
                rowGap: 8,
                "& li": {
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    fontSize: 14,
                    lineHeight: "normal",
                    "& em": {
                        height: 18,
                        width: 18,
                        borderRadius: "50%",
                        background: "#2E6216",
                    }
                }
            }
        },
        "& .searchResultsTbl": {
            marginTop: 40,
            "& .MuiTableRow-root td": {
                "& button": {
                    minWidth: "auto",
                },
                "& .rowActions": {
                    display: "inline-flex",
                    gap: 10,
                    "& button:first-child svg": {
                        fill: "#503A65",
                    }
                },
            },
        }
    },
    bankingTotals: {
        borderRadius: 12,
        border: "1px solid #E1D8E5",
        padding: 15,
        "& .topicList": {
            padding: 0,
            margin: 0,
            display: "flex",
            flexWrap: "wrap",
            rowGap: 15,
            justifyContent: "space-between",
            listStyleType: "none",
            "& li": {
                width: "calc(50% - 30px)",
                fontSize: 16,
                lineHeight: "normal",
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width: 767px)": {
                    fontSize: 14,
                    width: "calc(50% - 20px)",
                },
                "@media (max-width: 639px)": {
                    width: "100%",
                },
                "& span": {
                    color: "#818181",
                    textAlign: "left",
                },
            },
        },
    },
    bankingInfo: {
        "& .bankForm": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 20,
            "@media (max-width: 767px)": {
                gap: 15,
            },
            "& >div[id*='formField']": {
                width: "calc(25% - 15px)",
                "@media (max-width: 991px)": {
                    width: "calc(100% / 3 - 15px)",
                },
                "@media (max-width: 767px)": {
                    width: "calc(100% / 2 - 8px)",
                },
                "@media (max-width: 480px)": {
                    width: "100%",
                },
            }
        },
    },
    mcaData: {
        "& .mcaForm": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 20,
            "@media (max-width: 767px)": {
                gap: 15,
            },
            "& >div#formField": {
                width: "calc(25% - 15px)",
                "@media (max-width: 991px)": {
                    width: "calc(100% / 3 - 15px)",
                },
                "@media (max-width: 767px)": {
                    width: "calc(100% / 2 - 8px)",
                },
                "@media (max-width: 480px)": {
                    width: "100%",
                },
            }
        }
    },
    deleteRecordDialog: {
        "& *": {
            fontFamily: "Fira Sans, sans-serif",
        },
        "& .MuiDialog-paper": {
            padding: "16px 24px",
            borderRadius: 8,
            background: "#FFFFFF",
            boxSizing: "border-box",
        },
        "& .dialog-title": {
            display: "inline-block",
            width: "100%",
            fontSize: 30,
            fontWeight: 600,
            lineHeight: "normal",
            textAlign: "center",
            "@media (max-width: 767px)": {
                fontSize: 24,
            },
        },
        "& .delete-que": {
            fontSize: 22,
            fontWeight: 600,
            lineHeight: "normal",
            marginTop: 60,
            marginBottom: 30,
            "@media (max-width: 767px)": {
                fontSize: 18,
                margin: "25px 0",
            },
            "& em": {
                fontWeight: 700,
                fontStyle: "normal",
            }
        },
        "& .delete-warning": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            padding: "15px 10px",
            boxSizing: "border-box",
            fontSize: 18,
            borderRadius: 3,
            lineHeight: "normal",
            color: "#212121",
            background: "rgba(234, 82, 79, 0.10)",
            "@media (max-width: 767px)": {
                fontSize: 16,
            },
            "& .title": {
                display: "inline-flex",
                alignItems: "center",
                fontSize: 30,
                fontWeight: 600,
                lineHeight: "normal",
                color: "#EA524F",
                gap: 10,
                "@media (max-width: 767px)": {
                    fontSize: 22,
                },
                "& svg": {
                    height: 25,
                    width: 25,
                }
            }
        },
        "& .dialog-actions": {
            display: "flex",
            flexWrap: "wrap",
            gap: 15,
            marginTop: 35,
            justifyContent: "flex-end",
            "@media (max-width: 767px)": {
                marginTop: 30,
            },
            "& button": {
                fontSize: 16,
                lineHeight: "normal",
                color: "#FFFFFF",
                background: "#503A65",
                fontWeight: 500,
                borderRadius: 8,
                padding: "10px 14px",
                textTransform: "capitalize",
                "@media (max-width: 767px)": {
                    fontSize: 16,
                },
                "&.outlined": {
                    border: "1px solid #503A65",
                    color: "#503A65",
                    background: "transparent",
                }
            }
        }
    },
});

export default withStyles(webStyles)(withRouter(MerchantFinancials));
// Customizable Area End
