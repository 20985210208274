// Customizable Area Start
import React from "react";
import {
    Select,
    InputAdornment,
    OutlinedInput,
    MenuItem,
    Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ReviewInfoController, { Props } from "./ReviewInfoController.web";
import {
    EditIcon,
    IconStepper,
    IconSubmitStepper
} from '../../../../components/src/IconAssets';
import '../../assets/css/DataEntryScreens.web.css';
import { iconArrowDown } from "../assets";
import Sidebar from "../../../../components/src/Sidebar.web";
import Header from "../../../../components/src/Header.web";
// Customizable Area End


export class ReviewInfo extends ReviewInfoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { businessInfo, personalInfo } = this.state;

        return (
            // Customizable Area Start
            <>
                <Header/>
                <Sidebar/>
                <div className="dataEntry-page">
                    <div className="form-stepper">
                        <div className="step">
                            <span className="step-icon"><IconStepper /></span>
                            <span className="step-name">Business Info</span>
                        </div>
                        <div className="step">
                            <span className="step-icon"><IconStepper /></span>
                            <span className="step-name">Personal Info</span>
                        </div>
                        <div className="step active">
                            <span className="step-icon"><IconSubmitStepper /></span>
                            <span className="step-name">Submit</span>
                        </div>
                    </div>
                    <div className="ReviewInfo-container" data-test-id="Main">
                        <div className="form-box">
                            <div className="box-head">
                                <h6>Main Info</h6>
                            </div>
                            <div className="box-body">
                                <div className="form-field">
                                    <label htmlFor="folder">Folder</label>
                                    <OutlinedInput
                                        type="text"
                                        id="folder"
                                        disabled
                                        value={this.state.businessInfo.folder}
                                    />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="iso">ISO</label>
                                    <Select
                                        id="iso"
                                        className="select-box"
                                        variant="outlined"
                                        disabled
                                        value={this.state.businessInfo.iso || ""}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                            </InputAdornment>
                                        }
                                    >
                                        <MenuItem className="select-option" value="iso1">ISO 1</MenuItem>
                                        <MenuItem className="select-option" value="iso2">ISO 2</MenuItem>
                                        <MenuItem className="select-option" value="iso3">ISO 3</MenuItem>
                                    </Select>
                                </div>
                                <div className="form-field">
                                    <label htmlFor="notes">Notes</label>
                                    <OutlinedInput
                                        type="text"
                                        id="notes"
                                        disabled
                                        value={this.state.businessInfo.notes}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="info-box">
                            <div className="box-head">
                                <h6>Business Info</h6>
                                <Button onClick={() => this.handleEditButtonClick('/dataentry/businessinfo')} data-test-id="BusinessEditBtn">
                                    <EditIcon />
                                </Button>
                            </div>
                            <div className="box-body">
                                <ul className="data-list">
                                    <li>
                                        <label>Legal name</label>
                                        <span>{this.handleEmptyData(businessInfo?.legal_name)}</span>
                                    </li>
                                    <li>
                                        <label>Type On Entity</label>
                                        <span>{this.handleEmptyData(businessInfo?.type_on_entity)}</span>
                                    </li>
                                    <li>
                                        <label>Merchant (DBA)</label>
                                        <span>{this.handleEmptyData(businessInfo?.merchant)}</span>
                                    </li>
                                    <li>
                                        <label>Address</label>
                                        <span>{this.handleEmptyData(businessInfo?.address)}</span>
                                    </li>
                                    <li>
                                        <label>FEIN</label>
                                        <span>{this.handleEmptyData(businessInfo?.fein)}</span>
                                    </li>
                                    <li>
                                        <label>City</label>
                                        <span>{this.handleEmptyData(businessInfo?.city)}</span>
                                    </li>
                                    <li>
                                        <label>ST</label>
                                        <span>{this.handleEmptyData(businessInfo?.st)}</span>
                                    </li>
                                    <li>
                                        <label>ZIP</label>
                                        <span>{this.handleEmptyData(businessInfo?.zip)}</span>
                                    </li>
                                    <li>
                                        <label>Country</label>
                                        <span>{this.handleEmptyData(businessInfo?.country)}</span>
                                    </li>
                                    <li>
                                        <label>Phone</label>
                                        <span>{this.handleEmptyData(businessInfo?.phone)}</span>
                                    </li>
                                    <li>
                                        <label>Email</label>
                                        <span>{this.handleEmptyData(businessInfo?.email)}</span>
                                    </li>
                                    <li>
                                        <label>Start Date</label>
                                        <span>{this.handleEmptyData(businessInfo?.start_date)}</span>
                                    </li>
                                    <li>
                                        <label>Type</label>
                                        <span>{this.handleEmptyData(businessInfo?.type)}</span>
                                    </li>
                                    <li>
                                        <label>Website</label>
                                        <span>{this.handleEmptyData(businessInfo?.website)}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {personalInfo?.length > 0 && personalInfo?.map((item: any, index: number) => (
                            <div className="info-box" key={item}>
                                <div className="box-head">
                                    <h6>Personal Info {index + 1}</h6>
                                    <Button onClick={() => this.handleEditButtonClick('/dataentry/personalinfo')} data-test-id="PersonalEditBtn">
                                        <EditIcon />
                                    </Button>
                                </div>
                                <div className="box-body">
                                    <ul className="data-list">
                                        <li>
                                            <label>First Name</label>
                                            <span>{this.handleEmptyData(item?.first_name)}</span>
                                        </li>
                                        <li>
                                            <label>Middle Initial</label>
                                            <span>{this.handleEmptyData(item?.middle_initial)}</span>
                                        </li>
                                        <li>
                                            <label>Last Name</label>
                                            <span>{this.handleEmptyData(item?.last_name)}</span>
                                        </li>
                                        <li>
                                            <label>Suffix</label>
                                            <span>{this.handleEmptyData(item?.Sufix)}</span>
                                        </li>
                                        <li>
                                            <label>DOB</label>
                                            <span>{this.handleEmptyData(item?.dob)}</span>
                                        </li>
                                        <li>
                                            <label>Principal Gender</label>
                                            <span>{this.handleEmptyData(item?.principal_gender)}</span>
                                        </li>
                                        <li>
                                            <label>SSN</label>
                                            <span>{this.handleEmptyData(item?.ssn)}</span>
                                        </li>
                                        <li>
                                            <label>% Owenship</label>
                                            <span>{this.handleEmptyData(item?.ownership)}</span>
                                        </li>
                                        <li>
                                            <label>Address</label>
                                            <span>{this.handleEmptyData(item?.address)}</span>
                                        </li>
                                        <li>
                                            <label>City</label>
                                            <span>{this.handleEmptyData(item?.city)}</span>
                                        </li>
                                        <li>
                                            <label>State</label>
                                            <span>{this.handleEmptyData(item?.state)}</span>
                                        </li>
                                        <li>
                                            <label>Principal Zip</label>
                                            <span>{this.handleEmptyData(item?.principal_zip)}</span>
                                        </li>
                                        <li>
                                            <label>Email</label>
                                            <span>{this.handleEmptyData(item?.email)}</span>
                                        </li>
                                        <li>
                                            <label>Home Phone</label>
                                            <span>{this.handleEmptyData(item?.home_phone)}</span>
                                        </li>
                                        <li>
                                            <label>Work Phone</label>
                                            <span>{this.handleEmptyData(item?.cell_phone)}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ))}

                        <div className="data-actions">
                            <Button
                                className="back-btn"
                                data-test-id="backBtn"
                                onClick={() => this.props.history.push('/dataentry/personalinfo')}
                            >
                                Back
                            </Button>
                            <Button
                                className="submit-btn"
                                data-test-id="submitBtn"
                                onClick={() => this.createSubmissionID()}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withRouter(ReviewInfo as any);
// Customizable Area End
