// Customizable Area Start
import React from "react";
import ResetPasswordController, {
    Props
} from "../src/ResetPasswordController.web";
import {
    Grid,
    InputAdornment,
    IconButton,
    Typography,
    TextField,
    Button
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
    ValidationRightGreen,
    ValidationWrongRed
} from "../../../components/src/IconAssets";
import { AppConstant } from "../../../components/src/Constant";

const images = require("./assets")
const configJSON = require("./config")

const webStyle: any = {
    mainResetPassword: {
        borderRadius: "16px",
        width: "25%",
        margin: "auto",
        padding: "50px 50px",
        height: "auto",
        // overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 8px 4px rgba(225, 216, 229, 0.30)",
        fontFamily: "Fira Sans, sans-serif",
    },
    imageAndText: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    lockImage: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "42px"
    },
    resetPasswordtext: {
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 500,
        fontSize: "28px",
        color: "black",
        marginBottom: "22px"
    },
    mainPasswordFields: {
        width: "100%",
        // marginBottom: "97px"
    },
    label: {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Fira Sans, sans-serif",
        marginBottom: "10px"
    },
    textFieldNewPassword: {
        border: "1px solid #E1D8E5",
        width: "100%",
        padding: "10px 16px",
        borderRadius: "0.5rem",
        boxSizing: "border-box",
        marginBottom: "22px"
    },
    textFieldConfirmPassword: {
        border: "1px solid #E1D8E5",
        width: "100%",
        padding: "10px 16px",
        borderRadius: "0.5rem",
        boxSizing: "border-box",
        marginBottom: "16px"
    },
    validationMessages: {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Fira Sans, sans-serif",
        marginBottom: "8px",
        display: "flex",
        gap: "10px"
    },
    saveNewPasswordButton: {
        alignItems: "center",
        justifyContent: "center",
        display: "block",
        flexDirection: "column",
        // backgroundColor: "#503A65",
        borderRadius: "8px",
        color: "white",
        textTransform: "capitalize",
        width: "100%",
        fontSize: "18px",
        fontWeight: 700,
        padding: "16px",
        marginBottom: "20px",
        fontFamily: "Fira Sans, sans-serif",
        cursor: "pointer"
    },
    backToLogin: {
        alignItems: "center",
        justifyContent: "center",
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Fira Sans, sans-serif",
        color: "#503A65",
        textDecorationLine: "underline",
        cursor: "pointer",
        textAlign: "center"
    },
    mainButtonsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    errorMessage: {
        color: "red",
        fontSize: '16px',
        // paddingTop: '5px',
        fontWeight: 400,
        fontFamily: "Fira Sans, sans-serif",
    },
    textFieldConfirmPasswordError: {
        border: "1px solid red",
        width: "100%",
        padding: "10px 16px",
        borderRadius: "0.5rem",
        boxSizing: "border-box",
        marginBottom: "16px"
    },
    textFieldNewPasswordError: {
        border: "1px solid red",
        width: "100%",
        padding: "10px 16px",
        borderRadius: "0.5rem",
        boxSizing: "border-box",
        marginBottom: "22px"
    }
}
// Customizable Area End

export default class ResetPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props)
    }
    // Customizable Area Start
    validations = () => {
        return (
            <ul style={{ listStyleType: 'none', padding: 0, marginBottom: "60px" }}>
                <li style={{ color: this.state.validations[0] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validations[0] ? <ValidationRightGreen /> : <ValidationWrongRed />} {AppConstant.passwordLengthValidation}
                </li>
                <li style={{ color: this.state.validations[1] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validations[1] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.uppercaseLetter}
                </li>
                <li style={{ color: this.state.validations[2] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validations[2] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.lowercaseletter}
                </li>
                <li style={{ color: this.state.validations[3] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validations[3] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.number}
                </li>
                <li style={{ color: this.state.validations[4] ? "#909090" : "red", ...webStyle.validationMessages }}>
                    {this.state.validations[4] ? <ValidationRightGreen /> : <ValidationWrongRed />} {configJSON.specialCharacter}
                </li>
            </ul>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div style={webStyle.mainResetPassword}>

                {/* Image and Text container */}
                <Grid container style={webStyle.imageAndText}>
                    <Grid item>
                        <img src={images.lockIcon} style={webStyle.lockImage} />
                    </Grid>
                </Grid>
                <Grid item style={webStyle.resetPasswordtext}>
                    {configJSON.resetPasswordText}
                </Grid>

                {/* Fields */}
                <Grid item style={webStyle.mainPasswordFields}>
                    <Typography className="label" style={webStyle.label}>{configJSON.newPassword}</Typography>
                    <TextField
                        style={
                            this.state.passwordNotMatchError
                                ? webStyle.textFieldNewPasswordError
                                : webStyle.textFieldNewPassword
                        }
                        type={this.state.showNewPassword ? "text" : "password"}
                        data-test-id="newPassword"
                        name="newPassword"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <img src={images.passwordFieldIcon} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handlePasswordVisdibilityNewPassword}
                                        data-test-id="password-visibility"
                                        edge="end"
                                    >
                                        {this.state.showNewPassword ? (
                                            <Visibility style={{ color: "#b493b7" }} />
                                        ) : (
                                            <VisibilityOff style={{ color: "#b493b7" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Typography className="label" style={webStyle.label}>{configJSON.confirmNewPassword}</Typography>
                    <TextField
                        style={
                            this.state.passwordNotMatchError
                                ? webStyle.textFieldConfirmPasswordError
                                : webStyle.textFieldConfirmPassword
                        }
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        data-test-id="confirmNewpassword"
                        name="confirmNewpassword"
                        value={this.state.reEnterPassword}
                        onChange={this.handleConfirmPasswordChange}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <img src={images.passwordFieldIcon} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handlePasswordVisdibilityConfirmNewPassword}
                                        data-test-id="password-visibility"
                                        edge="end"
                                    >
                                        {this.state.showConfirmPassword ? (
                                            <Visibility style={{ color: "#b493b7" }} />
                                        ) : (
                                            <VisibilityOff style={{ color: "#b493b7" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Grid item style={webStyle.errorMessage}>
                        {this.state.passwordNotMatchError && this.state.passwordNotMatchErrorMsg}
                    </Grid>
                </Grid>

                {/* Validations */}
                <Grid>
                    {this.validations()}
                </Grid>

                {/* Buttons */}
                <Grid container style={webStyle.mainButtonsContainer}>
                    <Grid item style={webStyle.mainButton}>
                        <Button
                            style={{ backgroundColor: this.state.buttonDisabled ? '#A79CB2' : '#503A65', ...webStyle.saveNewPasswordButton }}
                            data-test-id="saveNewPassword"
                            disabled={this.state.buttonDisabled}
                            onClick={this.handleSaveNewPassword}
                        >
                            {configJSON.saveNewPasswordButton}
                        </Button>
                    </Grid>
                    <Grid item style={webStyle.backToLogin} onClick={this.handleBackToLogin} data-test-id="backToLogin">
                        {configJSON.backToLoginButtonText}
                    </Grid>
                </Grid>
            </div>
            // Customizable Area End
        )
    }
}
