import React from "react";
import CreatePasswordController, {
    Props
} from "../../../blocks/forgot-password/src/CreatePasswordController.web";

const configJSON = require("./config");
const images = require("./assets");

const webStyle: any = {
    mainPage: 
    {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "64px 100px",
        height: "auto",
        overflow: "hidden",
        flexDirection: "column",
        borderRadius: "16px",
        boxShadow: "0px 0px 8px 4px rgba(225, 216, 229, 0.30)",
        fontFamily: "Fira Sans, sans-serif",
        margin: "auto",
    },
    backToLogin: 
    {
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 700,
        color: "#503A65",
        textDecorationLine: "underline",
        cursor: "pointer",
        fontSize: "18px",
    },
    successMessage: 
    {
        marginBottom: "61px",
        fontWeight: 500,
        fontFamily: "Fira Sans, sans-serif",
        color: "#059669",
        fontSize: "28px",
    },
    successImage: 
    {
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "61px"
    }
}

export default class CreatePasswordSuccessfull extends CreatePasswordController{
    constructor(props: Props){
        super(props)
    }

    render() {
        return(
            <div style={webStyle.mainPage}>
                    <div style={webStyle.successImage}>
                        <img src={images.successGreen} />
                    </div>
                    <div style={webStyle.successMessage}>
                        {configJSON.successMessageCreate}
                    </div>
                    <div data-test-id="backToLogin" style={webStyle.backToLogin} onClick={this.handleBackToLogin}>
                        {configJSON.backToLoginButtonText}
                    </div>
            </div>
        )
    }
}