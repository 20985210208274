// Customizable Area Start
import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import CustomPagination from "./CustomPagination.web";


interface Props {
    columns: ColumnsProps[];
    rows: RowsProps[];
    rowsPerPage: number;
    loading: boolean;
    rowOptions?: {
        isRowClickAble: boolean;
        onRowClick: (row: any) => void;
    };
}

interface S {
    page: number;
}

interface SS { }

export interface ColumnsProps {
    key: string;
    label: string;
    cellRender?: (row: RowsProps) => React.ReactNode;
}

export interface RowsProps {
    [key: string]: any;
}
// Customizable Area End

export class CustomDataGrid extends React.Component<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            page: 1,
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleChangePage = (newPage: number) => {
        this.setState({ page: newPage });
    };

    renderCellData = (column: any, row: any) => {
        if (column.key === "action" || column.key === "") {
            return (
                column.cellRender ? column.cellRender(row) : ""
            );
        } else {
            return (
                row[column.key] || '-'
            );
        }
    }

    onTableRowClick = (row: any) => {
        if (this.props.rowOptions) {
            const { isRowClickAble, onRowClick } = this.props.rowOptions;
            if (isRowClickAble) {
                return onRowClick(row);
            }
        }
    }

    tableRowCursor = (): string => {
        return this.props.rowOptions?.isRowClickAble ? "pointer" : "default";
    }

    renderTblData = (rowsToDisplay: any) => {
        const { columns } = this.props;
        return (
            <TableBody>
                {rowsToDisplay.length > 0 ?
                    (<>
                        {rowsToDisplay.map((row: any, index: any) => (
                            <TableRow
                                key={JSON.stringify(index)}
                                onClick={() => this.onTableRowClick(row)}
                                style={{ cursor: this.tableRowCursor() }}
                            >
                                {columns.map((column: any) => (
                                    <TableCell key={column.key}>
                                        {this.renderCellData(column, row)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </>) :
                    (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="empty-msg" style={styles.emptyMsg}>No Data Found!</TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        );
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { columns, rows, rowsPerPage } = this.props;
        const { page } = this.state;
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const pageCount = Math.ceil(rows.length / rowsPerPage);
        const rowsToDisplay = rows.slice(startIndex, endIndex);
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <TableContainer className="custom-datagrid" style={styles.tblContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.length > 0 && columns.map((column) => (
                                    <TableCell key={column.key}>{column.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {this.props.loading ?
                            (
                                <div style={styles.loader}>
                                    <div className="custom-loader"></div>
                                </div>
                            ) :
                            (
                                this.renderTblData(rowsToDisplay)
                            )}
                    </Table>
                </TableContainer>
                <div className="pagination-wrapper">
                    <CustomPagination
                        count={pageCount}
                        page={page}
                        onChange={this.handleChangePage}
                    />
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles: any = {
    tblContainer: {
        position: "relative",
    },
    loader: {
        position: "absolute",
        inset: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    emptyMsg: {
        position: "absolute",
        inset: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}
// Customizable Area End

export default CustomDataGrid as any;
