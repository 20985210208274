// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { RowsProps } from "../../../components/src/CustomDataGrid.web";

export const configJSON = require('./config')
// Customizable Area End

export interface Props {
    // Customizable Area Start
    id: any,
    navigation: any,
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    value: number,
    allData: [],
    openCreateAccount: boolean,
    openEditAccount: boolean,
    getRolesApiResponse: any,
    firstName: string,
    lastName: string,
    email: string,
    slackHandle: string,
    roleSelect: string,
    activated: boolean,
    accountCreationResponse: string,
    allAccountData: any,
    editId: string,
    roleId: any,
    accountEmailError: any,
    accountEmailErrorMsg: string,
    accountRowData: any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AccountCreationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiCallIdAccountCreationForm: any;
    apiCallIdRoleIndex: any;
    apiCallIdAllAccount: any;
    apiCallIdEditAccount: any;
    newRowData: RowsProps[] = [];
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            value: 0,
            allData: [],
            openCreateAccount: false,
            openEditAccount: false,
            firstName: "",
            lastName: "",
            email: "",
            slackHandle: "",
            roleSelect: "",
            getRolesApiResponse: [],
            activated: false,
            accountCreationResponse: "",
            allAccountData: {},
            editId: "",
            roleId: 0,
            accountEmailError: false,
            accountEmailErrorMsg: "",
            accountRowData: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.apiCallFnAllRoleIndex()
        this.apiCallFnAllAccount()
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId != null) {
                switch (apiRequestCallId) {
                    case this.apiCallIdRoleIndex:
                        this.setState({
                            getRolesApiResponse: responseJson.data
                        });
                        break;

                    case this.apiCallIdAccountCreationForm:
                        if(!responseJson.errors) {
                            this.setState({
                                accountCreationResponse: responseJson.data,
                                openCreateAccount: false
                            });
                            this.apiCallFnAllAccount()
                        }
                        else if(responseJson.errors[0].account) {
                            this.setState({
                                openCreateAccount: true,
                                accountEmailError: true,
                                accountEmailErrorMsg: responseJson.errors[0].account
                            })
                        }
                        break;

                    case this.apiCallIdAllAccount:
                        const accountData = responseJson.data;
                        this.newRowData = accountData.map((accData: any) => ({
                            name: [`${accData.attributes.first_name} `, `${accData.attributes.last_name}`],
                            email: accData.attributes.email,
                            role: accData.attributes.role,
                            status: accData.attributes.activated,
                            editId: accData.id,
                        }));
                        this.setState({
                            accountRowData: this.newRowData
                        })

                        break;

                    case this.apiCallIdEditAccount:
                        this.apiCallFnAllAccount()
                        break;

                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    apiCallFnAccountCreationForm = async () => {
        const loginToken = await getStorageData("userToken")

        const header = {
            "Content-Type": "application/json",
            token: loginToken,
        };
        const httpBody = {
            "data": {
                "type": "email_account",
                "attributes": {
                    "email": `${this.state.email}`,
                    "first_name": `${this.state.firstName}`,
                    "last_name": `${this.state.lastName}`,
                    "slack_handle": `${this.state.slackHandle}`,
                    "role_id": `${this.state.roleId}`,
                    "activated": `${this.state.activated}`
                }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdAccountCreationForm = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_block/accounts/'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.apiCallIdAccountCreationForm;
    };

    apiCallFnAllRoleIndex = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdRoleIndex = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiCallEndpointsRoleIndexGet
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.apiCallIdRoleIndex;
    };

    apiCallFnAllAccount = async () => {

        const loginToken = await getStorageData("userToken")

        const header = {
            "Content-Type": "application/json",
            token: loginToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdAllAccount = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiCallEndpointsAllAccount
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.apiCallIdAllAccount;
    };

    apiCallFnEditAccount = async () => {
        const loginToken = await getStorageData("userToken")

        const header = {
            "Content-Type": "application/json",
            token: loginToken,
        };

        const httpBody = {
            "data": {
                "type": "email_account",
                "attributes": {
                    "first_name": `${this.state.firstName}`,
                    "last_name": `${this.state.lastName}`,
                    "email": `${this.state.email}`,
                    "activated": true,
                    "slack_handle": `${this.state.slackHandle}`,
                    "role_id": this.state.roleId,
                }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdEditAccount = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/${this.state.editId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.apiCallIdEditAccount;
    };

    // Functonalities

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue })
    }

    handleAccountCreation = () => {
        if (this.state.roleSelect === "underwriter") {
            this.setState({
                roleId: 2
            })
        }
        else {
            this.setState({
                roleId: 3
            })
        }
        this.apiCallFnAccountCreationForm()
    }

    handleToggle = (key: keyof S, value: boolean, rowData: any) => {
        this.setState({
            ...this.state,
            [key]: value,
            // editId: 
        });
    }

    handleEditModal = (action: string, value: boolean, editId: any, slackHandle: any, roleSelect: any) => {
        this.setState({
            openEditAccount: true,
            editId: editId
        })
        if (action === 'openEditAccount' && value) {
            const selectedRow = this.newRowData.find(row => row.editId === editId);
            if (selectedRow) {
                this.setState({
                    openEditAccount: value,
                    firstName: selectedRow.name[0],
                    lastName: selectedRow.name[1],
                    email: selectedRow.email,
                    slackHandle: selectedRow.slackHandle,
                    roleSelect: selectedRow.role,
                });
                if (this.state.roleSelect === "underwriter") {
                    this.setState({
                        roleId: 2
                    })
                }
                else {
                    this.setState({
                        roleId: 3
                    })
                }
            }
        } else {
            this.setState({
                openEditAccount: value,
                firstName: "",
                lastName: "",
                email: "",
                slackHandle: "",
                roleSelect: "",
            });
        }
    }

    handleAddEmployee = () => {
        this.setState({
            openCreateAccount: true,
            firstName: "",
            lastName: "",
            email: "",
            slackHandle: "",
            roleSelect: ""
        })
    }

    handleEditEmployee = () => {
        this.setState({
            openEditAccount: false,
        })
        this.apiCallFnEditAccount()
    }

    handleInputChange = (e: any) => {
        const { name, value } = e.target
        this.setState({
            ...this.state,
            [name]: value,
        })
    }

    handleRoleSelect = (e: any) => {
        const { name, value } = e.target
        if (name === "selectRole") {
            this.setState({ roleSelect: value })
        }
    }

    // Customizable Area End
}