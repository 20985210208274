import React from "react";

// Customizable Area Start
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const configJSON = require("./config");
const images = require("./assets");
const webStyles: any = {
  mainLoginBlock: {
    borderRadius: "16px",
    /* border: 2px solid red; */
    width: "40%",
    margin: "auto",
    padding: "50px 50px",
    height: "auto",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 8px 4px rgba(225, 216, 229, 0.30)",
    fontFamily: "Fira Sans, sans-serif",
  },
  imageAndText: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  loginText: {
    marginBottom: "64px",
    fontSize: "32px",
  },
  passwordFieldError: {
    color: "red",
    fontSize: '15px',
    paddingTop: '5px',
  },
  emailFieldError: {
    color: "red",
    fontSize: '15px',
    paddingTop: '5px',
  },
  emailAndPassword: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "80%",
  },
  signInButton: {
    alignItems: "center",
    justifyContent: "center",
    display: "block",
    flexDirection: "column",
    backgroundColor: "#503A65",
    borderRadius: "8px",
    color: "white",
    textTransform: "capitalize",
    width: "100%",
    fontSize: "18px",
    padding: "16px",
  },
  mainEmailPassword: {
    width: "100%",
  },
  textField: {
    border: "1px solid #E1D8E5",
    width: "100%",
    padding: "10px 16px",
    borderRadius: "0.5rem",
    boxSizing: "border-box",
  },
  errorTextField: {
    border: "1px solid red",
    width: "100%",
    padding: "10px 16px",
    borderRadius: "0.5rem",
    boxSizing: "border-box",
  },
  forgotPassword: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Fira Sans, sans-serif",
    color: "#503A65",
    cursor: "pointer",
    textAlign: "right",
    marginBottom: "90px"
  },
}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={webStyles.mainLoginBlock}>
          {/* Image and text container */}
          <Grid container style={webStyles.imageAndText}>
            <Grid item>
              <img src={images.companyLogo} />
            </Grid>
            <Grid item>
              <Typography style={webStyles.loginText}>
                {configJSON.loginTextMain}
              </Typography>
            </Grid>
          </Grid>

          {/* Email and password container */}
          <Grid container style={webStyles.emailAndPassword}>
            <Grid item style={webStyles.mainEmailPassword}>
              <Typography className="label">Your Email</Typography>
              <TextField
                className="email-field"
                style={
                  this.state.errorEmail
                    ? webStyles.errorTextField
                    : webStyles.textField
                }
                type="text"
                data-test-id="email-input-field"
                name="email"
                value={this.state.loginEmail}
                onChange={this.handleTextChange}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <img src={images.emailField} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid item style={webStyles.emailFieldError}>
                {this.state.errorEmail && this.state.errorEmailString}
              </Grid>
            </Grid>
            <Grid item style={webStyles.mainEmailPassword}>
              <Typography className="label">Password</Typography>
              <TextField
                className="password-field"
                style={
                  this.state.errorPassword
                    ? webStyles.errorTextField
                    : webStyles.textField
                }
                type={this.state.showPassword ? "text" : "password"}
                name="password"
                data-test-id="password-input-field"
                value={this.state.loginPassword}
                onChange={this.handleTextChange}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handlePasswordVisdibility}
                        data-test-id="password-visibility"
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <Visibility style={{ color: "#b493b7" }} />
                        ) : (
                          <VisibilityOff style={{ color: "#b493b7" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <img src={images.passwordField} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid item style={webStyles.passwordFieldError}>
                {this.state.errorPassword && this.state.errorPasswordString}
              </Grid>
            </Grid>
            <Grid style={{width: "100%"}}>
            <Grid item data-test-id="forgotPassword" style={webStyles.forgotPassword} onClick={this.handleForgotPassword}>
              Forgot Password
            </Grid>
            </Grid>
            <Grid item style={webStyles.mainEmailPassword}>
              <Button
                style={webStyles.signInButton}
                onClick={this.handleFieldErrorOnButtonClick}
                data-test-id="sign-in-button"
              >
                {configJSON.signInButtonText}
              </Button>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
