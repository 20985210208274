import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import {
  getStorageData,
  setStorageData
} from "../../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  businessInfo: any;
  personalInfo: any[];
  is_edit: boolean;
  personalInfoErrors: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PersonalInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      businessInfo: {},
      personalInfo: [{
        first_name: "",
        middle_initial: "",
        last_name: "",
        sufix: "",
        dob: "",
        principal_gender: "",
        ssn: "",
        ownership: "",
        address: "",
        city: "",
        state: "",
        principal_zip: "",
        email: "",
        home_phone: "",
        cell_phone: ""
      }],
      is_edit: false,
      personalInfoErrors: [{
        first_name: "",
        last_name: "",
        dob: "",
        ssn: "",
        address: "",
        city: "",
        state: "",
        principal_zip: ""
      }]
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleAddMorePersonalInfo();
    this.handleStorageData();
  }
  // Customizable Area End

  // Customizable Area Start
  handleStorageData = async () => {
    const storedData: any = JSON.parse(await getStorageData("personalInfo"));

    if (storedData?.length > 0) {
      this.setState({ personalInfo: [] }, () => {
        this.setState({ personalInfo: storedData });
      });
    }
  }

  handleAddMorePersonalInfo = () => {
    const personalInfo2 = { first_name: "", middle_initial: "", last_name: "", sufix: "", dob: "", principal_gender: "", ssn: "", ownership: "", address: "", city: "", state: "", principal_zip: "", email: "", home_phone: "", cell_phone: "" };

    this.setState({
      personalInfo: [...this.state.personalInfo, personalInfo2],
    });
  }

  handlePushValidationForm = () => {
    const personalInfoErrors2: any = { first_name: "", last_name: "", dob: "", ssn: "", address: "", city: "", state: "", principal_zip: "" };
    this.setState({ personalInfoErrors: [...this.state.personalInfoErrors, personalInfoErrors2] });
  }

  handlePersonalInfoChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const updatedPersonalInfo = [...this.state.personalInfo];
    const updatedErrors = [...this.state.personalInfoErrors];
    updatedPersonalInfo[index][name] = value;
    if (updatedErrors[index]?.hasOwnProperty(name)) {
      updatedErrors[index][name] = "";
    }
    this.setState({ personalInfo: updatedPersonalInfo, personalInfoErrors: updatedErrors });
  }

  handlePersonalInfoDobChange = (selectedDate: any, index: number) => {
    const updatedPersonalInfo = [...this.state.personalInfo];
    const updatedDateError = [...this.state.personalInfoErrors];

    updatedPersonalInfo[index]['dob'] = moment(selectedDate).format("MM/DD/YYYY");
    updatedDateError[index]['dob'] = "";
    this.setState({ personalInfo: updatedPersonalInfo, personalInfoErrors: updatedDateError });
  }

  handleSubmitPersonalInfo = async (e: any) => {
    e.preventDefault();

    const { personalInfo } = this.state;
    const updatedErrors = [...this.state.personalInfoErrors];

    personalInfo.forEach((obj, index) => {
      for (let key in obj) {
        if (updatedErrors[index]?.hasOwnProperty(key)) {
          if (obj[key] === "") {
            updatedErrors[index][key] = `Please ${key === "state" ? "select" : "enter"} ${key.replace(/[_/\\]/g, ' ')}`;
          } else {
            updatedErrors[index][key] = "";
          }
        }
      }
    });
    this.setState({ personalInfoErrors: updatedErrors });

    setTimeout(() => {
      const isEmpty = this.state.personalInfoErrors.every(obj => {
        return Object.values(obj).every(value => value === "");
      });
      if (isEmpty) {
        this.storePersonalInfoData();
      }
    }, 500);

  }

  storePersonalInfoData = () => {
    if (this.state.is_edit) {
      setStorageData('is_edit', false);
      this.setState({ is_edit: false });
    }
    const personalInfoState = this.state.personalInfo || [];
    const filteredPersonalInfo = personalInfoState.filter(obj => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== '') {
          return true;
        }
      }
      return false;
    });
    setStorageData("personalInfo", JSON.stringify(filteredPersonalInfo));
    this.props.history.push("/dataentry/reviewinfo");
  }
  // Customizable Area End
}
