// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import '../../assets/css/DataEntryScreens.web.css';
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import {
    Button,
    Select,
    Typography,
    Dialog,
    MenuItem,
} from "@material-ui/core";
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import UnderwriterScreenStepperWeb from "./UnderwriterScreenStepper.web";
import MerchantPaymentsController, { Props } from "./MerchantPaymentsController.web";
import CustomDataGrid, { ColumnsProps } from "../../../../components/src/CustomDataGrid.web";
import { Chart } from 'react-google-charts';
import { CalendarIcon, CrossIcon, DownloadIcon } from "../../../../components/src/IconAssets";
import DatePicker from "react-date-picker";

const documentColumns: ColumnsProps[] = [
    { key: 'type', label: 'Transaction Type' },
    { key: 'dateSettled', label: 'Date Settled' },
    { key: 'amount', label: 'Amount' },
    { key: 'balance', label: 'Balance' },
];

// Customizable Area End

export class MerchantPayments extends MerchantPaymentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes }: any = this.props;
        const { documents } = this.state;
        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <main className={`${classes.mainWrapper} page-main`} data-test-id="Main">
                    <div className={classes.stepHeader}>
                        <Typography variant="h3">Payments</Typography>
                        <Button onClick={() => { this.setState({ isOpenAddPaymentDialog: true }) }}>
                            Add Payment
                        </Button>
                    </div>
                    <UnderwriterScreenStepperWeb />
                    <div className={classes.pageFormSection}>
                        <div className={classes.yearFilterWrapper}>
                            <Typography className="title">Amount</Typography>
                            <div className="space" />
                            <DownloadIcon />
                            <Button className="download-button">Download</Button>
                            <Select
                                id="year"
                                name="year"
                                className="yearSelectBox"
                                variant="outlined"
                            >
                                <MenuItem className="select-option" value="username1">2024</MenuItem>
                                <MenuItem className="select-option" value="username2">2023</MenuItem>
                                <MenuItem className="select-option" value="username3">2022</MenuItem>
                            </Select>
                        </div>
                        {/* @ts-ignore */}
                        <Chart
                            width={'100%'}
                            height={'400px'}
                            // @ts-ignore
                            chartType={'Bar'}
                            loader={<div>Loading Chart</div>}
                            data={[
                                ['Category', 'Amount'],
                                ['Jan', 160000],
                                ['Feb', 149000],
                                ['Mar', 90000],
                                ['Apr', 50000],
                                ['May', 70000],
                                ['Jun', 90000],
                                ['Jul', 120000],
                                ['Aug', 1000],
                                ['Sep', 4000],
                                ['Oct', 150000],
                                ['Nov', 60000],
                                ['Dec', 40000],
                            ]}
                            options={{
                                hAxis: {
                                    useHTML: true,
                                    format: '$#,###.00',
                                },
                                vAxis: {
                                    title: '',
                                },
                                isStacked: true,
                                legend: { position: 'none' },
                                series: {
                                    0: { color: '#3F8DFD' },
                                },
                            }}
                        />
                        <Typography variant="h3" className={classes.pageTitle}>Payment History</Typography>
                        <CustomDataGrid columns={documentColumns} rows={documents} rowsPerPage={documents.length} loading={false} />
                    </div>
                    <Dialog
                        open={this.state.isOpenAddPaymentDialog}
                        className={classes.addPaymentDialog}
                        onClose={this.handleCloseAddPaymentDialog}
                    >
                        <div className="dialogHeader">
                            <Typography variant="h4">Add Payment</Typography>
                            <Button data-test-id="dialogCloseBtn" onClick={this.handleCloseAddPaymentDialog}>
                                <CrossIcon />
                            </Button>
                        </div>
                        <form data-test-id="dialogForm" onSubmit={(e) => e.preventDefault()}>
                            <div className="fieldRow">
                                <div className="field">
                                    <label htmlFor="member">Transaction Type</label>
                                    <div className="fieldInput">
                                        <Select
                                            id="username"
                                            name="username"
                                            className={classes.selectBox}
                                            variant="outlined"
                                        >
                                            <MenuItem className="select-option" value="username1">Username 1</MenuItem>
                                            <MenuItem className="select-option" value="username2">Username 2</MenuItem>
                                            <MenuItem className="select-option" value="username3">Username 3</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="member">Date Settled</label>
                                    <div className="fieldInput">
                                        {/* <input
                                            className="custom-datepicker-input"
                                            type="date"
                                        /> */}
                                        <DatePicker
                                            id="date"
                                            name="date"
                                            className="date_picker"
                                            data-test-id="dateInput"
                                            format="MM/dd/yyyy"
                                            monthPlaceholder="MM"
                                            yearPlaceholder="YYYY"
                                            dayPlaceholder="DD"
                                            // value={this.state.selectedDate}
                                            // onChange={(newDate: any) => this.setState({ selectedDate: newDate })}
                                            calendarIcon={<CalendarIcon color={'#000000'} />}
                                            clearIcon={null}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="fieldRow">
                                <div className="field">
                                    <label htmlFor="member">Amount</label>
                                    <div className="fieldInput"></div>
                                </div>
                                <div className="field">
                                    <label htmlFor="member">Balance</label>
                                    <div className="fieldInput"></div>
                                </div>
                            </div>
                            <div className="actions">
                                <Button
                                    type="reset"
                                    data-test-id="dialogCancleBtn"
                                    className="outlined"
                                    onClick={this.handleCloseAddPaymentDialog}
                                >
                                    cancel
                                </Button>
                                <Button type="submit">add payment</Button>
                            </div>
                        </form>
                    </Dialog>
                </main>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const webStyles = (_theme: Theme) => createStyles({
    pageTitle: {
        width: "100%",
        display: "inline-block",
        fontSize: 24,
        lineHeight: "normal",
        fontWeight: 500,
        "@media (max-width: 767px)": {
            fontSize: 22,
        },
    },
    mainWrapper: {
        "& .custom-datagrid": {
            borderColor: "transparent",
            minHeight: "auto",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
            "& table .empty-msg": {
                position: "relative !important",
                display: "table-cell !important",
                textAlign: "center",
            },
            "& .MuiTableRow-root td": {
                borderColor: "transparent",
            },
            "& .MuiTableRow-root td, .MuiTableRow-root th": {
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        "& .pagination-wrapper": {
            display: "none",
        }
    },
    stepHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: "25px 0",
        "& h3": {
            fontSize: 24,
            lineHeight: 'normal',
            fontWeight: 500,
            color: '#000',
            "@media (max-width: 767px)": {
                fontSize: 20
            }
        },
        "& button, button:hover": {
            fontSize: 16,
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#fff',
            backgroundColor: "#503A65",
            background: 'transparent',
            padding: '10px 16px',
            borderRadius: 8,
            // border: "1px solid #503A65",
            "@media (max-width: 767px)": {
                fontSize: 14,
                padding: '8px 14px',
            }
        }
    },
    pageFormSection: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: 35,
        gap: 20,
    },
    formWrapper: {
        width: "calc(70% - 20px)",
        display: "flex",
        flexDirection: "column",
        gap: 30,
        "& form": {
            display: "flex",
            flexWrap: "wrap",
            gap: 20,
            "& h4": {
                display: "inline-block",
                width: "100%",
                fontSize: 18,
                lineHeight: "normal",
                fontWeight: 500,
            },
            '& .selectBox': {
                height: "100%",
                width: "100%",
                borderRadius: 8,
                color: '#000',
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                boxSizing: "border-box",
                "& .MuiSelect-root": {
                    height: '100%',
                    boxSizing: "border-box",
                },
                "& .MuiSelect-icon": {
                    display: 'none',
                },
                "& input": {
                    height: "100%",
                    boxSizing: "border-box",
                },
                "& fieldset": {
                    display: "none",
                },
                "@media (max-width: 767px)": {
                    height: 38
                }
            }
        }
    },
    generateDoc: {
    },
    addPaymentDialog: {
        "& .MuiDialog-paper": {
            padding: "16px 24px",
            paddingTop: 25,
            borderRadius: 8,
            background: "#FFFFFF",
            boxSizing: "border-box",
            maxWidth: "600px",
            width: "100%",
            "& *": {
                fontFamily: "Fira Sans, sans-serif",
            },
        },
        "& form": {
            display: "flex",
            flexWrap: "wrap",
            rowGap: 20,
            "& .fieldRow": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            },
            "& .field": {
                flex: 1,
                "& label": {
                    fontSize: 16,
                    fontWeight: 400
                },
                marginRight: 5,
            },
            "& .fieldInput": {
                border: "1px solid #E1D8E5",
                height: 45,
                borderRadius: 8,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            },
            "& .actions": {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                width: "100%",
                gap: 10,
                marginTop: 20,
                "& button": {
                    fontSize: 16,
                    lineHeight: "normal",
                    color: "#FFFFFF",
                    border: "1px solid #503A65",
                    borderRadius: 8,
                    padding: "10px 16px",
                    background: "#503A65",
                    textTransform: "capitalize",
                    "&.outlined": {
                        background: "transparent",
                        color: "#503A65",
                    }
                }
            },
            "& .custom-datepicker-input": {
                width: "100%",
                height: "100%",
                border: "none",
                outline: "none",
                appearance: "none",
                cursor: "pointer",
            },
            "& textarea": {
                padding: 12,
            },
        },
        "& .dialogHeader": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 12,
            gap: 8,
            "& h4": {
                fontSize: 20,
                fontWeight: 500,
                lineHeight: "normal",
            },
            "& button": {
                minWidth: "auto",
                padding: 0,
            },
        },
    },
    yearFilterWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        "& .space": {
            flex: 1
        },
        "& .yearSelectBox": {
            width: 75
        },
        "& .title": {
            fontSize: 16,
            fontWeight: 500,
        },
        "& .download-button": {
            fontSize: 14,
            fontWeight: 700,
            color: "#503A65"
        }
    },
    uploadDoc: {
    },
    chartWrapper: {
    },
});

export default withStyles(webStyles)(withRouter(MerchantPayments));
// Customizable Area End
