import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { webStyles } from "./MerchantDocuments.web";
import { WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// Customizable Area Start
const moment = require('moment');

export interface Document {
  stips: any;
  department: any;
  date_time: any;
  verified: any;
  document: any;
  notes: any;
}

// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps, WithStyles<typeof webStyles> {
  navigation?: any;
  history: any;
  location: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  documents: Document[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MerchantDocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      documents: [
        {
          stips: "Bank Statement - Dec",
          department: "Funding Coordinator",
          date_time: "10-07-2023 & 9:32 AM",
          verified: "Yes",
          document: "Yes",
          notes: "Yes",
        },
        {
          stips: "Bank Statement - Dec",
          department: "Funding Coordinator",
          date_time: "10-07-2023 & 9:32 AM",
          verified: "Yes",
          document: "Yes",
          notes: "Yes",
        },
        {
          stips: "Bank Statement - Dec",
          department: "Funding Coordinator",
          date_time: "10-07-2023 & 9:32 AM",
          verified: "Yes",
          document: "Yes",
          notes: "Yes",
        },
        {
          stips: "Bank Statement - Dec",
          department: "Funding Coordinator",
          date_time: "10-07-2023 & 9:32 AM",
          verified: "Yes",
          document: "Yes",
          notes: "Yes",
        },
      ],
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
