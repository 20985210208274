// @ts-nocheck
import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBtnIcon } from './IconAssets';

interface Props {
  count: number;
  page: number;
  onChange: (page: number) => void;
}

interface S { }

interface SS { }

class CustomPagination extends Component<Props, S, SS> {
  handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    this.props.onChange(value);
  };

  render() {
    const { count, page } = this.props;

    const maxPagesToShow = 5;
    const pages = Array.from({ length: count }, (_, i) => i + 1);

    const firstPage = 1;
    const lastPage = count;

    let displayedPages = [];

    if (count <= maxPagesToShow) {
      displayedPages = pages;
    } else {
      if (page === 1) {
        displayedPages = [1, 2, 3, 4, 5, null, lastPage];
      } else {
        if (page <= maxPagesToShow - 2) {
          displayedPages = [1, 2, 3, 4, 5, null, lastPage];
        } else if (page >= lastPage - (maxPagesToShow - 3)) {
          displayedPages = [1, null, lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
        } else {
          displayedPages = [1, null, page - 2, page - 1, page, page + 1, null, lastPage];
        }
      }
    }

    return (
      <div className="custom-pagination">
        <Button
          onClick={(e) => this.handlePageChange(e, Math.max(page - 1, firstPage))}
          disabled={page === firstPage}
          className='prev-button'
        >
          <ArrowBtnIcon />
        </Button>

        {displayedPages.map((pageNumber) => (
          <React.Fragment key={pageNumber?.toString()}>
            {pageNumber === null ? (
              <span>...</span>
            ) : (
              <Button
                onClick={(e) => this.handlePageChange(e, pageNumber)}
                className={pageNumber === page ? 'page-btn active' : 'page-btn'}
              >
                {pageNumber}
              </Button>
            )}
          </React.Fragment>
        ))}

        <Button
          onClick={(e) => this.handlePageChange(e, Math.min(page + 1, lastPage))}
          disabled={page === lastPage}
          className='next-button'
        >
          <ArrowBtnIcon />
        </Button>
      </div>
    );
  }
}

export default CustomPagination as any;
