import React from "react";

export const IconStepper = () => {
    return (
        <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Mask group">
                <mask id="mask0_11168_599" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="23">
                    <g id="Group">
                        <g id="Group_2">
                            <path id="Vector" d="M16.4102 1.90222H2.41016C2.14494 1.90222 1.89059 2.00758 1.70305 2.19511C1.51551 2.38265 1.41016 2.63701 1.41016 2.90222V20.9022C1.41016 21.1674 1.51551 21.4218 1.70305 21.6093C1.89059 21.7969 2.14494 21.9022 2.41016 21.9022H16.4102C16.6754 21.9022 16.9297 21.7969 17.1173 21.6093C17.3048 21.4218 17.4102 21.1674 17.4102 20.9022V2.90222C17.4102 2.63701 17.3048 2.38265 17.1173 2.19511C16.9297 2.00758 16.6754 1.90222 16.4102 1.90222Z" fill="white" stroke="white" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                            <path id="Vector_2" d="M5.91016 14.9022H12.9102M5.91016 17.9022H9.41016M12.4102 6.40222L8.41016 10.4022L6.41016 8.40222" stroke="black" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </g>
                </mask>
                <g mask="url(#mask0_11168_599)">
                    <path id="Vector_3" d="M-2.58984 -0.0977783H21.4102V23.9022H-2.58984V-0.0977783Z" />
                </g>
            </g>
        </svg>
    );
}

export const IconSubmitStepper = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fluent:document-arrow-up-20-filled">
                <path id="Vector" d="M12.9101 2.80225V8.20225C12.9101 8.67964 13.0997 9.13747 13.4373 9.47504C13.7749 9.8126 14.2327 10.0022 14.7101 10.0022H20.1101V20.2022C20.1101 20.6796 19.9205 21.1375 19.5829 21.475C19.2453 21.8126 18.7875 22.0022 18.3101 22.0022H12.6017C13.498 20.9169 14.0238 19.5735 14.1023 18.1681C14.1809 16.7628 13.8081 15.3691 13.0384 14.1907C12.2687 13.0122 11.1424 12.1107 9.82403 11.6177C8.50562 11.1248 7.06415 11.0662 5.71011 11.4506V4.60225C5.71011 4.12486 5.89975 3.66702 6.23732 3.32945C6.57488 2.99189 7.03272 2.80225 7.51011 2.80225H12.9101ZM14.1101 3.10225V8.20225C14.1101 8.36138 14.1733 8.51399 14.2858 8.62651C14.3984 8.73903 14.551 8.80225 14.7101 8.80225H19.8101L14.1101 3.10225ZM7.51011 23.2022C8.94228 23.2022 10.3158 22.6333 11.3285 21.6206C12.3412 20.6079 12.9101 19.2344 12.9101 17.8022C12.9101 16.3701 12.3412 14.9966 11.3285 13.9839C10.3158 12.9712 8.94228 12.4022 7.51011 12.4022C6.07794 12.4022 4.70443 12.9712 3.69173 13.9839C2.67903 14.9966 2.11011 16.3701 2.11011 17.8022C2.11011 19.2344 2.67903 20.6079 3.69173 21.6206C4.70443 22.6333 6.07794 23.2022 7.51011 23.2022ZM10.3349 17.3774C10.4476 17.4901 10.5109 17.6429 10.5109 17.8022C10.5109 17.9616 10.4476 18.1144 10.3349 18.227C10.2222 18.3397 10.0694 18.403 9.91011 18.403C9.75078 18.403 9.59797 18.3397 9.48531 18.227L8.11011 16.8506V20.2022C8.11011 20.3614 8.04689 20.514 7.93437 20.6265C7.82185 20.739 7.66924 20.8022 7.51011 20.8022C7.35098 20.8022 7.19837 20.739 7.08584 20.6265C6.97332 20.514 6.91011 20.3614 6.91011 20.2022V16.8506L5.53491 18.227C5.4224 18.3397 5.26975 18.4031 5.11053 18.4032C4.95131 18.4033 4.79857 18.3401 4.68591 18.2276C4.57324 18.1151 4.50989 17.9625 4.50977 17.8033C4.50966 17.6441 4.5728 17.4913 4.68531 17.3786L7.08531 14.9786C7.19697 14.8666 7.34835 14.8032 7.50651 14.8022H7.51371C7.67016 14.803 7.82009 14.865 7.93131 14.975L7.93491 14.9786L10.3349 17.3786V17.3774Z" />
            </g>
        </svg>
    );
}

export const IconInfo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.41016 12.9022C2.41016 7.38222 6.89016 2.90222 12.4102 2.90222C17.9302 2.90222 22.4102 7.38222 22.4102 12.9022C22.4102 18.4222 17.9302 22.9022 12.4102 22.9022C6.89016 22.9022 2.41016 18.4222 2.41016 12.9022ZM4.41016 12.9022C4.41016 17.3122 8.00016 20.9022 12.4102 20.9022C16.8202 20.9022 20.4102 17.3122 20.4102 12.9022C20.4102 8.49222 16.8202 4.90222 12.4102 4.90222C8.00016 4.90222 4.41016 8.49222 4.41016 12.9022ZM13.4102 16.9022V18.9022H11.4102V16.9022H13.4102ZM8.59016 9.73222C9.14016 7.91222 10.9602 6.64222 13.0202 6.94222C14.7602 7.20222 16.1302 8.67222 16.3702 10.4122C16.5982 12.0258 15.7059 12.8043 14.8446 13.5558C14.6864 13.6938 14.5292 13.831 14.3802 13.9722C14.2602 14.0822 14.1502 14.1922 14.0502 14.3122C14.0452 14.3172 14.0402 14.3247 14.0352 14.3322C14.0302 14.3397 14.0252 14.3472 14.0202 14.3522C13.9202 14.4722 13.8202 14.6022 13.7402 14.7422C13.7302 14.7572 13.7227 14.7722 13.7152 14.7872C13.7077 14.8022 13.7002 14.8172 13.6902 14.8322C13.5202 15.1322 13.4102 15.4822 13.4102 15.9022H11.4102C11.4102 15.4022 11.4902 14.9922 11.6102 14.6522C11.6145 14.6391 11.617 14.6278 11.6192 14.6177C11.6221 14.6047 11.6245 14.5935 11.6302 14.5822C11.6352 14.5672 11.6427 14.5547 11.6502 14.5422C11.6577 14.5297 11.6652 14.5172 11.6702 14.5022C11.7402 14.3222 11.8302 14.1522 11.9202 14.0022C11.9252 13.9922 11.9302 13.9847 11.9352 13.9772C11.9402 13.9697 11.9452 13.9622 11.9502 13.9522C11.9502 13.9472 11.9527 13.9447 11.9552 13.9422C11.9577 13.9397 11.9602 13.9372 11.9602 13.9322C12.3007 13.4193 12.7575 13.068 13.1847 12.7395C13.7985 12.2674 14.3512 11.8424 14.4102 11.0522C14.4902 10.0822 13.7902 9.15222 12.8402 8.95222C11.8102 8.73222 10.8602 9.34222 10.5402 10.2322C10.4002 10.6122 10.0702 10.9022 9.66016 10.9022H9.46016C8.85016 10.9022 8.41016 10.3122 8.59016 9.73222Z" fill="black" />
        </svg>
    )
}

export const EditIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.7087 5.63126C21.0987 6.02127 21.0987 6.65127 20.7087 7.04127L18.8787 8.87126L15.1287 5.12126L16.9587 3.29126C17.1456 3.10401 17.3992 2.99878 17.6637 2.99878C17.9283 2.99878 18.1819 3.10401 18.3687 3.29126L20.7087 5.63126ZM2.99875 20.5013V17.4613C2.99875 17.3213 3.04875 17.2013 3.14875 17.1013L14.0587 6.19126L17.8087 9.94127L6.88875 20.8513C6.79875 20.9513 6.66875 21.0013 6.53875 21.0013H3.49875C3.21875 21.0013 2.99875 20.7813 2.99875 20.5013Z" fill="#B493B7" />
        </svg>
    );
}

export const ArrowBtnIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M12.5 13.2292L9.26664 9.99582L12.5 6.76249C12.825 6.43749 12.825 5.91249 12.5 5.58749C12.3443 5.43144 12.1329 5.34375 11.9125 5.34375C11.692 5.34375 11.4807 5.43144 11.325 5.58749L7.49998 9.41249C7.17498 9.73749 7.17498 10.2625 7.49998 10.5875L11.325 14.4125C11.65 14.7375 12.175 14.7375 12.5 14.4125C12.8166 14.0875 12.825 13.5542 12.5 13.2292Z" fill="white" />
        </svg>
    )
}

export const IconRoundPlus = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M13.5898 7H11.5898V11H7.58984V13H11.5898V17H13.5898V13H17.5898V11H13.5898V7ZM12.5898 2C7.06984 2 2.58984 6.48 2.58984 12C2.58984 17.52 7.06984 22 12.5898 22C18.1098 22 22.5898 17.52 22.5898 12C22.5898 6.48 18.1098 2 12.5898 2ZM12.5898 20C8.17984 20 4.58984 16.41 4.58984 12C4.58984 7.59 8.17984 4 12.5898 4C16.9998 4 20.5898 7.59 20.5898 12C20.5898 16.41 16.9998 20 12.5898 20Z" fill="white" />
        </svg>
    );
}

export const CalendarIcon = ({ color }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.4102 4H18.4102V3C18.4102 2.45 17.9602 2 17.4102 2C16.8602 2 16.4102 2.45 16.4102 3V4H8.41016V3C8.41016 2.45 7.96016 2 7.41016 2C6.86016 2 6.41016 2.45 6.41016 3V4H5.41016C4.30016 4 3.42016 4.9 3.42016 6L3.41016 20C3.41016 21.1 4.30016 22 5.41016 22H19.4102C20.5102 22 21.4102 21.1 21.4102 20V6C21.4102 4.9 20.5102 4 19.4102 4ZM19.4102 19C19.4102 19.55 18.9602 20 18.4102 20H6.41016C5.86016 20 5.41016 19.55 5.41016 19V9H19.4102V19ZM9.41016 13V11H7.41016V13H9.41016ZM11.4102 11H13.4102V13H11.4102V11ZM17.4102 13V11H15.4102V13H17.4102Z" fill={color ?? "#B493B7"} />
        </svg>
    );
}

export const PhoenixLogoName = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="164" height="21" viewBox="0 0 164 21" fill="none">
            <path d="M22.905 7.32149C22.905 8.32391 22.7096 9.27267 22.3186 10.1677C21.9396 11.0508 21.4183 11.8325 20.7549 12.5127C20.1034 13.181 19.3333 13.7121 18.4449 14.1059C17.5564 14.4997 16.6146 14.6966 15.6195 14.6966H5.50859L9.15466 11.0628H15.6195C16.1289 11.0628 16.6087 10.9613 17.0588 10.7585C17.509 10.5556 17.8999 10.2871 18.2316 9.9529C18.5633 9.60683 18.824 9.20704 19.0135 8.75355C19.203 8.30007 19.2978 7.8227 19.2978 7.32149C19.2978 6.8083 19.203 6.33094 19.0135 5.88939C18.824 5.43591 18.5633 5.04208 18.2316 4.70795C17.8999 4.37379 17.509 4.11123 17.0588 3.9203C16.6087 3.71742 16.1289 3.61598 15.6195 3.61598H3.60723V20.1564H0V0H15.6195C16.6146 0 17.5564 0.19691 18.4449 0.590732C19.3333 0.972617 20.1034 1.49771 20.7549 2.16601C21.4183 2.82238 21.9396 3.59808 22.3186 4.49311C22.7096 5.37624 22.905 6.31904 22.905 7.32149Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.5698 20.1564H30.2184V0H26.5698V20.1564ZM43.9941 20.1564H47.6425V8.52446e-06H43.9941V8.27022H35.5564L31.9448 11.9399H43.9941V20.1564Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M76.3046 13.9806C76.7421 12.7514 76.9611 11.4387 76.9611 10.0424C76.9611 8.65807 76.7421 7.35727 76.3046 6.14002C75.8676 4.92276 75.2409 3.86063 74.4251 2.95365C73.6214 2.03474 72.6517 1.31273 71.517 0.78764C70.3938 0.262547 69.1409 0 67.7575 0H61.4448C60.0615 0 58.8023 0.262547 57.6677 0.78764C56.5326 1.31273 55.5574 2.03474 54.7416 2.95365C53.9379 3.86063 53.3171 4.92276 52.8797 6.14002C52.4422 7.35727 52.2236 8.65807 52.2236 10.0424C52.2236 11.4387 52.4422 12.7514 52.8797 13.9806C53.3171 15.1979 53.9379 16.266 54.7416 17.1849C55.5574 18.1038 56.5326 18.8317 57.6677 19.3688C58.8023 19.8939 60.0615 20.1564 61.4448 20.1564H67.7575C69.1409 20.1564 70.3938 19.8939 71.517 19.3688C72.6517 18.8317 73.6214 18.1038 74.4251 17.1849C75.2409 16.266 75.8676 15.1979 76.3046 13.9806ZM72.9356 7.55421C73.1957 8.32989 73.3257 9.15933 73.3257 10.0424C73.3257 10.9256 73.1957 11.7609 72.9356 12.5485C72.6873 13.3242 72.3207 14.0045 71.8362 14.5893C71.3631 15.174 70.7838 15.6394 70.0984 15.9855C69.4126 16.3197 68.6325 16.4868 67.7575 16.4868H61.4448C60.5698 16.4868 59.7838 16.3197 59.0862 15.9855C58.4004 15.6394 57.8153 15.174 57.3304 14.5893C56.8577 14.0045 56.4911 13.3242 56.2311 12.5485C55.9827 11.7609 55.859 10.9256 55.859 10.0424C55.859 9.15933 55.9827 8.32989 56.2311 7.55421C56.4911 6.76656 56.8577 6.08633 57.3304 5.51349C57.8153 4.92874 58.4004 4.46928 59.0862 4.13512C59.7838 3.78903 60.5698 3.61599 61.4448 3.61599H67.7575C68.6325 3.61599 69.4126 3.78903 70.0984 4.13512C70.7838 4.46928 71.3631 4.92874 71.8362 5.51349C72.3207 6.08633 72.6873 6.76656 72.9356 7.55421Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M80.6255 20.1564H101.698V16.464H84.3349V3.67454H101.698V0H80.6255V20.1564ZM86.0727 11.8263H99.3504V8.13398H89.3753L86.0727 11.8263Z" fill="black" />
            <path d="M124.497 20.1564C124.497 18.8198 124.263 17.525 123.794 16.2719C123.339 15.0069 122.716 13.8195 121.928 12.7096C121.151 11.5879 120.245 10.5437 119.21 9.57698C118.187 8.61035 117.108 7.7511 115.974 6.99927C114.841 6.2355 113.682 5.59702 112.499 5.08387C111.328 4.55878 110.213 4.17093 109.153 3.9203V20.1564H105.363V0H107.415V0.0358017C108.771 0.107405 110.213 0.399789 111.741 0.912948C113.282 1.41417 114.81 2.10038 116.326 2.97155C117.854 3.8308 119.321 4.85713 120.726 6.0505C122.143 7.23198 123.4 8.53875 124.497 9.97081V0H128.268V20.1564H124.497Z" fill="black" />
            <path d="M136.514 20.1564H132.849V0L136.514 2.97155V20.1564Z" fill="black" />
            <path d="M163.084 20.1564H158.383L151.191 12.6023L144.016 20.1564H139.263L148.823 10.114L144.042 5.05702L139.263 0H144.016L163.084 20.1564Z" fill="black" />
            <path d="M61.7224 13.7429L69.6314 6.41333H65.2609L59.5532 11.7175L61.7224 13.7429Z" fill="black" />
            <path d="M155.372 8.24581L164 0H159.232L153.005 5.96716L155.372 8.24581Z" fill="black" />
        </svg>
    )
}

export const NotificationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2901 17.04L18.0001 15.75V10.75C18.0001 7.68 16.3601 5.11 13.5001 4.43V3.75C13.5001 2.92 12.8301 2.25 12.0001 2.25C11.1701 2.25 10.5001 2.92 10.5001 3.75V4.43C7.63005 5.11 6.00005 7.67 6.00005 10.75V15.75L4.71005 17.04C4.08005 17.67 4.52005 18.75 5.41005 18.75H18.5801C19.4801 18.75 19.9201 17.67 19.2901 17.04ZM8.00005 16.75V10.75C8.00005 8.27 9.51005 6.25 12.0001 6.25C14.4901 6.25 16.0001 8.27 16.0001 10.75V16.75H8.00005ZM14.0001 19.75C14.0001 20.85 13.1001 21.75 12.0001 21.75C10.8901 21.75 10.0001 20.85 10.0001 19.75H14.0001Z" fill="#503A65" />
        </svg>
    )
}

export const NotificationNotFilled = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.7901 17.54L18.5001 16.25V11.25C18.5001 8.18 16.8601 5.61 14.0001 4.93V4.25C14.0001 3.42 13.3301 2.75 12.5001 2.75C11.6701 2.75 11.0001 3.42 11.0001 4.25V4.93C8.13005 5.61 6.50005 8.17 6.50005 11.25V16.25L5.21005 17.54C4.58005 18.17 5.02005 19.25 5.91005 19.25H19.0801C19.9801 19.25 20.4201 18.17 19.7901 17.54ZM8.50005 17.25V11.25C8.50005 8.77 10.0101 6.75 12.5001 6.75C14.9901 6.75 16.5001 8.77 16.5001 11.25V17.25H8.50005ZM14.5001 20.25C14.5001 21.35 13.6001 22.25 12.5001 22.25C11.3901 22.25 10.5001 21.35 10.5001 20.25H14.5001Z" fill="#94A3B8" />
        </svg>
    )
}

export const EditProfileNotFilled = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 4.5C10.29 4.5 8.5 6.29 8.5 8.5C8.5 10.71 10.29 12.5 12.5 12.5C14.71 12.5 16.5 10.71 16.5 8.5C16.5 6.29 14.71 4.5 12.5 4.5ZM14.6 8.5C14.6 7.34 13.66 6.4 12.5 6.4C11.34 6.4 10.4 7.34 10.4 8.5C10.4 9.66 11.34 10.6 12.5 10.6C13.66 10.6 14.6 9.66 14.6 8.5ZM18.6 17.5C18.6 16.86 15.47 15.4 12.5 15.4C9.53 15.4 6.4 16.86 6.4 17.5V18.6H18.6V17.5ZM4.5 17.5C4.5 14.84 9.83 13.5 12.5 13.5C15.17 13.5 20.5 14.84 20.5 17.5V19.5C20.5 20.05 20.05 20.5 19.5 20.5H5.5C4.95 20.5 4.5 20.05 4.5 19.5V17.5Z" fill="#94A3B8" />
        </svg>
    )
}

export const PasswordNotFilled = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4621 9.76594H25.8545C27.3862 9.76594 28.6393 10.8 28.6393 12.0638V23.5532C28.6393 24.817 27.3862 25.8511 25.8545 25.8511H9.14565C7.614 25.8511 6.36084 24.817 6.36084 23.5532V12.0638C6.36084 10.8 7.614 9.76594 9.14565 9.76594H10.5381V7.46807C10.5381 4.29701 13.657 1.72339 17.5001 1.72339C21.3431 1.72339 24.4621 4.29701 24.4621 7.46807V9.76594ZM17.5001 4.02126C15.1887 4.02126 13.3229 5.56084 13.3229 7.46807V9.76594H21.6773V7.46807C21.6773 5.56084 19.8115 4.02126 17.5001 4.02126ZM10.5381 23.5532C9.77223 23.5532 9.14565 23.0362 9.14565 22.4042V13.2128C9.14565 12.5808 9.77223 12.0638 10.5381 12.0638H24.4621C25.2279 12.0638 25.8545 12.5808 25.8545 13.2128V22.4042C25.8545 23.0362 25.2279 23.5532 24.4621 23.5532H10.5381ZM20.2849 17.8085C20.2849 19.0723 19.0317 20.1064 17.5001 20.1064C15.9684 20.1064 14.7153 19.0723 14.7153 17.8085C14.7153 16.5447 15.9684 15.5106 17.5001 15.5106C19.0317 15.5106 20.2849 16.5447 20.2849 17.8085Z" fill="#94A3B8" />
        </svg>
    )
}

export const EmployeeAccountFilled = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.99 8C11.99 9.66 10.66 11 9 11C7.34 11 6 9.66 6 8C6 6.34 7.34 5 9 5C10.66 5 11.99 6.34 11.99 8ZM18.99 9.5C18.99 10.88 17.88 12 16.5 12C15.12 12 14 10.88 14 9.5C14 8.12 15.12 7 16.5 7C17.88 7 18.99 8.12 18.99 9.5ZM16.5 14C14.67 14 11 14.92 11 16.75V18C11 18.55 11.45 19 12 19H21C21.55 19 22 18.55 22 18V16.75C22 14.92 18.33 14 16.5 14ZM2 16.5C2 14.17 6.67 13 9 13C9.66 13 10.5 13.1 11.37 13.28C9.33 14.41 9 15.9 9 16.75V19H3C2.45 19 2 18.55 2 18V16.5Z" fill="#503A65" />
        </svg>
    )
}

export const DeleteAccountNotFilled = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M19.5 6.91L18.09 5.5L12.5 11.09L6.91 5.5L5.5 6.91L11.09 12.5L5.5 18.09L6.91 19.5L12.5 13.91L18.09 19.5L19.5 18.09L13.91 12.5L19.5 6.91Z" fill="#94A3B8" />
        </svg>
    )
}

export const EditIconAC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path d="M4 5H3C2.46957 5 1.96086 5.21071 1.58579 5.58579C1.21071 5.96086 1 6.46957 1 7V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H12C12.5304 18 13.0391 17.7893 13.4142 17.4142C13.7893 17.0391 14 16.5304 14 16V15" stroke="#503A65" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 3.00011L16 6.00011M17.385 4.58511C17.7788 4.19126 18.0001 3.65709 18.0001 3.10011C18.0001 2.54312 17.7788 2.00895 17.385 1.61511C16.9912 1.22126 16.457 1 15.9 1C15.343 1 14.8088 1.22126 14.415 1.61511L6 10.0001V13.0001H9L17.385 4.58511Z" stroke="#503A65" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const AddNewEmployeePlus = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
        </svg>
    )
}

export const CommunicationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_12492_7102" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_12492_7102)">
                <path d="M6 14H18V12H6V14ZM6 11H18V9H6V11ZM6 8H18V6H6V8ZM4 18C3.45 18 2.97917 17.8042 2.5875 17.4125C2.19583 17.0208 2 16.55 2 16V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V22L18 18H4Z" fill="#503A65" />
            </g>
        </svg >
    );
}

export const OverviewIcon = ({ color }: { color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24">
            <path d="M20.175 20.375L20.875 19.675L19 17.8V15H18V18.2L20.175 20.375ZM18.5 23C17.1167 23 15.9373 22.5123 14.962 21.537C13.9867 20.5617 13.4993 19.3827 13.5 18C13.5 16.6167 13.9877 15.4373 14.963 14.462C15.9383 13.4867 17.1173 12.9993 18.5 13C19.8833 13 21.0627 13.4877 22.038 14.463C23.0133 15.4383 23.5007 16.6173 23.5 18C23.5 19.3833 23.0123 20.5627 22.037 21.538C21.0617 22.5133 19.8827 23.0007 18.5 23ZM7.5 9H17.5V7H7.5V9ZM12.175 21H5.5C4.95 21 4.479 20.804 4.087 20.412C3.695 20.02 3.49934 19.5493 3.5 19V5C3.5 4.45 3.696 3.979 4.088 3.587C4.48 3.195 4.95067 2.99934 5.5 3H19.5C20.05 3 20.521 3.196 20.913 3.588C21.305 3.98 21.5007 4.45067 21.5 5V11.7C21.0167 11.4667 20.529 11.2917 20.037 11.175C19.545 11.0583 19.0327 11 18.5 11C18.3167 11 18.1457 11.004 17.987 11.012C17.8283 11.02 17.666 11.041 17.5 11.075V11H7.5V13H13.625C13.325 13.2833 13.054 13.5917 12.812 13.925C12.57 14.2583 12.3577 14.6167 12.175 15H7.5V17H11.575C11.5417 17.1667 11.5207 17.3293 11.512 17.488C11.5033 17.6467 11.4993 17.8173 11.5 18C11.5 18.55 11.55 19.0627 11.65 19.538C11.75 20.0133 11.925 20.5007 12.175 21Z" fill={color ?? "#818181"} />
        </svg>
    );
}

export const FinancialsIcon = ({ color }: { color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 18">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.886 9.3335C10.7481 8.94351 10.4927 8.60587 10.1549 8.36706C9.81714 8.12824 9.41366 8.00001 9 8V7.5H8V8C7.46957 8 6.96086 8.21071 6.58579 8.58579C6.21071 8.96086 6 9.46957 6 10C6 10.5304 6.21071 11.0391 6.58579 11.4142C6.96086 11.7893 7.46957 12 8 12V14C7.565 14 7.1945 13.7225 7.0565 13.3335C7.03603 13.2699 7.003 13.211 6.95936 13.1603C6.91573 13.1097 6.86239 13.0683 6.80248 13.0386C6.74257 13.0089 6.67732 12.9916 6.61059 12.9875C6.54386 12.9835 6.47699 12.9929 6.41396 13.0152C6.35092 13.0375 6.29299 13.0721 6.2436 13.1172C6.1942 13.1622 6.15434 13.2167 6.12637 13.2775C6.09841 13.3382 6.0829 13.4039 6.08078 13.4707C6.07865 13.5376 6.08995 13.6041 6.114 13.6665C6.25192 14.0565 6.50734 14.3941 6.8451 14.6329C7.18286 14.8718 7.58634 15 8 15V15.5H9V15C9.53043 15 10.0391 14.7893 10.4142 14.4142C10.7893 14.0391 11 13.5304 11 13C11 12.4696 10.7893 11.9609 10.4142 11.5858C10.0391 11.2107 9.53043 11 9 11V9C9.20681 8.99995 9.40854 9.06402 9.57743 9.18338C9.74631 9.30274 9.87403 9.47153 9.943 9.6665C9.98716 9.79155 10.0792 9.89394 10.1988 9.95113C10.2581 9.97946 10.3223 9.99583 10.3879 9.99933C10.4535 10.0028 10.5191 9.99337 10.581 9.9715C10.6429 9.94964 10.6999 9.91579 10.7488 9.87189C10.7976 9.828 10.8373 9.77491 10.8656 9.71567C10.894 9.65642 10.9103 9.59218 10.9138 9.52661C10.9173 9.46104 10.9079 9.39542 10.886 9.3335ZM8 9C7.73478 9 7.48043 9.10536 7.29289 9.29289C7.10536 9.48043 7 9.73478 7 10C7 10.2652 7.10536 10.5196 7.29289 10.7071C7.48043 10.8946 7.73478 11 8 11V9ZM9 14C9.26522 14 9.51957 13.8946 9.70711 13.7071C9.89464 13.5196 10 13.2652 10 13C10 12.7348 9.89464 12.4804 9.70711 12.2929C9.51957 12.1054 9.26522 12 9 12V14Z" fill={color ?? "#818181"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.07649 1.3105C4.30349 0.71 6.31649 0 8.51949 0C10.6765 0 12.6365 0.6805 13.857 1.273L13.926 1.3065C14.294 1.4885 14.591 1.6605 14.8 1.8L12.953 4.5C17.211 8.853 20.5 17.9985 8.51949 17.9985C-3.46101 17.9985 -0.26051 9.019 4.03449 4.5L2.19949 1.8C2.34099 1.7075 2.52049 1.6 2.73449 1.486C2.84049 1.429 2.95449 1.37 3.07649 1.3105ZM11.766 4.464L13.2445 2.3025C11.8695 2.4015 10.2335 2.7245 8.65849 3.1805C7.53349 3.5055 6.28349 3.456 5.12599 3.2425C4.83431 3.18842 4.54462 3.12405 4.25749 3.0495L5.21749 4.463C7.27499 5.1955 9.70799 5.1955 11.766 4.464ZM4.63999 5.315C7.04749 6.245 9.94099 6.245 12.3485 5.314C13.3534 6.37335 14.1941 7.57719 14.8425 8.8855C15.5185 10.2645 15.8865 11.643 15.8265 12.831C15.7685 13.9775 15.3175 14.957 14.2875 15.685C13.214 16.4435 11.4085 16.9985 8.51899 16.9985C5.62649 16.9985 3.81249 16.453 2.72899 15.703C1.69149 14.984 1.23599 14.018 1.17149 12.887C1.10399 11.712 1.46399 10.3405 2.13749 8.952C2.77999 7.628 3.67649 6.3535 4.63999 5.315ZM4.06499 1.958C4.46499 2.077 4.88299 2.1805 5.30699 2.259C6.38199 2.457 7.45999 2.486 8.37999 2.2195C9.4521 1.90717 10.5425 1.66154 11.645 1.484C10.725 1.207 9.64899 1 8.51899 1C6.79649 1 5.18999 1.4805 4.06499 1.958Z" fill={color ?? "#818181"} />
        </svg>
    );
}

export const DataIcon = ({ color }: { color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17ZM19 19H5V5H19V19.1M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill={color ?? "#818181"} />
        </svg>
    );
}

export const NotesIcon = ({ color }: { color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 25" >
            <path d="M4.8 19.7C4.305 19.7 3.8811 19.5236 3.5283 19.1708C3.1755 18.818 2.9994 18.3944 3 17.9V5.3C3 4.805 3.1764 4.3811 3.5292 4.0283C3.882 3.6755 4.3056 3.4994 4.8 3.5H17.4C17.895 3.5 18.3189 3.6764 18.6717 4.0292C19.0245 4.382 19.2006 4.8056 19.2 5.3V11.33C18.915 11.195 18.6225 11.0786 18.3225 10.9808C18.0225 10.883 17.715 10.8119 17.4 10.7675V5.3H4.8V17.9H10.245C10.29 18.23 10.3614 18.545 10.4592 18.845C10.557 19.145 10.6731 19.43 10.8075 19.7H4.8ZM4.8 17V17.9V5.3V10.7675V10.7V17ZM6.6 16.1H10.2675C10.3125 15.785 10.3839 15.4775 10.4817 15.1775C10.5795 14.8775 10.6881 14.585 10.8075 14.3H6.6V16.1ZM6.6 12.5H12.09C12.57 12.05 13.1064 11.675 13.6992 11.375C14.292 11.075 14.9256 10.8725 15.6 10.7675V10.7H6.6V12.5ZM6.6 8.9H15.6V7.1H6.6V8.9ZM16.5 21.5C15.255 21.5 14.1936 21.0611 13.3158 20.1833C12.438 19.3055 11.9994 18.2444 12 17C12 15.755 12.4389 14.6936 13.3167 13.8158C14.1945 12.938 15.2556 12.4994 16.5 12.5C17.745 12.5 18.8064 12.9389 19.6842 13.8167C20.562 14.6945 21.0006 15.7556 21 17C21 18.245 20.5611 19.3064 19.6833 20.1842C18.8055 21.062 17.7444 21.5006 16.5 21.5ZM16.05 19.7H16.95V17.45H19.2V16.55H16.95V14.3H16.05V16.55H13.8V17.45H16.05V19.7Z" fill={color ?? "#818181"} />
        </svg>
    );
}

export const DocumentsIcon = ({ color }: { color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 22" fill="none">
            <path d="M1 9C1 5.229 1 3.343 2.172 2.172C3.343 1 5.229 1 9 1H11C14.771 1 16.657 1 17.828 2.172C19 3.343 19 5.229 19 9V13C19 16.771 19 18.657 17.828 19.828C16.657 21 14.771 21 11 21H9C5.229 21 3.343 21 2.172 19.828C1 18.657 1 16.771 1 13V9Z" stroke={color ?? "#818181"} strokeWidth="1.5" />
            <path d="M6 9H14M6 13H11" stroke={color ?? "#818181"} strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
}

export const PerformanceIcon = ({ color }: { color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M18 1H3C2.60218 1 2.22064 1.15804 1.93934 1.43934C1.65804 1.72064 1.5 2.10218 1.5 2.5V17.5C1.5 17.8978 1.65804 18.2794 1.93934 18.5607C2.22064 18.842 2.60218 19 3 19H18C18.3978 19 18.7794 18.842 19.0607 18.5607C19.342 18.2794 19.5 17.8978 19.5 17.5V2.5C19.5 2.10218 19.342 1.72064 19.0607 1.43934C18.7794 1.15804 18.3978 1 18 1Z" stroke={color ?? "#818181"} strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M5.22 12.9175L8.0485 10.089L10.2425 12.2775L15.5 7" stroke={color ?? "#818181"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 7H15.5V11" stroke={color ?? "#818181"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export const SyndicationIcon = ({ color }: { color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 30" fill="none">
            <path d="M6.48001 23.2801C8.06401 23.2801 9.36001 21.9841 9.36001 20.4001C9.36001 18.8161 8.06401 17.5201 6.48001 17.5201C4.89601 17.5201 3.60001 18.8161 3.60001 20.4001C3.60001 21.9841 4.89601 23.2801 6.48001 23.2801ZM6.48001 18.4801C7.53601 18.4801 8.40001 19.3441 8.40001 20.4001C8.40001 21.4561 7.53601 22.3201 6.48001 22.3201C5.42401 22.3201 4.56001 21.4561 4.56001 20.4001C4.56001 19.3441 5.42401 18.4801 6.48001 18.4801ZM6.00001 12.4801C6.00001 12.2161 6.21601 12.0001 6.48001 12.0001C11.112 12.0001 14.88 15.7681 14.88 20.4001C14.88 20.6641 14.664 20.8801 14.4 20.8801C14.136 20.8801 13.92 20.6641 13.92 20.4001C13.92 16.2961 10.584 12.9601 6.48001 12.9601C6.21601 12.9601 6.00001 12.7441 6.00001 12.4801ZM6.00001 6.96008C6.00001 6.69608 6.21601 6.48008 6.48001 6.48008C14.16 6.48008 20.4 12.7201 20.4 20.4001C20.4 20.6641 20.184 20.8801 19.92 20.8801C19.656 20.8801 19.44 20.6641 19.44 20.4001C19.44 13.2481 13.632 7.44008 6.48001 7.44008C6.21601 7.44008 6.00001 7.22408 6.00001 6.96008ZM22.32 4.08008H1.68001C1.41601 4.08008 1.20001 4.29608 1.20001 4.56008V25.2001C1.20001 25.4641 1.41601 25.6801 1.68001 25.6801H22.32C22.584 25.6801 22.8 25.4641 22.8 25.2001V4.56008C22.8 4.29608 22.584 4.08008 22.32 4.08008ZM21.84 20.7201C21.84 22.9292 20.0492 24.7201 17.84 24.7201H6.16001C3.95087 24.7201 2.16001 22.9292 2.16001 20.7201V9.04008C2.16001 6.83094 3.95087 5.04008 6.16001 5.04008H17.84C20.0492 5.04008 21.84 6.83094 21.84 9.04008V20.7201Z" fill={color ?? "#818181"} />
        </svg>
    );
}

export const OptionsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="#475569" />
        </svg>
    );
}

export const LocationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 12C12.55 12 13.021 11.804 13.413 11.412C13.8043 11.0207 14 10.55 14 10C14 9.45 13.8043 8.979 13.413 8.587C13.021 8.19567 12.55 8 12 8C11.45 8 10.9793 8.19567 10.588 8.587C10.196 8.979 10 9.45 10 10C10 10.55 10.196 11.0207 10.588 11.412C10.9793 11.804 11.45 12 12 12ZM12 19.35C14.0333 17.4833 15.5417 15.7873 16.525 14.262C17.5083 12.7373 18 11.3833 18 10.2C18 8.38333 17.4207 6.89567 16.262 5.737C15.104 4.579 13.6833 4 12 4C10.3167 4 8.89567 4.579 7.737 5.737C6.579 6.89567 6 8.38333 6 10.2C6 11.3833 6.49167 12.7373 7.475 14.262C8.45833 15.7873 9.96667 17.4833 12 19.35ZM12 21.625C11.8667 21.625 11.7333 21.6 11.6 21.55C11.4667 21.5 11.35 21.4333 11.25 21.35C8.81667 19.2 7 17.2043 5.8 15.363C4.6 13.521 4 11.8 4 10.2C4 7.7 4.80433 5.70833 6.413 4.225C8.021 2.74167 9.88333 2 12 2C14.1167 2 15.979 2.74167 17.587 4.225C19.1957 5.70833 20 7.7 20 10.2C20 11.8 19.4 13.521 18.2 15.363C17 17.2043 15.1833 19.2 12.75 21.35C12.65 21.4333 12.5333 21.5 12.4 21.55C12.2667 21.6 12.1333 21.625 12 21.625Z" fill="#503A65" />
        </svg>
    );
}

export const CrossIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#334155" />
        </svg>
    );
}

export const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 32 26" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.72 3.50935L20.6666 4.26668H24C24.7333 4.26668 25.3333 4.74668 25.3333 5.33335C25.3333 5.92001 24.7333 6.40001 24 6.40001H7.99996C7.26663 6.40001 6.66663 5.92001 6.66663 5.33335C6.66663 4.74668 7.26663 4.26668 7.99996 4.26668H11.3333L12.28 3.50935C12.52 3.31735 12.8666 3.20001 13.2133 3.20001H18.7866C19.1333 3.20001 19.48 3.31735 19.72 3.50935ZM7.99996 20.2667C7.99996 21.44 9.19996 22.4 10.6666 22.4H21.3333C22.8 22.4 24 21.44 24 20.2667V9.60001C24 8.42668 22.8 7.46668 21.3333 7.46668H10.6666C9.19996 7.46668 7.99996 8.42668 7.99996 9.60001V20.2667ZM12 9.60001H20C20.7333 9.60001 21.3333 10.08 21.3333 10.6667V19.2C21.3333 19.7867 20.7333 20.2667 20 20.2667H12C11.2666 20.2667 10.6666 19.7867 10.6666 19.2V10.6667C10.6666 10.08 11.2666 9.60001 12 9.60001Z" fill="#503A65" />
        </svg>
    );
}

export const WarningIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
            <path d="M29.8552 25.5172L15.9274 1.41142C15.7198 1.05318 15.3616 0.875732 15 0.875732C14.6384 0.875732 14.2768 1.05318 14.0726 1.41142L0.144813 25.5172C-0.266994 26.2337 0.248602 27.1242 1.07222 27.1242H28.9278C29.7514 27.1242 30.267 26.2337 29.8552 25.5172ZM13.9286 10.7859C13.9286 10.6386 14.0492 10.518 14.1965 10.518H15.8035C15.9508 10.518 16.0714 10.6386 16.0714 10.7859V16.9463C16.0714 17.0936 15.9508 17.2141 15.8035 17.2141H14.1965C14.0492 17.2141 13.9286 17.0936 13.9286 16.9463V10.7859ZM15 22.5709C14.5795 22.5623 14.179 22.3893 13.8847 22.0888C13.5903 21.7884 13.4254 21.3845 13.4254 20.9639C13.4254 20.5433 13.5903 20.1394 13.8847 19.8389C14.179 19.5385 14.5795 19.3654 15 19.3568C15.4205 19.3654 15.821 19.5385 16.1153 19.8389C16.4097 20.1394 16.5746 20.5433 16.5746 20.9639C16.5746 21.3845 16.4097 21.7884 16.1153 22.0888C15.821 22.3893 15.4205 22.5623 15 22.5709Z" fill="#EA524F" />
        </svg>
    );
}

export const DownloadIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99996 8.38317C5.91107 8.38317 5.82774 8.36917 5.74996 8.34117C5.67218 8.31361 5.59996 8.2665 5.53329 8.19984L3.13329 5.79984C3.01107 5.67761 2.94996 5.52206 2.94996 5.33317C2.94996 5.14428 3.01107 4.98873 3.13329 4.8665C3.25551 4.74428 3.41374 4.68028 3.60796 4.6745C3.80263 4.66917 3.96107 4.72761 4.08329 4.84984L5.33329 6.09984V1.33317C5.33329 1.14428 5.39729 0.985837 5.52529 0.857837C5.65285 0.730282 5.81107 0.666504 5.99996 0.666504C6.18885 0.666504 6.34729 0.730282 6.47529 0.857837C6.60285 0.985837 6.66663 1.14428 6.66663 1.33317V6.09984L7.91663 4.84984C8.03885 4.72761 8.19729 4.66917 8.39196 4.6745C8.58618 4.68028 8.7444 4.74428 8.86662 4.8665C8.98885 4.98873 9.04996 5.14428 9.04996 5.33317C9.04996 5.52206 8.98885 5.67761 8.86662 5.79984L6.46663 8.19984C6.39996 8.2665 6.32774 8.31361 6.24996 8.34117C6.17218 8.36917 6.08885 8.38317 5.99996 8.38317ZM1.99996 11.3332C1.63329 11.3332 1.31951 11.2027 1.05863 10.9418C0.797293 10.6805 0.666626 10.3665 0.666626 9.99984V8.6665C0.666626 8.47761 0.730404 8.31917 0.857959 8.19117C0.985959 8.06361 1.1444 7.99984 1.33329 7.99984C1.52218 7.99984 1.68063 8.06361 1.80863 8.19117C1.93618 8.31917 1.99996 8.47761 1.99996 8.6665V9.99984H9.99996V8.6665C9.99996 8.47761 10.064 8.31917 10.192 8.19117C10.3195 8.06361 10.4777 7.99984 10.6666 7.99984C10.8555 7.99984 11.0137 8.06361 11.1413 8.19117C11.2693 8.31917 11.3333 8.47761 11.3333 8.6665V9.99984C11.3333 10.3665 11.2028 10.6805 10.942 10.9418C10.6806 11.2027 10.3666 11.3332 9.99996 11.3332H1.99996Z" fill="#503A65" />
        </svg>
    );
}

export const EmainIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z" fill="#503A65" />
        </svg>
    );
}

export const UploadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M8.25264 26.6041C6.23042 26.6041 4.50287 25.9041 3.06998 24.5041C1.6362 23.1041 0.919312 21.393 0.919312 19.3708C0.919312 17.6374 1.44153 16.093 2.48598 14.7374C3.53042 13.3819 4.89709 12.5152 6.58598 12.1374C7.14153 10.093 8.25264 8.43742 9.91931 7.17075C11.586 5.90409 13.4749 5.27075 15.586 5.27075C18.186 5.27075 20.3913 6.17609 22.202 7.98675C24.0135 9.79831 24.9193 12.0041 24.9193 14.6041C26.4526 14.7819 27.7251 15.4428 28.7366 16.5868C29.7473 17.7316 30.2526 19.0708 30.2526 20.6041C30.2526 22.2708 29.6695 23.6876 28.5033 24.8547C27.3362 26.021 25.9193 26.6041 24.2526 26.6041H16.9193C16.186 26.6041 15.5584 26.3432 15.0366 25.8214C14.514 25.2988 14.2526 24.6708 14.2526 23.9374V17.0708L12.1193 19.1374L10.2526 17.2708L15.586 11.9374L20.9193 17.2708L19.0526 19.1374L16.9193 17.0708V23.9374H24.2526C25.186 23.9374 25.9749 23.6152 26.6193 22.9708C27.2638 22.3263 27.586 21.5374 27.586 20.6041C27.586 19.6708 27.2638 18.8819 26.6193 18.2374C25.9749 17.593 25.186 17.2708 24.2526 17.2708H22.2526V14.6041C22.2526 12.7596 21.6029 11.1872 20.3033 9.88675C19.0029 8.5872 17.4304 7.93742 15.586 7.93742C13.7415 7.93742 12.1695 8.5872 10.87 9.88675C9.56953 11.1872 8.91931 12.7596 8.91931 14.6041H8.25264C6.96376 14.6041 5.86376 15.0596 4.95264 15.9708C4.04153 16.8819 3.58598 17.9819 3.58598 19.2708C3.58598 20.5596 4.04153 21.6596 4.95264 22.5708C5.86376 23.4819 6.96376 23.9374 8.25264 23.9374H11.586V26.6041H8.25264Z" fill="#B493B7" />
        </svg>
    );
}

export const ValidationRightGreen = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 2C4.86 2 1.5 5.36 1.5 9.5C1.5 13.64 4.86 17 9 17C13.14 17 16.5 13.64 16.5 9.5C16.5 5.36 13.14 2 9 2ZM9 15.5C5.6925 15.5 3 12.8075 3 9.5C3 6.1925 5.6925 3.5 9 3.5C12.3075 3.5 15 6.1925 15 9.5C15 12.8075 12.3075 15.5 9 15.5ZM7.5 11.1275L11.91 6.7175C12.2025 6.425 12.6825 6.425 12.975 6.7175C13.2675 7.01 13.2675 7.4825 12.975 7.775L8.0325 12.7175C7.74 13.01 7.2675 13.01 6.975 12.7175L5.0325 10.775C4.74 10.4825 4.74 10.01 5.0325 9.7175C5.17262 9.57706 5.36286 9.49814 5.56125 9.49814C5.75964 9.49814 5.94988 9.57706 6.09 9.7175L7.5 11.1275Z" fill="#059669" />
        </svg>
    )
}

export const ValidationWrongRed = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <rect x="0.75" y="1.25" width="14.5" height="14.5" rx="7.25" stroke="#DC2626" strokeWidth="1.5" />
            <path d="M12.2 4.30666C12.0754 4.18183 11.9063 4.11167 11.73 4.11167C11.5536 4.11167 11.3845 4.18183 11.26 4.30666L7.99998 7.55999L4.73998 4.29999C4.61543 4.17516 4.44633 4.105 4.26998 4.105C4.09364 4.105 3.92453 4.17516 3.79998 4.29999C3.53998 4.55999 3.53998 4.97999 3.79998 5.23999L7.05998 8.49999L3.79998 11.76C3.53998 12.02 3.53998 12.44 3.79998 12.7C4.05998 12.96 4.47998 12.96 4.73998 12.7L7.99998 9.43999L11.26 12.7C11.52 12.96 11.94 12.96 12.2 12.7C12.46 12.44 12.46 12.02 12.2 11.76L8.93998 8.49999L12.2 5.23999C12.4533 4.98666 12.4533 4.55999 12.2 4.30666Z" fill="#DC2626" />
        </svg>
    )
}