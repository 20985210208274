import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import apiCall from "../../../../components/src/apiCall.web";
import {
  getStorageData,
  removeStorageData,
  setStorageData
} from "../../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  businessInfo: any;
  personalInfo: any[];
  is_edit: boolean;
  token: string;
  submissionId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReviewInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addBusinessInfoApiCallId: any;
  addPersonalInfoApiCallId: any;
  createSubmissionIdApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      businessInfo: {},
      personalInfo: [],
      is_edit: false,
      token: "",
      submissionId: null,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleStoredData();
  }
  // Customizable Area End

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handleApiRequests(apiRequestCallId, responseJson);

      if (apiRequestCallId === this.addBusinessInfoApiCallId) {
        if (responseJson.errors) {
          console.error(responseJson.errors);
        }
      }

      if (apiRequestCallId === this.addPersonalInfoApiCallId) {
        if (!responseJson.errors) {
          await removeStorageData('businessInfo');
          await removeStorageData('personalInfo');
          await removeStorageData('is_edit');
          await this.props.history.push('/dataentry');
        }
      }
    }
    // Customizable Area End
    runEngine.debugLog("Message Recived", message);
  }

  // Customizable Area Start
  handleApiRequests = (apiRequestCallId:any, responseJson:any) => {
    if(apiRequestCallId === this.createSubmissionIdApiCallId) {
      if(!responseJson.errors && responseJson.data.id) {
        this.setState({ submissionId: responseJson.data.id }, () => {
          this.handleSubmitData();
        });
      }
    }
  }

  handleStoredData = async () => {
    const storedBusinessInfo: any = JSON.parse(await getStorageData("businessInfo"));
    const storedPersonalInfo: any = JSON.parse(await getStorageData("personalInfo"));
    const userToken = await getStorageData('userToken');
    this.setState({ token: userToken });

    if (storedPersonalInfo && Object.keys(storedPersonalInfo).length > 0) {
      this.setState({ personalInfo: [] }, () => {
        this.setState({ personalInfo: storedPersonalInfo });
      })
    }

    if (storedBusinessInfo && Object.keys(storedBusinessInfo).length > 0) {
      this.setState({ businessInfo: storedBusinessInfo });
    }
  }

  formateDate = (date: any) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  handleEditButtonClick = (path: string) => {
    setStorageData('is_edit', true);
    this.props.history.push(path);
  }

  createSubmissionID = async () => {
    const requestheaders = {
      'content-type': 'application/json',
      token: this.state.token,
    };

    this.createSubmissionIdApiCallId = await apiCall({
      header: JSON.stringify(requestheaders),
      method: "POST",
      body: JSON.stringify({}),
      endpoint: configJSON.getAllDataEntryApiEndPoint
    })
  }

  handleSubmitData = async () => {
    const { token, submissionId, businessInfo, personalInfo } = this.state; 

    const requestheaders = {
      'content-type': 'application/json',
      token: token,
    };

    const httpBodyBusinessInfo = {
      "business_info": {
        "submission_id": submissionId,
        ...businessInfo
      }
    }
    
    const httpBodyPersonalInfo = {
      "personal_info": personalInfo?.map(item => ({ ...item, ['submission_id']: submissionId }))
    };

    this.addBusinessInfoApiCallId = await apiCall({
      header: JSON.stringify(requestheaders),
      method: configJSON.addUserApiMethod,
      endpoint: configJSON.addBusinessInfoApiEndPoint,
      body: JSON.stringify(httpBodyBusinessInfo),
    });

    this.addPersonalInfoApiCallId = await apiCall({
      header: JSON.stringify(requestheaders),
      method: configJSON.addUserApiMethod,
      endpoint: configJSON.addPersonalInfoApiEndPoint,
      body: JSON.stringify(httpBodyPersonalInfo),
    });
  }

  handleEmptyData = (data: string) => {
    return data || "None";
  }
  // Customizable Area End
}
