import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

interface Props {
    header: any;
    method: string;
    endpoint: string;
    body?: any;
}

export const apiCall = async (props: Props) => {

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        props.endpoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        props.header
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        props.method
    );

    props.body && requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        props.body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return requestMessage.messageId;
}

export default apiCall;