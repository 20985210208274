import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { webStyles } from "./MerchantDocuments.web";
import { WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// Customizable Area Start
const moment = require('moment');

export interface Payment {
  type: any;
  dateSettled: any;
  amount: any;
  balance: any;
}

// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps, WithStyles<typeof webStyles> {
  navigation?: any;
  history: any;
  location: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  documents: Payment[];
  isOpenAddPaymentDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MerchantPaymentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      isOpenAddPaymentDialog: false,
      documents: [
        {
          type: "Default Fee",
          amount: "11283.32",
          dateSettled: "10-07-2023 & 9:32 AM",
          balance: "54675.00",
        },
        {
          type: "Default Fee",
          amount: "11283.32",
          dateSettled: "10-07-2023 & 9:32 AM",
          balance: "54675.00",
        },
        {
          type: "Default Fee",
          amount: "11283.32",
          dateSettled: "10-07-2023 & 9:32 AM",
          balance: "54675.00",
        },
        {
          type: "Default Fee",
          amount: "11283.32",
          dateSettled: "10-07-2023 & 9:32 AM",
          balance: "54675.00",
        },
      ],
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  handleOpenAddPaymentDialog = () => {
    this.setState({ isOpenAddPaymentDialog: true })
  }
  handleCloseAddPaymentDialog = () => {
    this.setState({ isOpenAddPaymentDialog: false })
  }
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
