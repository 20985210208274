import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { classes } from "./Underwriter.web";
import { WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "../../../../framework/src/Utilities";
import apiCall from "../../../../components/src/apiCall.web";

const moment = require('moment');
// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps, WithStyles<typeof classes> {
  navigation?: any;
  history: any;
  location: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allData: any[];
  isLoading: boolean;
  userToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UnderwriterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllMerchantApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      allData: [],
      isLoading: false,
      userToken: "",
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getAllMerchantApiCallId) {
        if (responseJson.data) {
          this.setState({ isLoading: false }, () => {
            this.handleDataForDataGrid(responseJson.data);
          });
        } else {
          this.setState({ isLoading: false });
        }
      }
    }
    // Customizable Area End
    runEngine.debugLog("Message Recived", message);
  }


  async componentDidMount() {
    const token = await getStorageData('userToken');
    this.setState({ userToken: token }, () => {
      this.getAllMerchant();
    });
  }

  getAllMerchant = async () => {
    this.setState({ isLoading: true });

    const requestheaders = {
      'content-type': 'application/json',
      token: this.state.userToken,
    };

    this.getAllMerchantApiCallId = await apiCall({
      header: JSON.stringify(requestheaders),
      method: configJSON.getUserListApiMethod,
      endpoint: configJSON.getAllMerchantApiEndPoint
    });

  }
  // Customizable Area End

  // Customizable Area Start
  handleDataForDataGrid = (response: any) => {
    let finalDataArray: any[] = [];

    response?.forEach((item: any) => {
      if (item.attributes.submissions?.length > 0) {
        item.attributes.submissions.forEach((sub:any) => {
          if (
            sub?.attributes?.business_info &&
            sub?.attributes?.personal_infos.length
          ) {
            const { business_info, personal_infos } = sub.attributes;
            const submissionData = {
              flag: business_info.attributes.type || '',
              merchant: business_info.attributes.merchant || '',
              mid: business_info.attributes.merchant || '',
              renewal: '',
              submitted: this.changeDateFormat(personal_infos[0]) || '',
              iso: business_info.attributes.iso || '',
              status: '',
              uw: personal_infos[0].attributes.first_name || '',
            };
            finalDataArray.push(submissionData);
          }
        })
      }
    });

    this.setState({ allData: finalDataArray });
  };

  changeDateFormat = (data: any) => {
    let date = "";
    if (data && Object.keys(data).length > 0) {
      date = data.attributes?.created_at;
    }
    return moment(date).format("MM/DD/YYYY");
  }
  // Customizable Area End
}
