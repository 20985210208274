// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import '../../assets/css/DataEntryScreens.web.css';
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import {
    Button,
    InputAdornment,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import { iconArrowDown, plusRoundedIcon } from "../assets";
import { UploadIcon } from "../../../../components/src/IconAssets";
import { Chart as GoogleChart } from 'react-google-charts';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import UnderwriterScreenStepperWeb from "./UnderwriterScreenStepper.web";
import MerchantDocumentsController, { Props } from "./MerchantDocumentsController.web";
import CustomDataGrid, { ColumnsProps } from "../../../../components/src/CustomDataGrid.web";

const chartData = [
    ['Task', 'Count'],
    ['Open Task', 3],
    ['Completed Task', 14],
];

const documentColumns: ColumnsProps[] = [
    { key: 'stips', label: 'Stips' },
    { key: 'department', label: 'Department' },
    { key: 'date_time', label: 'Date & Time' },
    { key: 'verified', label: 'Verified' },
    { key: 'document', label: 'Document' },
    { key: 'notes', label: 'Notes' },
    { key: 'action', label: '', cellRender: (row) => <>
        <Button className={row.code}><Typography style={styles.viewMoreBtnStyle}>View Detail</Typography></Button>
        <Button className={row.code}><Typography style={styles.viewMoreBtnStyle}>Delete</Typography></Button>
    </> },
];

// Customizable Area End

export class MerchantDocuments extends MerchantDocumentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { documents } = this.state;
        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <main className={`${classes.mainWrapper} page-main`} data-test-id="Main">
                    <Typography variant="h3" className={classes.pageTitle}>Merchant Overview</Typography>
                    <UnderwriterScreenStepperWeb />
                    <div className={classes.pageFormSection}>
                        <div className={classes.formWrapper}>
                            <form className={classes.generateDoc}>
                                <Typography variant="h4">Generate Documents</Typography>
                                <Select
                                    id="gender"
                                    name="principal_gender"
                                    className="select-box"
                                    variant="outlined"
                                    data-test-id="genderName"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem className="select-option" value="male">Male</MenuItem>
                                    <MenuItem className="select-option" value="female">Female</MenuItem>
                                    <MenuItem className="select-option" value="other">Other</MenuItem>
                                </Select>
                                <Button type="submit">Generate Contract</Button>
                            </form>
                            <form className={classes.uploadDoc}>
                                <Typography variant="h4">Upload Documents</Typography>
                                <label htmlFor="uploadDocInput">
                                    <div className="labelContent">
                                        <UploadIcon />
                                        <span><img src={plusRoundedIcon} alt="upload" width={20} />Browse file to upload</span>
                                    </div>
                                </label>
                                <input type="file" multiple id="uploadDocInput" hidden />
                            </form>
                        </div>
                        <div className={classes.chartWrapper}>
                            {/* @ts-ignore */}
                            <GoogleChart
                                chartType="PieChart"
                                data={chartData}
                                width={'100%'}
                                height={'100%'}
                                loader={<div className="custom-loader"></div>}
                                options={{
                                    pieSliceBorderColor: 'transparent',
                                    slices: {
                                        0: { color: '#37D999' },
                                        1: { color: '#B8E4FB' },
                                    },
                                    legend: 'none',
                                }}
                            />
                        </div>
                        <CustomDataGrid columns={documentColumns} rows={documents} rowsPerPage={documents.length} loading={false} />
                    </div>
                </main>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    viewMoreBtnStyle: {
        fontSize: 14, 
        color: "#503A65", 
        textDecoration: 'underline', 
        fontWeight: 500,
        marginLeft: 15,
    }
}

export const webStyles = (_theme: Theme) => createStyles({
    pageTitle: {
        width: "100%",
        display: "inline-block",
        marginBottom: 30,
        fontSize: 24,
        lineHeight: "normal",
        fontWeight: 500,
        "@media (max-width: 767px)": {
            fontSize: 22,
        },
    },
    mainWrapper: {
        "& .custom-datagrid": {
            borderColor: "transparent",
            minHeight: "auto",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
            "& table .empty-msg": {
                position: "relative !important",
                display: "table-cell !important",
                textAlign: "center",
            },
            "& .MuiTableRow-root td": {
                borderColor: "transparent",
            },
            "& .MuiTableRow-root td, .MuiTableRow-root th": {
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        "& .pagination-wrapper": {
            display: "none",
        }
    },
    pageFormSection: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: 35,
        gap: 20,
    },
    formWrapper: {
        width: "calc(70% - 20px)",
        display: "flex",
        flexDirection: "column",
        gap: 30,
        "& form": {
            display: "flex",
            flexWrap: "wrap",
            gap: 20,
            "& h4": {
                display: "inline-block",
                width: "100%",
                fontSize: 18,
                lineHeight: "normal",
                fontWeight: 500,
            },
            '& .select-box': {
                height: 44,
                borderRadius: 8,
                color: '#000',
                border: '1px solid #E1D8E5',
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                boxSizing: "border-box",
                "& .MuiSelect-root": {
                    height: '100%',
                    boxSizing: "border-box",
                },
                "& .MuiSelect-icon": {
                    display: 'none',
                },
                "& input": {
                    height: "100%",
                    boxSizing: "border-box",
                },
                "& fieldset": {
                    display: "none",
                },
                "@media (max-width: 767px)": {
                    height: 38
                }
            }
        }
    },
    generateDoc: {
        "& .select-box": {
            maxWidth: 312,
            width: "100%",
        },
        "& button": {
            fontSize: 16,
            lineHeight: "normal",
            fontWeight: 600,
            background: "#503A65",
            color: "#FFFFFF",
            padding: "10px 16px",
            borderRadius: 8,
            "&:hover": {
                background: "#503A65",
            }
        }
    },
    uploadDoc: {
        "& label": {
            minHeight: 80,
            width: "100%",
            borderRadius: 8,
            padding: 15,
            border: "1px solid #E1D8E5",
            "& .labelContent": {
                height: "100%",
                width: "100%",
                gap: 5,
                padding: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 16,
                border: "2px dashed #CBD5E1",
                boxSizing: "border-box",
                "& span": {
                    color: "#503A65",
                    fontSize: 16,
                    lineHeight: "normal",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    "& img": {
                        marginRight: 8,
                    }
                }
            }
        },
    },
    chartWrapper: {
        width: "30%",
        display: "flex",
        alignItems: "center",
        borderRadius: 16,
        border: "0.4px solid #E1D8E5",
        boxSizing: "border-box",
    },
});

export default withStyles(webStyles)(withRouter(MerchantDocuments));
// Customizable Area End
