// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import '../../assets/css/DataEntryScreens.web.css';
import CustomDataGrid, { ColumnsProps } from "../../../../components/src/CustomDataGrid.web";
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import { iconArrowDown } from "../assets";
import {
    InputAdornment,
    MenuItem,
    Select,
    Typography,
    Button,
    Dialog
} from "@material-ui/core";
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import MerchantNotesController, { Props } from "./MerchantNotesController.web";
import DatePicker from "react-date-picker";
import { CalendarIcon, CrossIcon } from "../../../../components/src/IconAssets";
import UnderwriterScreenStepperWeb from "./UnderwriterScreenStepper.web";

const columns: ColumnsProps[] = [
    { key: 'username', label: 'User’s Name' },
    { key: 'note_type', label: 'Note Type' },
    { key: 'date_time', label: 'Date & Time' },
    { key: 'note', label: 'Note' }
];
// Customizable Area End

export class MerchantNotes extends MerchantNotesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <main className={`page-main ${classes.notesMain}`} data-test-id="Main">
                    <Typography variant="h3" className={classes.mainTitle}>Merchant Overview</Typography>
                    <UnderwriterScreenStepperWeb />
                    <div className={classes.stepHeader}>
                        <Typography variant="h3">Notes</Typography>
                        <Button data-test-id="addNoteBtn" onClick={() => this.setState({ isOpenDialog: true })}>
                            Add Note
                        </Button>
                    </div>
                    <div className={classes.filterForm}>
                        <div className={`${classes.field} filterField`}>
                            <label htmlFor="type">Type</label>
                            <Select
                                id="type"
                                name="type"
                                className="select-box"
                                variant="outlined"
                                data-test-id="typeInput"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem className="select-option" value="type1">Type 1</MenuItem>
                                <MenuItem className="select-option" value="type2">Type 2</MenuItem>
                                <MenuItem className="select-option" value="type3">Type 3</MenuItem>
                            </Select>
                        </div>
                        <div className={`${classes.field} filterField`}>
                            <label htmlFor="date">Date</label>
                            <DatePicker
                                id="date"
                                name="date"
                                className="date_picker"
                                data-test-id="dateInput"
                                format="MM/dd/yyyy"
                                monthPlaceholder="MM"
                                yearPlaceholder="YYYY"
                                dayPlaceholder="DD"
                                value={this.state.selectedDate}
                                onChange={(newDate: any) => this.setState({ selectedDate: newDate })}
                                calendarIcon={<CalendarIcon color={'#000000'} />}
                                clearIcon={null}
                            />
                        </div>
                        <div className={`${classes.field} filterField`}>
                            <label htmlFor="username">Username</label>
                            <Select
                                id="username"
                                name="username"
                                className="select-box"
                                variant="outlined"
                                data-test-id="usernameInput"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem className="select-option" value="username1">Username 1</MenuItem>
                                <MenuItem className="select-option" value="username2">Username 2</MenuItem>
                                <MenuItem className="select-option" value="username3">Username 3</MenuItem>
                            </Select>
                        </div>
                        <div className={classes.formActions}>
                            <Button type="button">Search</Button>
                        </div>
                    </div>
                    <CustomDataGrid
                        columns={columns}
                        rows={this.state.allNotes}
                        rowsPerPage={6}
                        loading={false}
                    />
                    <Dialog
                        className={classes.addNoteDialog}
                        data-test-id="addNoteDialog"
                        open={this.state.isOpenDialog}
                        onClose={() => this.setState({ isOpenDialog: false })}
                    >
                        <div className="dialogHeader">
                            <Typography variant="h4">Add Notes</Typography>
                            <Button data-test-id="dialogCloseBtn" onClick={() => this.handleCloseDialog()}>
                                <CrossIcon />
                            </Button>
                        </div>
                        <form data-test-id="dialogForm" onSubmit={(e) => e.preventDefault()}>
                            <div className={classes.field}>
                                <label htmlFor="member">Select Member (optional)</label>
                                <Select
                                    id="member"
                                    name="member"
                                    className="select-box"
                                    variant="outlined"
                                    data-test-id="memberInput"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem className="select-option" value="member1">Member 1</MenuItem>
                                    <MenuItem className="select-option" value="member2">Member 2</MenuItem>
                                    <MenuItem className="select-option" value="member3">Member 3</MenuItem>
                                </Select>
                            </div>
                            <div className={classes.field}>
                                <label htmlFor="notes">Add  Notes Here</label>
                                <textarea name="notes" id="notes" rows={4}></textarea>
                            </div>
                            <div className="actions">
                                <Button
                                    type="reset"
                                    data-test-id="dialogCancleBtn"
                                    className="outlined"
                                    onClick={() => this.handleCloseDialog()}
                                >
                                    cancle
                                </Button>
                                <Button type="submit">add notes</Button>
                            </div>
                        </form>
                    </Dialog>
                </main>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const classes = (_theme: Theme) => createStyles({
    mainTitle: {
        display: "inline-block",
        width: "100%",
        fontSize: 24,
        lineHeight: "normal",
        fontWeight: 500,
        marginBottom: 30,
        "@media (max-width: 767px)": {
            fontSize: 22,
        },
    },
    stepHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: "25px 0",
        "& h3": {
            fontSize: 24,
            lineHeight: 'normal',
            fontWeight: 500,
            color: '#000',
            "@media (max-width: 767px)": {
                fontSize: 20
            }
        },
        "& button, button:hover": {
            fontSize: 16,
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#503A65',
            background: 'transparent',
            padding: '10px 16px',
            borderRadius: 8,
            border: "1px solid #503A65",
            "@media (max-width: 767px)": {
                fontSize: 14,
                padding: '8px 14px',
            }
        }
    },
    filterForm: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "100%",
        marginBottom: 40,
        gap: 25,
        "& .filterField": {
            flex: 1,
            "@media (max-width: 767px)": {
                flex: "100%",
            },
        },
        "@media (max-width: 767px)": {
            gap: 15
        }
    },
    field: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        '& label': {
            fontSize: 16,
            lineHeight: 'normal',
            color: '#334155',
            "@media (max-width: 767px)": {
                fontSize: 14,
            }
        },
        '& fieldset': {
            display: 'none',
        },
        '& .select-box, .MuiOutlinedInput-root, textarea': {
            borderRadius: 8,
            color: '#000',
            border: '1px solid #E1D8E5',
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            boxSizing: "border-box",
            resize: "none",
            "&:not(textarea)": {
                height: 44,
            },
            "& .MuiSelect-root": {
                height: '100%',
                boxSizing: "border-box",
            },
            "& .MuiSelect-icon": {
                display: 'none',
            },
            "@media (max-width: 767px)": {
                "&:not(textarea)": {
                    height: 38,
                },
            },
        }
    },
    formActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flex: 1,
        "& button, button:hover": {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 'normal',
            minHeight: 48,
            color: '#FFF',
            maxWidth: 208,
            width: '100%',
            padding: 15,
            borderRadius: 8,
            background: '#503A65',
            boxSizing: 'border-box',
            "@media (max-width: 767px)": {
                maxWidth: '100%',
                minHeight: 38,
                fontSize: 14,
                padding: '8px 14px',
            }
        },
        "@media (max-width: 767px)": {
            flex: '100%',
        }
    },
    notesMain: {
        "& .custom-datagrid": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        "& .pagination-wrapper": {
            border: '1px solid #E2E8F0',
            borderTop: 0,
            borderRadius: '0 0 12px 12px',
            width: 'calc(100% + 2px)',
            paddingRight: 30,
        }
    },
    addNoteDialog: {
        "& .MuiDialog-paper": {
            padding: "16px 24px",
            borderRadius: 8,
            background: "#FFFFFF",
            boxSizing: "border-box",
            maxWidth: "600px",
            width: "100%",
            "& *": {
                fontFamily: "Fira Sans, sans-serif",
            },
        },
        "& .dialogHeader": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 12,
            gap: 8,
            "& h4": {
                fontSize: 20,
                fontWeight: 500,
                lineHeight: "normal",
            },
            "& button": {
                minWidth: "auto",
                padding: 0,
            },
        },
        "& form": {
            display: "flex",
            flexWrap: "wrap",
            rowGap: 20,
            "& textarea": {
                padding: 12,
            },
            "& .actions": {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                width: "100%",
                gap: 10,
                marginTop: 20,
                "& button": {
                    fontSize: 16,
                    lineHeight: "normal",
                    color: "#FFFFFF",
                    border: "1px solid #503A65",
                    borderRadius: 8,
                    padding: "10px 16px",
                    background: "#503A65",
                    textTransform: "capitalize",
                    "&.outlined": {
                        background: "transparent",
                        color: "#503A65",
                    }
                }
            }
        }
    }
});

export default withStyles(classes)(withRouter(MerchantNotes));
// Customizable Area End
