import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { styles } from "./MerchantOverview.web";
import { WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// Customizable Area Start
const moment = require('moment');
// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  navigation?: any;
  history: any;
  location: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  owners: any[];
  submissionHistory: any[];
  isDialogBuyRateOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MerchantOverviewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      owners: [
        {
          slno: "01",
          owner_name: "Dennis Coleman",
          phone: "123-123-3456",
          email: "dennis@example.com",
          address: "555, Main Street, New York,",
          ssn: "12-7654321",
        },
        {
          slno: "01",
          owner_name: "",
          phone: "123-123-3456",
          email: "dennis@example.com",
          address: "555, Main Street, New York,",
          ssn: "12-7654321",
        },
        {
          slno: "01",
          owner_name: "Dennis Coleman",
          phone: "123-123-3456",
          email: "dennis@example.com",
          address: "555, Main Street, New York,",
          ssn: "12-7654321",
        }
      ],
      submissionHistory: [
        {
          business_name: "CSR MINI MART",
          details: "other/see notes",
          submitted: "10/28/2023",
          mid: "test",
          iso: "test iso",
          owners: "test, abc, xyz, qwe"
        },
        {
          business_name: "CSR MINI MART",
          details: "other/see notes",
          submitted: "10/28/2023",
          mid: "test",
          iso: "test iso",
          owners: "test, abc, xyz, qwe"
        }
      ],
      isDialogBuyRateOpen: false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  handleDialogClose = () => {
    this.setState({ isDialogBuyRateOpen: false });
  }
  // Customizable Area End
}
