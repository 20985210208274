// Customizable Area Start
import React from "react";
import ForgotPasswordBlockController, {
    Props
} from "../src/ForgotPasswordBlockController.web";
import {
    Grid,
    Typography
} from "@material-ui/core"
const images = require('./assets')
const configJSON = require('./config')

const webStyle: any = {
    mainPasswordReset: {
        width: "35%",
        margin: "auto",
        padding: "50px 50px",
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Fira Sans, sans-serif",
    },
    emailSending: {
        marginBottom: "71px"
    },
    instructions: {
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 400,
        fontSize: "24px",
        textAlign: "center",
        marginBottom: "50px"
    },
    emailResendButtonAndText: {
        display: "flex",
        gap: "15px",
        marginBottom: "50px",
        alignItems: "center",
        justifyContent: "center",
    },
    noEmail: {
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 400,
        fontSize: "24px",
        textAlign: "center",
    },
    resendButton: {
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Fira Sans, sans-serif",
        color: "#503A65",
        textDecorationLine: "underline",
        cursor: "pointer",
        textAlign: "center"
    },
    backToLogin: {
        alignItems: "center",
        justifyContent: "center",
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Fira Sans, sans-serif",
        color: "#503A65",
        textDecorationLine: "underline",
        cursor: "pointer",
        textAlign: "center"
    },
    successMessageheading: {
        fontSize: "40px",
        fontWeight: 500,
        fontFamily: "Fira Sans, sans-serif",
        color: "#000",
        marginBottom: "48px"
    }
}
// Customizable Area End

export default class AccountCreation extends ForgotPasswordBlockController {
    constructor(props: Props) {
        super(props)
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div style={webStyle.mainPasswordReset}>
                <Grid item>
                    <img src={images.emailSending} style={webStyle.emailSending} />
                </Grid>
                <Typography style={webStyle.successMessageheading}>
                    {configJSON.successMessageheading}
                </Typography>
                <Typography style={webStyle.instructions}>
                    Instructions for resetting your password has been sent to {this.state.email}
                </Typography>
                <Grid container style={webStyle.emailResendButtonAndText}>
                    <Typography style={webStyle.noEmail}>
                        {configJSON.notReceiveEmail}
                    </Typography>
                    <Grid item style={webStyle.resendButton} onClick={this.handleResendEmail} data-test-id="resendEmail">
                        {configJSON.resendButton}
                    </Grid>
                </Grid>
                <Grid item style={webStyle.backToLogin} onClick={this.handleBackToLogin}>
                    {configJSON.backToLoginButtonText}
                </Grid>
            </div>
            // Customizable Area End
        )
    }
}