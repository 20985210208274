export const clarityEmployee = require("../../components/src/assets/clarity-employee.png")
export const exit = require("../../components/src/assets/exit.png")
export const exportIcon = require("../../components/src/assets/export-type-image.png")
export const horn = require("../../components/src/assets/horn.png")
export const iso = require("../../components/src/assets/iso-icon.png")
export const multiple = require("../../components/src/assets/multiple-file.png")
export const queue = require("../../components/src/assets/queue.png")
export const settings = require("../../components/src/assets/settings.png")
export const tabBar = require("../../components/src/assets/tab-bar.png")
export const lockUser = require("../../components/src/assets/user-lock.png")
export const wrap = require("../../components/src/assets/wrap.png")
export const logoP = require("../../components/src/assets/P_logo.png")
export const accountPic = require("../../components/src/assets/account_pic.png")
export const searchIcon = require("../../components/src/assets/search_icon.png")