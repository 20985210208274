// Customizable Area Start
import React from "react";
import '../../assets/css/DataEntryScreens.web.css';
import { withRouter } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import DataEntryUserScreenController, { Props } from "./DataEntryUserScreenController.web";
import CustomDataGrid, { ColumnsProps } from "../../../../components/src/CustomDataGrid.web";
import { IconRoundPlus } from "../../../../components/src/IconAssets";
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";

const columns: ColumnsProps[] = [
    { key: 'falg', label: 'Flag' },
    { key: 'merchant', label: 'Merchant' },
    { key: 'mid', label: 'MID' },
    { key: 'renewal', label: 'Renewal' },
    { key: 'submitted', label: 'Submitted' },
    { key: 'iso', label: 'ISO' },
    { key: 'status', label: 'Status' },
    { key: 'uw', label: 'UW' },
];
// Customizable Area End

export class DataEntryUserScreen extends DataEntryUserScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <div className="dataEntry-page" data-test-id="Main">
                    <div className="page-header">
                        <Typography variant="h2">Submissions</Typography>
                        <Button onClick={() => this.handleAddNewSubmission()} data-test-id="addSubmissionBtn">
                            New Submission <IconRoundPlus />
                        </Button>
                    </div>
                    <CustomDataGrid columns={columns} rows={this.state.allData} rowsPerPage={6} loading={this.state.isLoading} />
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withRouter(DataEntryUserScreen as any);
// Customizable Area End
