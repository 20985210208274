// Customizable Area Start
// @ts-nocheck
// @ts-ignore
import React from "react";
import AccountCreationController, {
    Props,
    configJSON
} from "../../../blocks/email-account-registration/src/AccountCreationController.web";
import Header from "../../../components/src/Header.web";
import Sidebar from "../../../components/src/Sidebar.web";
import {
    Tabs,
    Tab,
    Paper,
    Grid,
    Typography,
    Button,
    Switch,
    Modal,
    Fade,
    InputLabel,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import {
    makeStyles,
    Theme,
    createStyles,
} from '@material-ui/core/styles';
import {
    NotificationNotFilled,
    EditProfileNotFilled,
    PasswordNotFilled,
    DeleteAccountNotFilled,
    EmployeeAccountFilled,
    EditIconAC,
    AddNewEmployeePlus,
} from "../../../components/src/IconAssets";
import CustomDataGrid, { ColumnsProps, RowsProps } from "../../../components/src/CustomDataGrid.web";
import './AccountCreation.web.css';

const images = require('./assets')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#4D3861',
                    opacity: 1,
                    border: 'none',
                },
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: '#E1D8E5',
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
    })
);

export const IOSSwitch = () => {
    const classes = useStyles();

    return <Switch disableRipple classes={classes} />;
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const webStyle: any = {
    root: {
        flexGrow: 1,
        borderRadius: '8px',
        height: '80px',
        width: '85%',
        marginBottom: '50px'
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sidebar: {
        marginBottom: '50px'
    },
    tableGrid: {
        width: '85%'
    },
    employeeAccount: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '40px'
    },
    addEmployee: {
        display: 'inline-flex',
        padding: '10px 16px',
        fontSize: '18px',
        gap: '80x',
        backgroundColor: '#503A65',
        borderRadius: '8px',
    },
    cursor: {
        cursor: 'pointer',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: 'white',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
        padding: '30px',
        borderRadius: '8px',
        width: '25%'
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '34px'
    },
    modalCloseIcon: {
        cursor: 'pointer',
    },
    createEmployee: {
        fontSize: '24px',
        fontFamily: 'Fira Sans, sans-serif',
        fontWeight: '700'
    },
    input: {
        borderRadius: '8px',
        border: '1px solid #E1D8E5'
    },
    label: {
        paddingBottom: '10px',
        fontSize: '16px',
        fontFamily: 'Fira Sans, sans-serif',
        fontWeight: '400',
    },
    firstAndLastName: {
        display: 'flex',
        gap: '16px',
        paddingBottom: '30px'
    },
    inputLong: {
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #E1D8E5',
        marginBottom: "5px",
    },
    inputLongError: {
        width: '100%',
        borderRadius: '8px',
        border: '1px solid red',
        marginBottom: "5px",
    },
    formDiv: {
        paddingBottom: '50px'
    },
    selectLong: {
        width: '100%',
        border: '2px solid #E1D8E5',
        borderRadius: '8px',
        fontFamily: 'Fira Sans, sans-serif',
        textTransform: 'capitalize'
    },
    menuItem: {
        fontFamily: 'Fira Sans, sans-serif',
        color: '#818181',
        // color: 'red',
        fontSize: '14px',
        fontWeight: '400',
        textTransform: 'capitalize'
    },
    buttonForm: {
        float: 'right',
        display: 'flex',
        gap: '10px'
    },
    addNewEmployee: {
        backgroundColor: '#503A65',
        padding: '10px 16px',
        borderRadius: '8px',
        color: 'white',
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Fira Sans, sans-serif',
    },
    cancelButton: {
        border: '1px solid #503A65',
        padding: '10px 16px',
        borderRadius: '8px',
        color: '#503A65',
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Fira Sans, sans-serif',
    },
    emailError: {
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Fira Sans, sans-serif',
        color: "red"
    }
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
            style={webStyle.tableGrid}
        >
            {value === index && (
                <Grid >
                    <Typography>{children}</Typography>
                </Grid>
            )}
        </div>
    );
}
// Customizable Area End

export default class AccountCreation extends AccountCreationController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    columns: ColumnsProps[] = [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'role', label: 'Role' },
        {
            key: 'action', label: 'Status', cellRender: () => (
                <div data-test-id="iosswitch" style={webStyle.cursor} onChange={() => { this.handleToggle('activated', !this.state.activated) }}>
                    <IOSSwitch />
                </div>
            ),
        },
        {
            key: 'action', label: '', cellRender: (item) => (
                <div data-test-id="editSwitch" style={webStyle.cursor} onClick={() => { this.handleEditModal('openEditAccount', true, item.editId, item.slackHandle, item.role) }}>
                    <EditIconAC />
                </div>
            ),
        }
    ];

    a11yProps(index: any) {
        return {
            id: `wrapped-tab-${index}`,
            'aria-controls': `wrapped-tabpanel-${index}`,
        };
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div className="accountCreation">
                <div>
                    <Header />
                </div>
                <div style={webStyle.sidebar}>
                    <Sidebar />
                </div>

                {/* Tabs and Tab Panel */}
                <div style={webStyle.mainDiv} classname="mainDiv">
                    <Paper square
                        style={webStyle.root}
                    >
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            data-test-id="tabChange"
                            textColor="secondary"
                            aria-label="icon label tabs example"
                            TabIndicatorProps={{ style: { backgroundColor: '#503A65' } }}
                            {...this.a11yProps(3)}
                        >
                            <Tab icon={<EditProfileNotFilled />} disabled label="Edit Profile" {...this.a11yProps(0)} />
                            <Tab icon={<PasswordNotFilled />} disabled label="Password" {...this.a11yProps(1)} />
                            <Tab icon={<NotificationNotFilled />} disabled label="Notifications" {...this.a11yProps(2)} />
                            <Tab icon={<EmployeeAccountFilled />} label="Employee Account" {...this.a11yProps(3)} />
                            <Tab icon={<DeleteAccountNotFilled />} disabled label="Delete Account" {...this.a11yProps(4)} />
                        </Tabs>
                    </Paper>
                    <TabPanel value={this.state.value} index={3}>

                        {/* employee acount and button */}
                        <div style={webStyle.employeeAccount}>
                            <Typography>
                                {configJSON.employeeAccountHeading}
                            </Typography>
                            <div>
                                <Button data-test-id="addNewEmployee" style={webStyle.addEmployee} onClick={this.handleAddEmployee}>
                                    {configJSON.addNewEmployeeButtonText} <AddNewEmployeePlus />
                                </Button>
                            </div>
                        </div>
                        <div className="table">
                            <CustomDataGrid data-test-id="customData" columns={this.columns} rows={this.newRowData} rowsPerPage={7} />
                        </div>
                    </TabPanel>

                    {/* Modal Create Account*/}
                    <div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            style={webStyle.modal}
                            open={this.state.openCreateAccount}
                            closeAfterTransition
                        >
                            <Fade in={this.state.openCreateAccount}>
                                <div style={webStyle.paper}>
                                    <div style={webStyle.modalHeader}>
                                        <div>
                                            <span style={webStyle.createEmployee}>
                                                {configJSON.createEmployeeAccountTextModal}
                                            </span>
                                        </div>
                                        <div
                                            style={webStyle.modalCloseIcon}
                                            onClick={() => this.handleToggle('openCreateAccount', false)}
                                            data-test-id="closeModal"
                                        >
                                            <img src={images.close} />
                                        </div>
                                    </div>
                                    <div
                                        style={webStyle.firstAndLastName}
                                    >
                                        <div>
                                            <InputLabel
                                                style={webStyle.label}
                                            >
                                                {configJSON.firstNameLabel}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                value={this.state.firstName}
                                                name="firstName"
                                                onChange={this.handleInputChange}
                                                style={webStyle.input}
                                                data-test-id="addFirstname"
                                            />
                                        </div>
                                        <div>
                                            <InputLabel
                                                style={webStyle.label}
                                            >{configJSON.lastNameLabel}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                value={this.state.lastName}
                                                style={webStyle.input}
                                                onChange={this.handleInputChange}
                                                name="lastName"
                                                data-test-id="addLastname"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={webStyle.formDiv}
                                    >
                                        <InputLabel style={webStyle.label}>
                                            {configJSON.emailLabel}
                                        </InputLabel>
                                        <TextField
                                            value={this.state.email}
                                            name="email"
                                            style={this.state.accountEmailError ? webStyle.inputLongError : webStyle.inputLong}
                                            variant="outlined"
                                            onChange={this.handleInputChange}
                                            data-test-id="addEmail"
                                        />
                                        <Grid item style={webStyle.emailError}>
                                            {this.state.accountEmailError && this.state.accountEmailErrorMsg}
                                        </Grid>
                                    </div>
                                    <div
                                        style={webStyle.formDiv}
                                    >
                                        <InputLabel
                                            style={webStyle.label}
                                        >
                                            {configJSON.slackHandleLabel}
                                        </InputLabel>
                                        <TextField
                                            variant="outlined"
                                            style={webStyle.inputLong}
                                            name="slackHandle"
                                            onChange={this.handleInputChange}
                                            value={this.state.slackHandle}
                                            data-test-id="addSlackHandle"
                                        />
                                    </div>
                                    <div
                                        style={webStyle.formDiv}
                                        className="custom-dropdown"
                                    >
                                        <InputLabel
                                            style={webStyle.label}
                                        >
                                            {configJSON.selectRoleLabel}
                                        </InputLabel>
                                        <Select
                                            name="selectRole"
                                            value={this.state.roleSelect}
                                            style={webStyle.selectLong}
                                            onChange={this.handleRoleSelect}
                                            id="demo-simple-select"
                                            labelId="demo-simple-select-label"
                                            data-test-id="addRoleSelect"
                                        >
                                            {this.state.getRolesApiResponse.map((item: any) => {
                                                const roleName = item.attributes.name;
                                                const formattedRoleName = roleName.split('_')
                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                    .join(' ');

                                                return (
                                                    <MenuItem
                                                        value=
                                                        {item.attributes.name}
                                                        key=
                                                        {item.id + "role name"}
                                                        style=
                                                        {webStyle.menuItem}
                                                    >
                                                        {formattedRoleName}
                                                    </MenuItem>
                                                );
                                            })
                                            }
                                        </Select>
                                    </div>
                                    <div
                                        style={webStyle.buttonForm}
                                    >
                                        <div>
                                            <Button
                                                style={webStyle.cancelButton}
                                                onClick={() => this.handleToggle('openCreateAccount', false)}
                                                data-test-id="cancelButton"
                                            >
                                                {configJSON.cancelButtonText}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                style={webStyle.addNewEmployee}
                                                onClick={this.handleAccountCreation}
                                                data-test-id="addButton"
                                            >
                                                {configJSON.addNewEmployeeButtonText}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>

                    {/* Modal Edit Account */}
                    <div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            style={webStyle.modal}
                            open={this.state.openEditAccount}
                            closeAfterTransition
                        >
                            <Fade in={this.state.openEditAccount}>
                                <div style={webStyle.paper}>
                                    <div style={webStyle.modalHeader}>
                                        <div>
                                            <span style={webStyle.createEmployee}>
                                                {configJSON.editEmployeeAccountTextModal}
                                            </span>
                                        </div>
                                        <div data-test-id="editModalOpen" style={webStyle.modalCloseIcon} onClick={() => this.handleToggle('openEditAccount', false)}>
                                            <img src={images.close} />
                                        </div>
                                    </div>
                                    <div style={webStyle.firstAndLastName}>
                                        <div>
                                            <InputLabel style={webStyle.label}>
                                                {configJSON.firstNameLabel}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                name="firstName"
                                                style={webStyle.input}
                                                value={this.state.firstName}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div>
                                            <InputLabel style={webStyle.label}>
                                                {configJSON.lastNameLabel}
                                            </InputLabel>
                                            <TextField
                                                variant="outlined"
                                                name="lastName"
                                                style={webStyle.input}
                                                value={this.state.lastName}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div style={webStyle.formDiv}>
                                        <InputLabel style={webStyle.label}>
                                            {configJSON.emailLabel}
                                        </InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="email"
                                            style={webStyle.inputLong}
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div style={webStyle.formDiv}>
                                        <InputLabel style={webStyle.label}>
                                            {configJSON.slackHandleLabel}
                                        </InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="slackHandle"
                                            style={webStyle.inputLong}
                                            value={this.state.slackHandle}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div style={webStyle.formDiv} className="custom-dropdown">
                                        <InputLabel style={webStyle.label}>{configJSON.selectRoleLabel}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.roleSelect}
                                            style={webStyle.selectLong}
                                            name="selectRole"
                                            onChange={this.handleRoleSelect}
                                        >
                                            {this.state.getRolesApiResponse.map((item: any) => {
                                                const roleName = item.attributes.name;
                                                const formattedRoleName = roleName
                                                    .split('_')
                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                    .join(' ');

                                                return (
                                                    <MenuItem
                                                        value={item.attributes.name}
                                                        key={item.id + "role name"}
                                                        style={webStyle.menuItem}
                                                    >
                                                        {formattedRoleName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div style={webStyle.buttonForm}>
                                        <div>
                                            <Button data-test-id="editModalCancel" style={webStyle.cancelButton} onClick={() => this.handleToggle('openEditAccount', false)}>
                                                {configJSON.cancelButtonText}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button data-test-id="editModalSubmit" style={webStyle.addNewEmployee} onClick={this.handleEditEmployee}>
                                                {configJSON.updateEmployeeButtontext}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                </div>
            </div>
            // Customizable Area End
        )
    }
}