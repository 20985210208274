// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

const configJSON = require("./config")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    password: string;
    reEnterPassword: string;
    validations: boolean[];
    showNewPassword: boolean,
    buttonDisabled: boolean,
    passwordNotMatchError: boolean,
    passwordNotMatchErrorMsg: string,
    showConfirmPassword: boolean,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiCallIdResetPassword: any
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            password: "",
            reEnterPassword: "",
            validations: [false, false, false, false, false, false],
            showNewPassword: false,
            buttonDisabled: true,
            passwordNotMatchError: false,
            passwordNotMatchErrorMsg: "",
            showConfirmPassword: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.apiCallIdResetPassword) {
                    if (!responseJson.errors) {
                        const passwordSuccess: Message = new Message(getName(MessageEnum.NavigationMessage));
                        passwordSuccess.addData(getName(MessageEnum.NavigationTargetMessage), "ResetPasswordSuccessfull");
                        passwordSuccess.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                        this.send(passwordSuccess);
                    }
                    else if (responseJson.errors) {
                        this.setState({
                            passwordNotMatchError: true,
                            passwordNotMatchErrorMsg: responseJson.errors
                        })
                    }
                }
            }
        }
        // Customizable Area End
    }

    validatePassword = (password: string): boolean[] => {
        const lengthValid = password.length >= 8;
        const uppercaseValid = /[A-Z]/.test(password);
        const lowercaseValid = /[a-z]/.test(password);
        const numberValid = /\d/.test(password);
        const specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return [
            lengthValid,
            uppercaseValid,
            lowercaseValid,
            numberValid,
            specialCharValid,
        ];
    };

    handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        const validations = this.validatePassword(password);
        const buttonDisabled = !validations.every((validation) => validation);
        this.setState({ password, validations, buttonDisabled });
    };

    handlePasswordVisdibilityNewPassword = () => {
        this.setState({ showNewPassword: !this.state.showNewPassword });
    };

    handlePasswordVisdibilityConfirmNewPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    };

    handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "confirmNewpassword") {
            this.setState({
                reEnterPassword: value,
                passwordNotMatchError: false
            })
        }
    }

    handleBackToLogin = () => {
        const backToLogin: Message = new Message(getName(MessageEnum.NavigationMessage));
        backToLogin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        backToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(backToLogin);
    }

    handleSaveNewPassword = () => {
        this.apiFnResetPassword()
    }

    apiFnResetPassword = async () => {
        const resetToken = await getStorageData("resetPasswordToken")

        const header = {
            "Content-Type": "application/json",
            token: resetToken,
        };

        const httpBody = {
            "data":
            {
                "type": "email_account",
                "attributes": { "password": this.state.password, "password_confirmation": this.state.reEnterPassword }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdResetPassword = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiResetPasswordEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPostMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.apiCallIdResetPassword;
    }

    // Customizable Area End
}