Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.apiCallEndpointsAccountCreationPost = "account_block/accounts/";
exports.apiCallEndpointsRoleIndexGet = "bx_block_roles_permissions/roles"
exports.apiCallHeadersGetRoleIndexApi = "application/json";
exports.apiCallEndpointsAllAccount = "account_block/accounts";
exports.employeeAccountHeading = "Employee Account";
exports.addNewEmployeeButtonText = "Add New Employee";
exports.createEmployeeAccountTextModal = "Create Employee Account";
exports.firstNameLabel = "First Name";
exports.lastNameLabel = "Last Name";
exports.emailLabel = "Email";
exports.slackHandleLabel = "Slack Handle";
exports.selectRoleLabel = "Select Role";
exports.cancelButtonText = "Cancel";
exports.editEmployeeAccountTextModal = "Edit Employee Account";
exports.updateEmployeeButtontext = "Update Employee";
// Customizable Area End
