import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { classes } from "./MerchantNotes.web";
import { WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// Customizable Area Start
const moment = require('moment');
// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps, WithStyles<typeof classes> {
  navigation?: any;
  history: any;
  location: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allNotes: any[];
  selectedDate: any;
  isOpenDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MerchantNotesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      allNotes: [],
      selectedDate: "",
      isOpenDialog: false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      allNotes: [
        { username: "user1", note_type: "admin", date_time: "10-07-2023 9:32 AM", note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor" },
        { username: "user2", note_type: "user", date_time: "10-07-2023 10:15 AM", note: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur" },
        { username: "user3", note_type: "admin", date_time: "10-07-2023 11:45 AM", note: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum" },
        { username: "user4", note_type: "user", date_time: "10-07-2023 1:20 PM", note: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat" },
        { username: "user5", note_type: "admin", date_time: "10-07-2023 3:05 PM", note: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium" }
      ]
    });
  }
  // Customizable Area End

  // Customizable Area Start
  handleCloseDialog = () => {
    this.setState({ isOpenDialog: false });
  }
  // Customizable Area End
}
