// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import '../../assets/css/DataEntryScreens.web.css';
import UnderwriterController, { Props } from "./UnderwriterController.web";
import CustomDataGrid, { ColumnsProps } from "../../../../components/src/CustomDataGrid.web";
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import { iconArrowDown } from "../assets";
import {
    InputAdornment,
    MenuItem,
    Select,
    Typography,
    Button
} from "@material-ui/core";
import { CommunicationIcon } from "../../../../components/src/IconAssets";
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

const columns: ColumnsProps[] = [
    { key: 'flag', label: 'Flag' },
    { key: 'merchant', label: 'Merchant' },
    { key: 'mid', label: 'MID' },
    { key: 'renewal', label: 'Renewal' },
    { key: 'submitted', label: 'Submitted' },
    { key: 'iso', label: 'ISO' },
    { key: 'status', label: 'Status' },
    { key: 'uw', label: 'UW' },
];
// Customizable Area End

export class Underwriter extends UnderwriterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <main className={`page-main ${classes.underwriterMain}`} data-test-id="Main">
                    <div className={classes.pageHeader}>
                        <Typography variant="h3">Queue</Typography>
                    </div>
                    <div className={classes.searchForm}>
                        <div className={classes.field}>
                            <label htmlFor="status">Status</label>
                            <Select
                                id="status"
                                name="status"
                                className="select-box"
                                variant="outlined"
                                data-test-id="statusInput"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem className="select-option" value="status1">Status 1</MenuItem>
                                <MenuItem className="select-option" value="status2">Status 2</MenuItem>
                                <MenuItem className="select-option" value="status3">Status 3</MenuItem>
                            </Select>
                        </div>
                        <div className={classes.field}>
                            <label htmlFor="iso">ISO</label>
                            <Select
                                id="iso"
                                name="iso"
                                className="select-box"
                                variant="outlined"
                                data-test-id="isoInput"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem className="select-option" value="iso1">ISO 1</MenuItem>
                                <MenuItem className="select-option" value="iso2">ISO 2</MenuItem>
                                <MenuItem className="select-option" value="iso3">ISO 3</MenuItem>
                            </Select>
                        </div>
                        <div className={classes.formActions}>
                            <Button type="button">Search</Button>
                        </div>
                    </div>
                    <CustomDataGrid
                        columns={columns}
                        rows={this.state.allData}
                        rowsPerPage={6}
                        loading={this.state.isLoading}
                        rowOptions={{
                            isRowClickAble: true,
                            onRowClick: (_row: any) => this.props.history.push('/underWriter/merchantOverview')
                        }}
                    />
                </main>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const classes = (_theme: Theme) => createStyles({
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 25,
        "& h3": {
            fontSize: 24,
            lineHeight: 'normal',
            fontWeight: 500,
            color: '#000',
            "@media (max-width: 767px)": {
                fontSize: 20
            }
        },
        "& button, button:hover": {
            fontSize: 16,
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#FFF',
            background: '#503A65',
            padding: '10px 16px',
            borderRadius: 8,
            "@media (max-width: 767px)": {
                fontSize: 14,
                padding: '8px 14px',
            }
        },
        "& button svg": {
            marginLeft: 8,
            "@media (max-width: 767px)": {
                height: 20,
                width: 20,
            }
        }
    },
    searchForm: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "100%",
        marginBottom: 40,
        gap: 25,
        "@media (max-width: 767px)": {
            gap: 15
        }
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        width: 'calc(100% / 3 - 25px)',
        '& label': {
            fontSize: 16,
            lineHeight: 'normal',
            color: '#334155',
            "@media (max-width: 767px)": {
                fontSize: 14,
            }
        },
        '& fieldset': {
            display: 'none',
        },
        '& .select-box': {
            height: 44,
            borderRadius: 8,
            color: '#000',
            border: '1px solid #E1D8E5',
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            boxSizing: "border-box",
            "& .MuiSelect-root": {
                height: '100%',
                boxSizing: "border-box",
            },
            "& .MuiSelect-icon": {
                display: 'none',
            },
            "@media (max-width: 767px)": {
                height: 38
            }
        },
        "@media (max-width: 767px)": {
            width: 'calc(50% - 8px)',
        },
        "@media (max-width: 479px)": {
            width: "100%"
        }
    },
    formActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: 'calc(100% / 3)',
        "& button, button:hover": {
            minHeight: 48,
            background: '#503A65',
            color: '#FFF',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 'normal',
            maxWidth: 208,
            width: '100%',
            padding: 15,
            boxSizing: 'border-box',
            borderRadius: 8,
            "@media (max-width: 767px)": {
                maxWidth: '100%',
                minHeight: 38,
                fontSize: 14,
                padding: '8px 14px',
            }
        },
        "@media (max-width: 767px)": {
            width: '100%',
        }
    },
    underwriterMain: {
        "& .custom-datagrid": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        "& .pagination-wrapper": {
            border: '1px solid #E2E8F0',
            borderTop: 0,
            borderRadius: '0 0 12px 12px',
            width: 'calc(100% + 2px)',
            paddingRight: 30,
        }
    }
});

export default withStyles(classes)(withRouter(Underwriter));
// Customizable Area End
