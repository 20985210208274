Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.getTypeOfEntityApiEndPoint = "bx_block_custom_form/business_infos/type_on_entity";
exports.addBusinessInfoApiEndPoint = "bx_block_custom_form/business_infos";
exports.addPersonalInfoApiEndPoint = "bx_block_custom_form/personal_infos";
exports.getAllDataEntryApiEndPoint = "bx_block_custom_form/submissions";
exports.getAllMerchantApiEndPoint = "bx_block_custom_form/submissions";
// Customizable Area End