// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

const configJSON = require('./config');
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    // forgotEmail: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    forgotPasswordEmail: string,
    forgotEmailError: boolean,
    forgotEmailErrorMessage: string,
    email: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ForgotPasswordBlockController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiCallIdForgotPassword: any
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            forgotPasswordEmail: "",
            forgotEmailError: false,
            forgotEmailErrorMessage: "",
            email: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.apiCallIdForgotPassword) {
                    this.setApiFnForgotPassword(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    setApiFnForgotPassword = (responseJson: any) => {
        if (!responseJson.errors) {
            setStorageData('forgotResetEmail', this.state.forgotPasswordEmail)
            const resetEmailConfirmation: Message = new Message(getName(MessageEnum.NavigationMessage));
            resetEmailConfirmation.addData(getName(MessageEnum.NavigationTargetMessage), "PasswordResetConfirmation");
            resetEmailConfirmation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(resetEmailConfirmation);
            const startIndex = responseJson.token.indexOf("ey");
            if (startIndex !== -1) {
                const extractedValue = responseJson.token.substring(startIndex);
                setStorageData("resetPasswordToken", extractedValue)
            }
        }
        else if (responseJson.errors[0].account) {
            this.setState({
                forgotEmailError: true,
                forgotEmailErrorMessage: responseJson.errors[0].account
            })
        }
    }

    async componentDidMount() {
        const email = await getStorageData('forgotResetEmail')
        this.setState({
            email: email
        })
    }

    apiCallFnResendEmail = async () => {
        const loginToken = await getStorageData("userToken")

        const header = 
        {
            "Content-Type": "application/json",
            token: loginToken,
        };
        const httpBody = { 
            "email": this.state.email
         }

        const requestMessage = new Message
        (
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdForgotPassword = requestMessage.messageId;
        requestMessage.addData
        (
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiForgotPasswordEndpoints
        );

        requestMessage.addData
        (
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData
        (
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData
        (
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPutMethod
        );

        runEngine.sendMessage
        (requestMessage.id, requestMessage);

        return this.apiCallIdForgotPassword;
    }

    apiCallFnResetPasswordEmail = async () => {
        const loginToken = await getStorageData("userToken")

        const header = {
            "Content-Type": "application/json",
            token: loginToken,
        };
        const httpBody = { "email": this.state.forgotPasswordEmail }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdForgotPassword = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiForgotPasswordEndpoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPutMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.apiCallIdForgotPassword;
    };

    handleTextChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "forgotPassword") {
            this.setState({
                forgotPasswordEmail: value,
                forgotEmailError: false,
            });
        }
    }

    handleBackToLogin = () => {
        const backToLogin: Message = new Message(getName(MessageEnum.NavigationMessage));
        backToLogin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        backToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(backToLogin);
    }

    handleEmailSending = () => {
        this.apiCallFnResetPasswordEmail()
    }

    handleResendEmail = () => {
        this.apiCallFnResendEmail()
    }

    // Customizable Area End
}