// Customizable Area Start
import React from "react";
import ForgotPasswordBlockController, {
    Props
} from "../src/ForgotPasswordBlockController.web";
import {
    Grid,
    InputAdornment,
    IconButton,
    Typography,
    TextField,
    Button
} from "@material-ui/core"

const images = require('./assets')
const configJSON = require('./config')

const webStyle: any = {
    mainForgotpassword: {
        borderRadius: "16px",
        width: "25%",
        margin: "auto",
        padding: "50px 50px",
        height: "auto",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 8px 4px rgba(225, 216, 229, 0.30)",
        fontFamily: "Fira Sans, sans-serif",
    },
    forgotPasswordText: {
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 500,
        fontSize: "28px",
        color: "black",
        marginBottom: "32px"
    },
    imageAndText: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    lockImage: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "64px"
    },
    textAndField: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
    },
    sendEmailText: {
        fontFamily: "Fira Sans, sans-serif",
        fontWeight: 400,
        fontSize: "18px",
        color: "black",
        marginBottom: "32px"
    },
    mainEmail: {
        width: "100%",
        marginBottom: "97px"
    },
    textField: {
        border: "1px solid #E1D8E5",
        width: "100%",
        padding: "10px 16px",
        borderRadius: "0.5rem",
        boxSizing: "border-box",
    },
    errorTextField: {
        border: "1px solid red",
        width: "100%",
        padding: "10px 16px",
        borderRadius: "0.5rem",
        boxSizing: "border-box",
    },
    submitResetButton: {
        alignItems: "center",
        justifyContent: "center",
        display: "block",
        flexDirection: "column",
        backgroundColor: "#503A65",
        borderRadius: "8px",
        color: "white",
        textTransform: "capitalize",
        width: "100%",
        fontSize: "18px",
        fontWeight: 700,
        padding: "16px",
        marginBottom: "20px",
        fontFamily: "Fira Sans, sans-serif",
        cursor: "pointer"
    },
    mainButton: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    backToLogin: {
        alignItems: "center",
        justifyContent: "center",
        display:"flex",
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Fira Sans, sans-serif",
        color: "#503A65",
        textDecorationLine: "underline",
        cursor: "pointer",
        textAlign: "center"
    },
    backtoLoginButton: {
        width: "100%",
    },
    label: {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Fira Sans, sans-serif",
    },
    emailFieldError: {
        color: "red",
        fontSize: '15px',
        paddingTop: '5px',
    },
}
// Customizable Area End

export default class ForgotPasswordBlock extends ForgotPasswordBlockController {
    constructor(props: Props) {
        super(props)
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={webStyle.mainForgotpassword}>
                {/* Image and Text Container */}
                <Grid container style={webStyle.imageAndText}>
                    <Grid item>
                        <img src={images.lockIcon} style={webStyle.lockImage} />
                    </Grid>
                </Grid>
                <Grid item style={webStyle.forgotPasswordText}>
                    {configJSON.forgotPasswordText}
                </Grid>

                {/* Main form */}
                <Grid container style={webStyle.textAndField}>
                    <Grid item style={webStyle.sendEmailText}>
                        {configJSON.sendEmailText}
                    </Grid>
                    <Grid item style={webStyle.mainEmail}>
                        <Typography className="label" style={webStyle.label}>Your Email</Typography>
                        <TextField
                            className="email-field"
                            // style={webStyle.textField}
                            style={
                                this.state.forgotEmailError
                                    ? webStyle.errorTextField
                                    : webStyle.textField
                            }
                            type="text"
                            data-test-id="forgtoEmailField"
                            name="forgotPassword"
                            value={this.state.forgotPasswordEmail}
                            onChange={this.handleTextChange}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton edge="start">
                                            <img src={images.emailField} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Grid item style={webStyle.emailFieldError}>
                            {this.state.forgotEmailError && this.state.forgotEmailErrorMessage}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item style={webStyle.mainButton}>
                            <Button
                                style={webStyle.submitResetButton}
                                onClick={this.handleEmailSending}
                                data-test-id="emailSendingButton"
                            >
                                {configJSON.submitResetButton}
                            </Button>
                        </Grid>
                        <Grid style={webStyle.backtoLoginButton}>
                        <Grid item style={webStyle.backToLogin} onClick={this.handleBackToLogin} data-test-id="backToLogin">
                            {configJSON.backToLoginButtonText}
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            // Customizable Area End
        )
    }
}