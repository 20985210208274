import React, { Component } from 'react';
import "./Sidebar.web.css";
const images = require("./assets");

class Sidebar extends Component {
  state = {
    tooltipText: '',
    selectedIcon: "",
  };

  handleIconHover = (text: string) => {
    this.setState({ tooltipText: text });
  };

  handleIconLeave = () => {
    this.setState({ tooltipText: '' });
  };

  handleIconClick = (text: string) => {
    this.setState({ selectedIcon: text });
  };

  render() {
    const { tooltipText } = this.state;

    return (
      <div className="sidebar">
        <div className="inner-wrapper">
          <div className="icon-group">
            <div
              className={`icon back ${this.state.selectedIcon === 'Back' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Back')}
              onMouseLeave={this.handleIconLeave}
              title="Back"
              onClick={() => this.handleIconClick('Back')}
            >
              <img src={images.wrap} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'Apps' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Apps')}
              onMouseLeave={this.handleIconLeave}
              title="Apps"
              onClick={() => this.handleIconClick('Apps')}
            >
              <img src={images.tabBar} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'Groups' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Groups')}
              onMouseLeave={this.handleIconLeave}
              title="Groups"
              onClick={() => this.handleIconClick('Groups')}
            >
              <img src={images.queue} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'Schedule' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Schedule')}
              onMouseLeave={this.handleIconLeave}
              title="Schedule"
              onClick={() => this.handleIconClick('Schedule')}
            >
              <img src={images.clarityEmployee} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'Upload' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Upload')}
              onMouseLeave={this.handleIconLeave}
              title="Upload"
              onClick={() => this.handleIconClick('Upload')}
            >
              <img src={images.exportIcon} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'ISO' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('ISO')}
              onMouseLeave={this.handleIconLeave}
              title="ISO"
              onClick={() => this.handleIconClick('ISO')}
            >
              <img src={images.iso} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'Announcements' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Announcements')}
              onMouseLeave={this.handleIconLeave}
              title="Announcements"
              onClick={() => this.handleIconClick('Announcements')}
            >
              <img src={images.horn} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'User' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('User')}
              onMouseLeave={this.handleIconLeave}
              title="User"
              onClick={() => this.handleIconClick('User')}
            >
              <img src={images.lockUser} />
            </div>
            <div
              className={`icon  ${this.state.selectedIcon === 'Tabs' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Tabs')}
              onMouseLeave={this.handleIconLeave}
              title="Tabs"
              onClick={() => this.handleIconClick('Tabs')}
            >
              <img src={images.multiple} />
            </div>
          </div>
          <div className="icon-group bottom-icons">
            <div
              className={`icon back ${this.state.selectedIcon === 'Settings' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('Settings')}
              onMouseLeave={this.handleIconLeave}
              title="Settings"
              onClick={() => this.handleIconClick('Settings')}
            >
              <img src={images.settings} />
            </div>
            <div
              className={`icon ${this.state.selectedIcon === 'LogOut' ? 'selected' : ''}`}
              onMouseEnter={() => this.handleIconHover('LogOut')}
              onMouseLeave={this.handleIconLeave}
              title="LogOut"
              onClick={() => this.handleIconClick('LogOut')}
            >
              <img src={images.exit} />
            </div>
          </div>
          {tooltipText && <div className="tooltip">{tooltipText}</div>}
        </div>
      </div>
    );
  }
}

export default Sidebar as any;
